module.exports = {
  "or": "Or",

  "affiliation": {
    "tooltip-uncomplete": "Confirmation pending, this transaction will be confirmed early this month",
    "title": "Affiliations",
    "threshold": "Rank",
    "thresholds": "Ranks",
    "transactions": "Transactions",
    "companies": "Affiliated companies",
    "click-to-copy": "Click to copy the affiliation link",
    "click-to-copy-success": "Copied",
    "code": "Program code",
    "name": "Program name",
    "date": "Date",
    "total": "Total available",
    "forecast-total": "Forecast total",
    "valid-date": "Is active since",
    "valid-date-future": "Will be valid on",
    "amount": "Monthly turnover to generate",
    "percentage": "Percentage",
    "empty-transactions": "No transactions yet…",
    "empty-companies": "No companies yet…",
    "monthly-ca": "Monthly turnover generated",
    "ca": "Turnover generated",
    "percentage-apply": "Percentage applied",
    "teasing-warning": "Currently your company does not have an affiliate program.\n" +
      "To activate this program, please contact our sales department by email or phone.",
    "teasing-detail": "Our affiliate program allows you to free yourself from invoicing your customers, but also to receive a remuneration on all the services collected within the framework of this program.",
    "tabs": {
      "info": "Information",
      "earn": "My earnings",
      "my-links": "My links",
      "generate-unique-link": "Generate a new link"
    }
  },

  "affiliation-links": {
    "title": "My affiliate links",
    "new": "Create a custom link",
    "code": "General membership code",
    "company": "Company",
    "used_by_company": "Used by the company?",
    "email": "Mail",
    "actions": "Actions",
    "false": "No",
    "true": "Yes",
    "modal": {
      "title": "Remove this affiliate link",
      "danger": "This action is irreversible.",
      "message": "",
      "button": "Delete",
      "extra": "Are you sure?"
    }
  },

  "help": {
    "key-rename": "Rename the key",
    "key-expire-edit": "Edit the expiration date of the key",
    "ssh-rename": "Rename the key",
    "ssh-rewrite": "Modify the key",
    "network": "By connecting your server to your Vswitch, a network card will be automatically linked to it.",
    "server-password": "The SSH user will be indicated in the panel admin « More information » section. To be logged in as root, please use the su command and your password.",
    "server-ssh": "Keys management can be administered from your account menu.",
    "server-create": "-",
    "server-snapshots": "Although useful, a snapshot does not replace making a backup.",
    "image-rename": "Rename the image.",
    "image-os-rename": "Change OS name.",
    "image-os-version-rename": "Rename OS version.",
    "server-rename": "Rename the server.",
    "server-rename-reverse": "Rename the reverse.",
    "server-open-ssh": "Open a SSH terminal.",
    "server-iso-mount": "Mount an ISO.",
    "server-iso-unmount": "Unmount an ISO.",
    "server-pin-dash": "Pin in your dashboard.",
    "account-ssh": "Your keys will be visible during your server creation process.",
    "account-api": "Available at the next release.",
    "account-2fa": "The two-factor authentication will strenghten your account security.",
    "user-pref": "To personalize your SmallCloud experience, save here your preferences. This section can be modified at any time.",
    "licenses": "WARNING: The choice of licence is definitive. Once you have completed your order, you will not be able to change your licence plan.",
    "company-user": "The 'Accountant' type allows access to invoices and payment processing.\nThe 'Manager' type provides access to technical server management.\nThe 'Administrator' type grants access to all functionalities.",
    "company-del-user": "Remove user",
    "company-send-inv": "Resend invite",
    "company-role-edit": "Edit access.",
    "company-del-invitation": "Remove invite.",
    "company-del-role": "Delete access.",
    "show-user-vcard": "See the user's vCard.",
    "reinit-filter": "Reset all filters",
    "tickets-prio-change": "Change the priority level",
    "tickets-header-link": "Create a new ticket.",
    "tickets-link-to-vm": "Open the server in a new tab.",
    "subscription-rename": "Rename the project.",
    "subscription-change-freq": "Change the frequency.",
    "subscription-see-all": "Invoices linked to this project.",
    "subscription-see-next": "Your next payment due.",
    "subscription-next-frequency": "The new frequency will be taken into account when renewing the invoice.",
    "network-rename": "Rename Vswitch",
    "company-oid": "Unique identifier of the company.",
    "ticket-indexer": "Update tickets from IMAP",
    "subscription-payment-method": "Assign or reset the payment method for the subscription",
  },

  "severity": {
    "0": "Check settings",
    "1": "Information",
    "2": "Warning",
    "3": "Alert",
  },

  "actions": {
    "send": "Send",
    "filter": "Filter",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "save": "Save",
    "generate": "Generate",
    "close": "Close",
    "delete": "Delete",
    "back": "Back",
    "next": "Next",
    "buy": "Buy",
    "quit": "Quit",
    "validate": "Ok",
    "pay": "Pay",
    "enable": "Enable",
    "disable": "Disable",
    "apply": "Apply",
    "reboot": "Reboot now",
    "reboot-acceptation": "I agree to have my server restarted immediately",
    "more": "Show more",
    "less": "Show less",
    "search-servers": "Search servers",
    "search-networks": "Search networks",
    "click-to-copy": "Click to copy",
    "copied": "Copied",
    "change-avatar": "Change avatar",
    "remove-avatar": "Delete avatar",
    "create_notification": "Create notification",
    "buy_space": "Buy storage",
    "create": "Create",
    "update": "Update",
    "refund": "Refund",
    "add": "Add",
    "message-avatar": "Click to edit",
    "download": "Download",
    "new": "New",
    "modify": "Modify",
    "create-list": "Create a list",
    "show-list": "View/Edit the list",
    "open-all": "Open all",
    "close-all": "Close all",
    "re-init": "Reinitialize",
    "new-f": "New",
    "open-list": "Open the list",
    "close-list": "Close the list",
    "terminate": "Terminate",
    "cancel-terminate": "Cancel termination",
    "multi-pay": "Multi-payment",
    "cancel-delete": "Cancel deletion",
    "phone-link": "Direct Call",
    "init-drp": "Access DRP",
    "reset-drp": "Exit DRP",
  },
  "popup": {
    "coming-soon": "Coming soon",
  },
  "modal": {
    "definitive-action": "This action is irreversible",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "finalize": "Finalize the order",
    "confirm-text": "This operation must be confirmed by typing « CONFIRM » below",
    "confirm-placeholder": "Type CONFIRM",
    "confirmation-word": "CONFIRM",
    "server-list": {
      "attached-servers": "Attached Servers",
      "not-attached-servers": "Not Attached Servers",
      "all": "All",
      "no-server": "No servers",
    },
    "dialog-reset-network": {
      "title": "Reset Network Interface",
      "warning": "This action is irreversible",
      "message": "By confirming this action, the network interface of the server will be reset.",
      "extra": "Are you sure you want to reset the network interface of the server?",
      "message2": "By confirming this action, the servers network interface will be reset.",
      "extra2": "Are you sure you want to reset the network interface of these servers?"
    },
    "dialog-reset": {
      "title": "Server reset",
      "warning": "This action is irreversible",
      "message": "By confirming this action, your server will be stopped until re-activation. Your data and snapshots will be permanently deleted.",
      "extra": "Are you sure you want to reset this server?"
    },
    "dialog-terminate": {
      "title": "Server resiliation",
      "warning": "This action is dangerous but reversible",
      "message": "By confirming this action, your server will be resiliated.",
      "extra": "Are you sure you want to resiliate this server?",
      "button": "Resiliate"
    },
    "dialog-cancel-terminate": {
      "title": "Cancelling the server termination",
      "warning": "This action is dangerous",
      "message": "By confirming this action, the termination of your server will be cancelled.",
      "extra": "Are you sure you want to cancel the termination of this server?",
      "button": "Cancel the termination"
    },
    "dialog-snapshot-restore": {
      "title": "Restoring snapshot",
      "warning": "This action is irreversible",
      "message": "By confirming this action, your server will be restored on this snapshot.",
      "extra": "Are you sure you want to restore this snapshot?"
    },
    "dialog-snapshot-delete": {
      "title": "Snapshot deletion",
      "warning": "This action is irreversible",
      "message": "By confirming this action, your snapshot will be deleted.",
      "extra": "Are you sure you want to delete this snapshot?"
    },
    "dialog-ip-detach": {
      "title": "Detach an IP",
      "warning": "This action is irreversible",
      "message": "By confirming this action, this IP will be detached from the server.",
      "extra": "Are you sure you want to detach this IP?"
    },
    "dialog-payment-method-remove": {
      "title": "Delete this payment method.",
      "warning": "This is an important action.",
      "message": "By confirming this action, this payment method will be remove.",
      "extra": "Are you sure you want to remove this payment method ?  ",
      "extra2": "Subscriptions using this payment method will be reset to the default payment method.",
    },
    "dialog-order-cancel": {
      "title": "Cancel this order",
      "warning": "This is an important action.",
      "message": "By confirming this action, this command will be cancelled.",
      "extra": "Are you sure you want to cancel this order?"
    },
    "dialog-changing-frequency": {
      "title": "Are you sure you want to change the billing frequency?",
      "warning": "The change will be effective the next time you billing",
      "message": "The new billing frequency:  ",
    },
    "dialog-changing-project-name": {
      "title": "Are you sure you want to change the name of the project?",
      "warning": "The name change on your invoice will be effective on your next billing",
      "message": "Your new project name will be: ",
    },
    "dialog-ConfirmRoleChange": {
      "title": "Are you sure to change the role of ",
      "warning": "This is an important action.",
      "message": "His/her/their new role will be: ",
    },
    "dialog-user-delete": {
      "title": "Delete this user",
      "warning": "This action is definitive",
      "message": "By confirming this action, this user will be deleted.",
      "extra": "Are you sure you want to delete this user?"
    },
    "dialog-invitation-delete": {
      "title": "Delete this invitation",
      "warning": "This action is definitive",
      "message": "By confirming this action, this invitation will be deleted.",
      "extra": "Are you sure you want to delete this invitation?"
    },
    "dialog-ticket-level-change": {
      "title": "Changing the priority level of your ticket",
      "warning": "This action is important",
      "message": "By confirming this action, your ticket will change priority.",
      "extra": "Are you sure you want to change the priority of your ticket?"
    },
    "dialog-company-update": {
      "title": "Modification of company information?",
      "success": "This new information will be added to your next invoice.",
      "message": "By confirming this action, your company information will be modified.",
      "extra": "Are you sure you wan to modify this information?"
    },
    "dialog-payment-cancel": {
      "title": "Cancellation of a waiting payment.",
      "warning": "This action is definitive.",
      "message": "By confirming this action, your payment will be cancelled.",
      "button": "Confirm"
    },
    "dialog-payment-refund": {
      "title": "Refund this payment",
      "warning": "This action is final",
      "message": "By confirming this action, you will make this refund.",
      "button": "Confirm"
    },
    "dialog-delete-image": {
      "title": "Delete the image.",
      "warning": "This action is irreversible",
      "message": "By confirming this action, your image will be permanently deleted.",
      "button": "Delete"
    },
    "dialog-role-delete": {
      "title": "Role deletion",
      "warning": "This action is irreversible",
      "message": "By confirming this action, your role will be deleted.",
      "extra": "Are you sure you want to delete this role?"
    },
    "dialog-ticket-creation": {
      "title": "Ticket creation",
      "success": "New ticket will be created after confirmation",
      "message": "By confirming this action, the new ticket will be created.",
      "button": "Confirm"
    },
    "dialog-ticket-close": {
      "title": "Closing ticket",
      "title2": "Closing the Ticket Without Response",
      "warning": "Ticket will be closed after confirmation",
      "message": "Confirming this action will close the ticket.",
      "button": "Confirm"
    },
    "dialog-item-pricing-update": {
      "title": "Added new price",
      "warning": "This action is important",
      "message": "You are modifying the item {item} - New price: {price}",
      "button": "Create",
      "extra": "Are you sure?"
    },
    "dialog-item-pricing-remove": {
      "title": "Deleting a new price",
      "warning": "This action is irreversible",
      "message": "You are deleting the item {item} update - The new price was: {price}",
      "button": "Delete",
      "extra": "Are you sure?"
    },
    "dialog-item-creation": {
      "title": "Creating an article",
      "warning": "This action is important",
      "message": "You are creating item {item} of type: {type}",
      "button": "Create",
      "extra": "Are you sure?"
    },
    "dialog-item-update": {
      "title": "Updating an article",
      "warning": "This action is important",
      "message": "You are updating item {item} of type: {type}",
      "button": "Update",
      "extra": "Are you sure?"
    },
    "dialog-item-account-remove": {
      "title": "Deleting a new account code",
      "warning": "This action is irreversible",
      "message": "You are deleting the item {item} update - The new account code was: {code}",
      "button": "Delete",
      "extra": "Are you sure?"
    },
    "dialog-item-account-update": {
      "title": "Added a new account code",
      "warning": "This action is important",
      "message": "You are modifying item {item} - New account code: {code}",
      "button": "Create",
      "extra": "Are you sure?"
    },
    "dialog-multi-pay-confirm": {
      "title": "Multiple payment",
      "warning": "This action will register a payment",
      "message": "Do you validate the creation of this payment?",
      "button": "Validate",
    },
    "dialog-confirm-hyperV-migration": {
      "title": "You will trigger a migration",
      "warning": "This action is important",
    },
    "dialog-confirm-hyperV-change-state": {
      "title": "Hypervisor state change - { hostname } -",
      "danger": "You will disable this hypervisor",
      "warning": "You are about to activate this hypervisor",
      "message": "Are you sure you want to change the state of the hypervisor - { hostname } -?"
    },
    "dialog-confirm-hyperV-change-state-all": {
      "title": "Changing the state of all hypervisors",
      "danger": "You are about to change the state of all hypervisors",
      "message": "Are you sure you want to proceed with this operation?",
    },
    "dialog-quote-delete": {
      "title": "Delete Quote",
      "warning": "This action is irreversible",
      "message": "By confirming this action, this quote will be deleted",
      "extra": "Are you sure you want to delete this quote?"
    },
    "dialog-quote-accept": {
      "title": "Accept a quote",
      "warning": "This action is irreversible",
      "message": "By confirming this action, this quote will be accepted",
      "extra": "Are you sure you want to accept this quote?"
    },
    "dialog-notification-delete": {
      "title": "Delete notification",
      "warning": "This action is irreversible",
      "message": "By confirming this action, this notification will be deleted",
      "extra": "Are you sure you want to delete this notification?"
    },
    "dialog-vat-update": {
      "title": "You are going to add VAT to the country {country}.",
      "warning": "This action is important",
      "message": "The new VAT for {country} will be {percentage}% on {date}",
      "button": "Update",
      "extra": "Are you sure?"
    },
    "dialog-vat-remove": {
      "title": "You will remove VAT from country {country}.",
      "warning": "This action is important",
      "message": "VAT of {percentage}% on {date} in {country} will be removed.",
      "button": "Update",
      "extra": "Are you sure?"
    },
    "dialog-start-demo": {
      "title": "Start Demo",
      "warning": "This action will deploy an unpaid server",
      "message": "By confirming this action, the server will be deployed without payment from the client.",
      "button": "Start",
      "extra": "Are you sure you want to enable demo mode?"
    },
    "dialog-stop-demo": {
      "title": "Stop Demo",
      "warning": "This action will deploy an unpaid server",
      "message": "By confirming this action, the server will be disabled until the customer pays. You can still activate the demo mode.",
      "button": "Stop",
      "extra": "Are you sure you want to disable demo mode?"
    },
    "dialog-enable-server": {
      "title": "Enable server",
      "success": "This action will activate the server",
      "message": "By confirming this action, the server will be activated even if the customer has not paid his invoice. You can always deactivate it",
      "button": "Enable",
      "extra": "Are you sure you want to activate the server?"
    },
    "dialog-disable-server": {
      "title": "Disable server",
      "danger": "This action will disable the server",
      "message": "By confirming this action, all communication with the server will be disabled. You can always re-enable it",
      "button": "Disable",
      "extra": "Are you sure you want to disable the server?"
    },
    "dialog-commercial-delete": {
      "title": "Remove Sales",
      "danger": "This action is irreversible",
      "message": "By confirming this action, this commercial will be deleted",
      "extra": "Are you sure you want to delete this commercial?"
    },
    "dialog-alert-delete": {
      "title": "Delete a Task",
      "danger": "This action is irreversible",
      "message": "By confirming this action, this task will be deleted",
      "extra": "Are you sure you want to delete this task?",
    },
    "dialog-line-delete": {
      "title": "Delete a line",
      "danger": "This action is irreversible",
      "message": "By confirming this action, this line will be deleted",
      "extra": "Are you sure you want to delete this line?"
    },
    "dialog-changing-outsourcing": {
      "title": "Outsourcing modification",
      "warning": "Are you sure you want to change the outsourcing level?",
      "message": "By confirming this action, the outsourcing level will be modified",
    },
    "dialog-delete-server": {
      "title": "Delete Server Immediately",
      "danger": "This action is irreversible",
      "message": "By confirming this action, the server will be deleted.",
      "extra": "Are you sure you want to delete this server?"
    },
    "dialog-invoice-ip": {
      "title": "Agreement Before Cancellation",
      "warning": "The old primary IP address of this server will be invoiced",
      "message": "By confirming this action, the old primary IP address of the server will be invoiced.",
      "extra": "Are you sure you want to continue?"
    },
    "dialog-cancel-terminate-ip": {
      "title": "Cancel IP Termination",
      "warning": "This action is reversible",
      "message": "By confirming this action, the IP termination will be canceled.",
      "extra": "Are you sure you want to cancel the termination of this IP?",
      "button": "Cancel Termination"
    },
    "dialog-changing-payment-state": {
      "title": "Are you sure you want to change the payment status?",
      "warning": "The change will take effect immediately.",
      "message": "The new payment status is:",
    },
    "dialog-subscription-update": {
      "title": "Subscription Update",
      "warning": "If you have removed items, they will be lost.",
      "message": "By confirming this action, the subscription will be modified.",
      "extra": "Are you sure you want to modify this subscription?",
    },
    "dialog-add-ipv6": {
      "title": "Adding IPV6 to the server",
      "success": "The IPV6 address will be added after confirmation",
      "message": "You can always remove it from your address pool later",
      "button": "Confirm",
    },
    "dialog-ticket-trash-delete": {
      "title": "Delete a 'Trash' Ticket",
      "danger": "This action is irreversible",
      "message": "By confirming this action, the ticket will be deleted (it will not be removed from the email box).",
      "extra": "Are you sure you want to delete this ticket?",
    },
    "dialog-ticket-trash-delete-many": {
      "title": "Delete Multiple 'Trash' Tickets",
      "danger": "This action is irreversible",
      "message": "By confirming this action, the selected tickets will be deleted (they will not be removed from the email box).",
      "extra": "Are you sure you want to delete the selected tickets?",
    },
    "dialog-apply-sub-payment-method": {
      "title": "Are you sure you want to use this payment method?",
      "warning": "The change will take effect on your next billing cycle",
      "message": "Payment method to use: {payment_method}",
    },
    "dialog-remove-sub-payment-method": {
      "title": "Are you sure you want to revert to the default payment method?",
      "warning": "The change will take effect on your next billing cycle",
      "message": "By confirming this action, the payment method will be reset",
    },
    "dialog-order-delete": {
      "title": "Delete the Order",
      "warning": "This action is irreversible",
      "message": "By confirming this action, the order will be deleted.",
      "extra": "Are you sure you want to delete this order?",
    },
    "dialog-reset-2fa": {
      "title": "Disable User's 2FA",
      "warning": "This action is irreversible",
      "message": "By confirming this action, the user's 2FA will be disabled.",
      "extra": "Are you sure you want to disable the user's 2FA?",
    },
    "dialog-deactivate-drp": {
      "title": "Deactivate DRP",
      "warning": "This action is irreversible",
      "message": "By confirming this action, the DRP of your server will be deactivated. The corresponding subscription will be deleted.",
      "extra": "Are you sure you want to deactivate the DRP on this server?"
    },
    "scheduled-date": {
      "title-schedule-reboot": "Schedule a reboot",
      "title-snapshot": "Schedule a Snapshot",
      "already-scheduled": "Date already scheduled | Dates already scheduled",
      "new-schedule": "New schedule",
      "validation-error": "The time must be at least 5 minutes in the future",
      "dialog-delete-scheduled-date": {
        "title": "Delete scheduling",
        "warning": "The change will be applied upon confirmation",
        "message": "By confirming this action, the scheduling will be deleted.",
        "extra": "Are you sure you want to delete this scheduling?",
      },
    },
    "project-selection": {
      "title": "Project list",
      "placeholder": "Project name",
      "name": "Project name",
      "no-project": "No project",
      "number-of-projects": "No project | 1 project | {nb} projects",
    },
    "server-selection": {
      "title": "Server list",
      "number-of-servers": "No server | 1 server | {nb} servers",
    },
  },

  "ip": {
    "type": "V",
    "reverse": "Reverse",
    "attach": "Attach an IP",
    "detach": "Detach an IP",
    "buy": "Order new IPs",
  },

  "cart": {
    "apply-all": "Apply for all servers",
    "dialog-update-all-pass": {
      "title": "Copy of the authentication to the servers",
      "info": "",
      "message": "Do you want to apply this password and/or SSH keys for all servers in the basket?",
      "extra": "Are you sure?"
    },
    "quote": {
      "title": "Create quote",
      "create": "Generate quote",
      "validity_period": {
        "text": "Validity period",
        "periode": "Month"
      },
      "payment_methode": "Payment method",
      "payment_type": {
        'PSEPA': "Debit",
        'card': "CB",
      },
      "modal": {
        "title": "Add quote to cart",
        "message": "You have the option to overwrite your current cart, merge them or cancel the current action.",
        "merge": "Merge",
        "erase": "Overwrite",
        "cancel": "Cancel"
      },
    },
    "debug": {
      "validate": "Validate Order",
    },
    "no-authentication": "No means of authentication",
    "update": "Apply for this server",
    "title": "Your cart",
    "empty": "No order in the cart…",
    "summary": "Summary of your order:",
    "quantity": "Quantity",
    "add": "Add to cart",
    "continue": "Continue to order",
    "no_modal": "Do not display this message anymore",
    "pay": "Pay the order",
    "payment": "Payment",
    "order": "Order a server",
    "order-add": "Add a server",
    "removed": "Line removed",
    "updated": "Order updated",
    "success": "Order successfully payed",
    "selected_ssh_keys": "Selected SSH key :",
    "password_use": "Password used :",
    "project_name": "New project name",
    "project_name_create": "Select or create a project",
    "project_name_input_placeholder": "Enter project name",
    "project_name_feedback": "Between 4 and 60 characters.",
    "project_name_tip": "Choose your project name or one will be generated automatically.",
    "select_project_name": "Selected project name: ",
    "project_name_placeholder": "Project name",
    "project_name_selector": "Project name selection",
    "error_redirect": "You will be redirected to the Orders and Invoices page where you can finish the payment.",
    "license": "Including license",
    "update-credential": "Credentials updated.",
    "project": "Project",
    "frequency": "Frequency",
    "unit_amount": "Unit amount",
    "monthly_unit_amount": "Monthly unit amount",
    "first_payment": "First payment",
    "period": "Period from {date1} to {date2}",
    "discount": "Discount",
    "monthly": "Monthly amount",
    "quarterly": "Quarterly amount",
    "biannually": "Half-yearly amount",
    "annually": "Annual amount",
    "added_to_cart": "Added to cart",
    "item_to_cart": "Item(s) added to cart",
    "cart_updated": "Cart Updated",
    "server_already_in_cart": "Server(s) already in cart",
    "server_with_drp": "Server(s) with DRP already activated",
    "server_with_outsourcing": "Server(s) already with managed services",
    "server": "Server",
    "label1": "Company",
    "label2": "Commercial",
    "vswitch": "VSwitch",
    "amount": "Amount:",
    "mandatory": "Mandatory Field",
    "button_paid": [
      "Please select a payment method",
    ],
    "item_type": {
      "ip": "IP Address",
      "drp": "DRP",
      "outsourcing": "Managed Services",
      "vswitch": "VSwitch",
      "vswitch_port": "VSwitch Port",
      "vswitch_qos": "VSwitch QoS",
      "license": "License",
    },
    "project-modal": {
      "title": "Choose Project Name",
      "comment": [
        "All items from the same project will be grouped on a single invoice.",
      ],
      "header": [
        "Existing Projects List",
        "New Project",
      ],
      "divided": "OR",
    },
    "outsourcing": {
      "level": "Service Level",
      "standard": "Standard",
      "advanced": "Advanced",
      "full": "Full",
    },
    "over-quota-text": "# ⚠️ Important: Exceeding Resource Quantities\n" +
      "\n" +
      "**{name}**\n" +
      "\n" +
      "We would like to draw your attention to an important issue regarding your framework contract reference: **{reference}** dated **{date}**.\n" +
      "\n" +
      "It appears that the requested resource quantities exceed the quota of your contract reference {reference}. As a result, we cannot automatically allocate the requested resources immediately.\n" +
      "\n" +
      "📞 We invite you to contact our sales department as soon as possible to discuss possible solutions and agree on actions to regularize this situation. If you have an emergency, you can dial +33 9 88 99 8888. Our on-call team will be able to assist you. In parallel, we are informing your sales representative.\n" +
      "\n" +
      "🙏 We sincerely thank you for your understanding and valuable cooperation. We remain at your disposal for any questions or clarifications.\n" +
      "\n" +
      "With our best regards,\n" +
      "\n" +
      "*The SmallCloud Team*\n",
  },

  "user": {
    "name": "Name",
    "title": "Title",
    "phone": "Phone",
    "mobile": "Mobile",
    "email": "Email",
    "authorization": "Type",
    "role": "Access",
    "roles": {
      "SUPER_ADMINISTRATOR": "Super Administrateur",
      "ADMINISTRATOR": "Administrator",
      "ACCOUNTANT": "Accountant",
      "MANAGER": "Manager",
      "TITAN_SUPER_ADMINISTRATOR": "TITAN - Super Administrator",
      "TITAN_ADMINISTRATOR": "TITAN - Administrator",
      "TITAN_ACCOUNTANT": "TITAN - Accountant",
      "TITAN_COMMERCIAL": "TITAN - Commercial",
      "TITAN_MANAGER": "TITAN - Manager",
      "UNKNOWN": "Unknown",
      "undefined": "Undefined",
      "none": "All",
    }
  },

  "role": {
    "name": "Access name",
    "allowed-servers": "Allowed servers",
    "allowed-networks": "Allowed Networks",
    "authorization": "Type",
    "role": "Access",
    "type": "Type",
    "servers": "Servers",
    "networks": "Networks",
    "custom-roles": "Access",
    "add": "Add an access",
    "number_of_users": "Users",
    "number_of_invites": "Invitations",
  },

  "tickets": {
    "modal": {
      "title": "Message confirmation"
    },
    "tickets": "Tickets",
    "tickets-empty": "You do not have any tickets.",
    "open-ticket": "New ticket",
    "status": {
      "processing": "In progress",
      "in_progress": "In progress",
      "closed": "Closed",
      "assigned": "Assigned",
      "pending": "Pending",
      "pending_admin": "Pending",
      "pending_client": "Ongoing",
      "new_pending_admin": "Pending",
      "new_pending_client": "Ongoing",
      "new": "New",
    },
    "attachments": {
      "received": {
        "title": "List of files",
        "header": [
          "Last name",
          "Kind",
        ],
        "popup": "View Attachment | View Attachments",
        "error_message": "Error reading",
      },
      "sent": {
        "title": "Document(s) to add",
        "popup": "Add documents",
        "text": [
          "Drop your files here",
          "or",
          "click to select them",
        ],
        "placeholder": "File name",
      },
      "content_type_error": "Unauthorized file type",
    },
    "message": {
      "tooltip": [
        "See more",
        "See less"
      ]
    },
    "trash": {
      "message": [
        "Deletion successful",
        "Error during deletion",
      ],
    },
  },
  "invoices": {
    "invoice": "Invoice",
    "order_title": "Order",
    "credit_title": "Credit",
    "next_invoice_title": "Next Invoice :",
    "download-pdf": "Download PDF",
    "download-multi-disabled": "Select invoices to download",
    "download-multi": "Download selected invoices",
    "payment-multi-disabled": "Select unpaid invoices",
    "payment-multi": "Pay selected invoices",
    "payment-multi-unpaid": "Please select only unpaid invoices.",
    "delete-order": "Delete the order",
    "delete-success": "Deletion successful",
    "recurring": {
      "states": {
        "pending": "Pending",
        "ongoing": "Ongoing",
        "canceled": "Terminated"
      }
    },
    "states": {
      "all": "All",
      "upcoming": "Upcoming",
      "ribbon_pending": "Pending",
      "pending": "Pending",
      "ribbon_unpaid": "Unpaid",
      "unpaid": "Awaiting payment",
      "ribbon_paid": "Paid",
      "paid": "Paid",
      "ribbon_canceled": "Canceled",
      "canceled": "Canceled",
      "available": "Available",
      "spent": "Spent",
      "expired": "Expired",
    },
    "types": {
      "order": "Order",
      "invoice": "Invoice",
      "credit": "Credit"
    }
  },

  "kvm": {
    "title": "Remote access",
    "p1": "Your remote access is being created",
    "p2": "Please wait a moment.",
    "cancel": "Cancel",
    "start": "Start remote access",
    "stop": "Stop remote access",
    "fullscreen": "Full screen mode",
    "fullscreen_leave": "Exit full screen mode",
    "expand": "Expand",
    "compress": "Compress",
    "pip": "Switch to PIP mode"
  },

  "menu": {
    "upgrade": {
      "text": "New app version available: ",
      "button": "Reload the page",
      "later": "Later",
    },
    "faq": "FAQ",
    "accounts": "Accounts",
    "affiliations": "Affiliations",
    "menu": "Menu",
    "dashboard": "Dashboard",
    "ip-histo": "IPs history",
    "vms-sidebar": "Servers / Templates",
    "vms": "Servers",
    "image": "Templates",
    "container": "Containers",
    "dedicated-serveur": "Dedicated servers",
    "vms-add": "Order a server",
    "companies": "Company",
    "contacts": "Contacts",
    "network": "Networks",
    "templates": "Templates",
    "company": "Company",
    "tickets": "Tickets",
    "recurring": "Subscriptions",
    "documents": "Documents",
    "settings": "Settings",
    "logout": "Sign out",
    "lang": "language",
    "dark-mode-header": "Dark mode",
    "dark-mode": "Dark",
    "light-mode": "Light",
    "notifications": "Servers notifications",
    "intervention": "Other items",
    "stop_impersonate": "Return to normal user",
    "tools": "Tools",
    "cron": "Scheduled tasks",
    "preference": "Preferences",
    "scheduled-action": "Scheduled Actions",
    "drp-location": "DRP Locations",
  },

  "filters": {
    "search": "Search",
    "search-server": "Search for a server, OS, IP, MAC...",
    "reset": "Reset",
    "os": "Operating system",
    "all": "All",
    "service": "Service",
    "level": "Priority",
    "project": "Project name",
    "periodicity": "Periodicity",
    "affiliationLinks-per-page": "Number of links per page: ",
    "servers-per-page": "Servers by page: ",
    "invoices-credits-per-page": "Invoices by page: ",
    "invoices-orders-per-page": "Orders and invoices by page: ",
    "recurring-per-page": "Subscriptions by page: .",
    "images-per-page": "Number of images per page:",
    "tickets-per-page": "Tickets by page: ",
    "payments-per-page": "Payments by page: ",
    "clients-per-page": "Clients by page: ",
    "orders-per-page": "Orders by page: ",
    "ipsHisto-per-page": "Ip by page: ",
    "item-per-page": "Items per page: ",
    "discount-per-page": "Discount per page: ",
    "country-per-page": "Countries per page: ",
    "events-per-page": "Events per page: ",
    "elements-per-page": "Items per page: ",
    "notifications-per-page": "Notifications per page: ",
    "invoices-quote-per-page": "Quotes per page: ",
    "ip-per-page": "IPs per page:",
    "license-per-page": "Number of licenses per page:",
    "line-per-page": "Number of lines per page:",
    "invoice_types": {
      "invoice": "Invoice",
      "order": "Order",
      "credit": "Credit"
    },
    "status": {
      "started": "Started",
      "stopped": "Stopped",
      "pending": "Pending",
      "waiting": "Waiting",
      "new": "New",
      "closed": "Closed",
      "deleted": "Deleted"
    },
    "ticket": {
      "processing": "In progress",
      "in_progress": "In progress",
      "closed": "Closed",
      "assigned": "Assigned",
      "pending": "In progress",
      "pending_admin": "Waiting",
      "pending_client": "In progress",
      "new": "New",
      "new_pending_admin": "New",
      "new_pending_client": "New",
      "number": "Ticket number",
      "object": "Object",
      "company": "Company",
      "read": "Read",
      "unread": "Unread",
      "admin": {
        "pending_admin": "To be processed",
        "pending_client": "Processed",
        "closed": "Closed",
      },
      "client": {
        "pending_admin": "Processed",
        "pending_client": "To be processed",
        "closed": "Closed",
      },
    },
  },

  "ticket": {
    "server": "Server | Servers",
    "status": "Responded",
    "number": "Nº",
    "user": "Creator",
    "object": "Object",
    "service": "Service",
    "product": "Product",
    "created_at": "Creation date",
    "messages": "Messages",
    "company": "Company",
    "lock": "Lock",
    "unlock": "Unlock",
    "company_info": "Customer Information",
    "last_reboot": "Last reboot",
    "indexer": "Reindex",
    "state_date": "Last update",
    "outsourcing_level": "Service Level",
    "level": "Priority",
    "sort-by": "Sort by",
    "sort-button-tip-apply": "Apply sorting",
    "sort-button-tip-cancel": "Cancel",
    "state-date": "Last Status",
    "ticket-new": "New Ticket",
    "ticket-response": "Ticket",
    "read": "Read",
    "toggle-unread": "Mark ticket as unread",
    "toggle-read": "Mark ticket as read",
    "reply-sent": "Reply sent",
  },
  "admin": {
    "client": {
      "actif": {
        "true": "Inactive",
        "false": "Active"
      },
      "filter": "Customer filter",
    },
    "discount": {
      "actif": {
        "true": "Active",
        "false": "Inactive"
      },
      "type": {
        "FIX": "Fix",
        "PERCENTAGE": "Percentage"
      },
    },
    "item": {
      "title": "Items",
      "button": "New",
      "sort": {
        "sort1": {
          "title": "Eligible for discounts",
          "state": {
            "true": "Active",
            "false": "Inactive"
          },
        },
        "sort2": {
          "title": "Subscription Eligible",
          "state": {
            "true": "Yes",
            "false": "No"
          },
        },
        "sort3": {
          "title": "Active",
          "state": {
            "true": "Yes",
            "false": "No"
          },
        },
      },
      "header": {
        "name": "Name",
        "plan": "Plan",
        "category": "Category",
        "enabled": "Active",
        "type": "Type",
        "description": "Description",
        "price": "Current price excl. VAT",
        "discount_enabled": "Discounts",
        "status": "Status",
        "subtype": "Subtype",
        "unit": "Unit",
      },
      "type": {
        "CPU": "CPU",
        "DISK": "Disk",
        "RAM": "RAM",
        "HARDWARE": "Hardware",
        "SERVICE": "Service",
        "LICENSE": "License",
        "QOS": "QOS",
        "MAC": "MAC",
        "IP": "IP",
        "SNAPSHOT": "Snapshot",
        "STORAGE": "Pool Storage",
        "CREDIT": "Credit",
        "REBOOT": "Server Reboot",
        "SMS": "SMS",
        "VSWITCH": "VSwitch",
        "rent": "Rent",
        "buy": "Buy"
      },
      "creation": {
        "creation_title": "Creating a new article",
        "update_title": "Update of an article",
        "modal": {
          "label1": "Name",
          "placeholder1": "",
          "label2": "Description",
          "placeholder2": "Description",
          "label3": "Item Type",
          "placeholder3": "",
          "label4": "Billing method",
          "placeholder4": "",
          "label5": "Discount",
          "placeholder5": "",
          "label6": "Active",
          "placeholder6": "",
          "label7": "Price excl. VAT",
          "placeholder7": "Price excl. VAT",
          "label8": "Accounting code",
          "placeholder8": "Accounting code",
          "label9": "Plesk Code",
          "placeholder9": "",
          "label10": "Subtype",
          "label11": "Number of credits",
          "edit_price": {
            "label1": "Price excl. VAT",
            "placeholder1": "Price excl. VAT",
            "label2": "Apply Date",
            "placeholder2": "Apply Date",
          },
          "edit_code": {
            "label1": "Accounting code",
            "placeholder1": "Accounting code",
            "label2": "Apply Date",
            "placeholder2": "Apply Date",
          },
          "label12": "Unit",
          "edit_unit": {
            "label1": "Value",
            "label2": "Type",
          },
        },
        "message": {
          "create_success": "Creation successfull",
          "update_success": "Update successful",
          "remove_success": "Successful deletion",
        },
      },
      "add_item": {
        "title": "Add an item to a subscription",
        "header1": "Item",
        "header2": "Serial number",
        "header3": "Server OID",
        "header4": "Qty",
        "checkbox1": "Create partial invoice",
        "checkbox2": "The item is deployed",
        "start_date": "Invoice start date",
      },
      "manage_item": {
        "title": "Subscription Item Management",
        "title-list": "List of Subscription Items",
        "delete-item": "Delete item",
        "delete-all": "Delete Subscription"
      },
    },
    "accounting": {
      "tabs": {
        "title": {
          "invoices": "Documents",
          "taxes": "Taxes",
          "payment": "Payments",
          "orderForm": "Orders",
          "csv": "Export CSV",
          "dispute": "Disputes",
          "statistics": "Statistics",
        },
      },
      "menu": [
        "Clients",
        "Memberships",
        "Documents",
        "Subscriptions",
        "Items",
        "Coupons",
        "Taxes",
        "Payment period",
        "Daily Tasks",
        "Outsourcing",
        "Miscellaneous",
        "Licenses",
      ],
    },
    "company": {
      "contact": {
        "title": "Company Contacts",
        "user": "User",
        "email": "Email address",
        "role": "Role",
        "phone": "Phone",
        "role_from": "You are about to change the role of {name}",
        "role_to": "Her new role will be: {name}",
        "tooltip": [
          "View as this user",
          "Generate a vCard",
          "Detailed information",
        ],
      },
      "tabs": [
        "Clients",
        "Quotation",
        "Commercial",
      ],
      "tab_title": "Commercials",
      "modal": {
        "title": "Assign a salesperson",
      },
      "popup": [
        "Editing in progress",
        "Click to edit",
      ],
      "detail": {
        "tab": [
          "Info",
          "VAT",
          "Contacts",
          "Commercial",
          "Memberships",
          "Quotas and limitations",
          "Inter-company transfer",
          "Additional settings",
        ],
        "info": {
          "label": [
            "Company Name",
            "E-mail",
            "Level of reduction",
            "Company disabled",
            "Active payment method",
            "Commercial",
            "Payment deadline",
            "Authorized amount",
            "None",
            "Automatic Resource Deployment",
            "Automatic sending of the invoice by email",
            "Single payment for multiple invoices",
            "URL for the DRP",
            "Phone Number",
            "Number of credits",
            "SMS sending authorization",
            "Consolidate services into a single invoice",
          ],
          "option": [
            "None",
            "Immediate | {days} day | {days} days",
            "End of month | {days} day end of month | {days} days end of month",
          ],
          "help": [
            "To add a new discount, please click on this button in edit mode.",
          ],
        },
        "vat": {
          "label": [
            "Intra-community VAT number",
            "Valid",
            "Checked",
          ],
          "information": [
            "Address",
            "Country code",
            "Last name",
            "Query date",
            "VAT number",
            "Status",
          ],
          "status": {
            "true": "Valid",
            "false": "Invalid",
          },
          "popup": [
            "Check and see information",
            "Check",
            "Cancel change"
          ],
        },
        "commercial": {
          "message": [
            "There is no salesperson assigned to this company.",
          ],
          "title": "Company Sales",
          "button": [
            "Assign a salesperson",
            "Change commercial",
          ],
          "header": [
            "Last name",
            "First name",
            "E-mail",
          ],
          "modal": {
            "title": "Assign a salesperson",
          },
        },
        "quota": {
          "label": [
            "Quota",
            "Restrictions",
            "Vswitchs",
          ],
          "header": [
            "Networks",
            "Servers",
            "CPU",
            "Memory",
            "Disk",
            "Snapshots",
            "Storage",
            "Interfaces",
            "Number of Ports",
            "Qos",
          ],
        },
        "transfer": {
          "header": [
            "Type of transfer",
            "Recipient company",
            "Subscription(s) to transfer",
            "Server(s) to transfer",
            "Destination subscription",
            "Resetting prices",
          ],
          "type": {
            "1": "Complete transfer to another company",
            "2": "Transfer of subscription(s) to another company",
            "3": "Transfer of server(s) to another company",
            "4": "Transfer of server(s) between subscriptions of the same company",
          },
          "help": {
            "1": "All subscriptions, servers, IPs, and Vswitches will be transferred to the destination company.",
            "2": "The selected subscriptions along with the associated servers and IPs will be transferred to the destination company.",
            "3": "The selected servers along with their associated IPs will be transferred to the destination company.",
            "4": "The selected servers will be transferred to another subscription of the same company.",
            "5": "By default, the destination subscription will have the same name as the original.",
            "6": "The prices of the transferred servers will be initialized to zero in the destination subscription, allowing them to be free."
          },
        },
      },
    },
    "coupon": {
      "title": "Discount coupons",
      "button_new": "New",
      "button_close": "Close",
      "button_add": "Add",
      "status": {
        "title": "State",
        "enable": "Active",
        "disable": "Inactive",
      },
      "code": "Code",
      "use": "Use",
      "description": "Description",
      "expiry_date": "Expiry date",
      "never": "Never",
      "no_coupon": "No coupon found",
      "popover": {
        "modify": "Modify",
        "enable": "Enable",
        "duplicate": "Duplicate",
        "disable": "Disable",
        "delete": "Delete",
        "at_start_date": "Effective when created",
        "always": "Always",
        "unlimited": "0 = unlimited",
        "need": "You must select at least one item and enter a code",
        "need_member": "You must enter a code and either enter a total discount or select at least one item",
      },
      "modal": {
        "title": "Coupon Detail",
        "code": "Code:",
        "description": "Description:",
        "expiry_date": "Expiry date: ",
        "max_use": "Max uses: unlimited | Max uses: {count}",
        "first_order": "Only on the 1st order from a company",
        "customers": "Target Customer: | Target Customers:",
        "items": "No item | Target item: {count} | Target items: {count}",
        "number": "No",
        "invoice_date": "Invoice date",
        "customer": "Customer",
        "total": "Total excl. VAT",
        "discount_amount": "Discount amount"
      },
      "create": {
        "title": "Creation/Update of a coupon",
        "tab1": {
          "title": "Coupon Settings",
          "label": "",
          "code": "Coupon Code",
          "first_order": "Only on the 1st order of a company",
          "customers": "Target a specific customer",
          "description": "Description",
          "items": "Number of selected items",
          "start_date": "Start date",
          "expiry_date": "Expiry date",
          "max_use": "Maximum number of total uses",
          "max_use_client": "Maximum number of uses per client",
          "amount": "Total Discount",
          "help": "Enter either a total discount or a list of items",
        },
        "tab2": {
          "title": "Item Management",
          "label1": "Item selected | Item selected | Items selected",
          "label2": "Add one or more items",
          "label3": "Maximum quantity per coupon",
          "header": [
            "Item",
            "Type of reduction",
            "Discount Value",
            "Max qty",
          ],
          "discount": {
            "kind": {
              "FIX": "Fixed amount",
              "PERCENTAGE": "Percentage"
            },
            "type_sign": {
              "FIX": "€",
              "PERCENTAGE": "%"
            },
            "help": "Empty means no limit",
          },
        }
      },
      "alert": [
        "Coupon Activated",
        "Coupon disabled"
      ],
      "switch": {
        "coupon": "General",
        "member": "Member",
        "affiliate": "Affiliate"
      },
    },
    "multi_payment": {
      "title": "Multiple Payment",
      "amount_received": "Amount received",
      "total_amount_received": "Total amount received ({total} €)",
      "payment_date": "Payment date",
      "payment_method": "Payment method",
      "reference_number": "Reference number",
      "unpaid_invoices": {
        "title": "Unpaid invoices",
        "header1": "Date",
        "deadline": "due date",
        "header2": "Invoice number",
        "header3": "Amount",
        "header4": "Amount due",
        "header5": "Payment",
      },
      "non_chargeable_amount": "Non chargeable amount {amount}€",
    },
    "payment": {
      "modal": {
        "invoice_date": "Invoice Date",
        "payment_amount": "Payment Amount",
        "refund_amount": "Refunded amount",
        "reference_number": "Reference",
        "client": "Customer",
        "invoice_number": "Invoice",
        "status": "Status",
        "bank_status_code": "Bank status code",
        "bank_status_message": "Bank Status Message",
        "dispute": "Dispute",
        "bank_payment_id": "Bank reference",
        "regularized": "Regularized by",
        "goto-payment": "View payment details",
      },
      "tab": {
        "title": "Add payment",
        "header1": "type",
        "header2": "Transaction ID",
        "header3": "Amount",
        "header4": "Execution date",
        "modal_question": "Are you sure you want to create this payment?",
      },
    },
    "refund": {
      "refund_number": "Reference {reference}",
      "initial_amount": "- Settlement amount including tax: ",
      "message_no_invoice": "No invoice was paid by this settlement",
      "checkbox1": "Do not create credit note",
      "checkbox2": "Partial Refund",
      "help": "Choose or enter a reason",
      "related_invoice": "Related invoice | Related invoices",
      "header1": "Name",
      "header2": "Qty",
      "header3": "Price excl. VAT",
      "credit": "Remaining credit excl. tax",
      "amount_excluding_vat": "Amount excl. VAT",
      "amount_including_vat": "Amount including VAT",
      "total_excluding_vat": "Total excluding VAT",
      "total_including_vat": "Total including VAT",
      "prepend": "Refund amount including tax",
      "total_credit": "Total remaining credit excl. tax",
      "total_refund": "Total Refund",
      "reasons": [
        "Reason for refund",
        "Duplicate payment",
        "Fraudulent",
        "Requested by the customer",
      ],
      "placeholder": [
        "Reference",
        "Bank",
        "Creation Date",
        "Reason to have it",
        "Qty",
        "Price",
        "Refund amount without credit",
      ],
      "excluding_vat": "Excl.Vat",
      "including_vat": "Incl.Vat",
    },
    "migration": {
      "title": "Hypervisor Migration",
      "label1": "Migration mode",
      "label2": "Select source hypervisor",
      "label3": "Source Server UUID",
      "label4": "Select Target Hypervisor",
      "label5": "Hypervisor",
      "no_hypervisor": "No hypervisor available",
      "success_message1": "Hypervisor { hostname } will be { action }.",
      "success_message2": "Migration started",
      "title-list": "List of Hypervisors",
      "state": {
        "disable": "Disabled",
        "enable": "Enabled",
      },
      "success_message": "The changes were made successfully",
    },
    "notification": {
      "button_migration": "HyperV Migration",
      "button_management": "hyperV Management",
      "button_assignment": "Assign notification",
      "header": [
        "Severity",
        "Title",
        "Message",
        "Restart",
        "Servers",
        "Approval",
      ],
      "modal": {
        "title": "Assign a notification",
        "title_add": "Notification",
        "label": [
          "Severity",
          "Title",
          "Message",
          "Force Restart",
          "Server OIDs",
          "Consent for deletion notification",
        ],
      },
      "reboot": [
        "Yes",
        "No",
      ],
      "agreement": [
        "Yes",
        "No",
      ],
    },
    "vat": {
      "title": "Changing the VAT rate of a country",
      "filter": "VAT > 0",
      "header": [
        "Country",
        "Country code",
        "Percentage",
      ],
      "label": [
        "Date of application",
        "Percentage"
      ],
      "message": [
        "Update successful",
        "Chosen date < today's date"
      ],
    },
    "export": {
      "label": [
        "Date filter",
        "Scriptures",
        "Companies",
        "New companies",
        "Updated Companies",
        "Download CSV",
        "Downloading...",
        "Contacts",
        "For CRM",
        "New contacts",
        "Updated contacts",
      ],
    },
    "payment_delay": {
      "title": "Payment deadlines",
      "button": "New",
      "spell": {
        "state": {
          "true": "Active",
          "false": "Inactive"
        },
      },
      "header": [
        "Nb of days",
        "Month-end",
        "Asset",
      ],
      "operation": {
        "title": [
          "Creating a payment term",
          "Update of a payment deadline",
        ],
        "header": [
          "Nb of days",
          "Month-end",
          "Asset",
        ],
        "message": [
          "Successful creation",
          "Error while creating",
          "Update successful",
          "Error while updating",
        ],
      },
    },
    "alert": {
      "title": "Daily Tasks",
      "button": "New",
      "sort": {
        "state": {
          "true": "Active",
          "false": "Inactive",
        },
      },
      "header": [
        "Task Type",
        "Template Category",
        "Template Name",
        "Template Extension",
        "Send Type",
        "Max Sends per Day",
        "Deadline",
        "Deadline Type",
        "Bcc Email",
        "Active",
        "Disable Server",
      ],
      "operation": {
        "title": [
          "Task Creation",
          "Task Update",
        ],
        "header": [
          "Task Type",
          "Template Category",
          "Template Name",
          "Template Extension",
          "Send Type",
          "Max Sends per Day",
          "Deadline",
          "Deadline Type",
          "Bcc Email",
          "Active",
          "Disable Server",
        ],
        "message": [
          "Creation Successful",
          "Error During Creation",
          "Update Successful",
          "Error During Update",
        ],
      },
      "type": {
        "before_server_deletion": "Before Server Deletion",
        "delete_server": "Server Deletion",
        "delete_ip": "IP Deletion",
        "delete_outsourcing": "Terminate Managed Services",
        "invoice_unpaid": "Unpaid Invoice",
        "payment_method_expired": "Payment Method Expired",
        "invoice_renew": "Invoicing",
        "payment_error": "Payment Error",
        "cart_is_paid": "Cart Paid",
        "ticket_before_closing": "Before Ticket Closing",
        "ticket_closing": "Ticket Closing",
        "managed_service_notification": "Expiration of Managed Service",
      },
      "template_category": {
        "server": "Server",
        "renewal": "Renewal",
        "payment": "Payment",
        "invoice": "Invoices",
        "ticket": "Ticket",
        "ip": "IP Address",
        "outsourcing": "Managed Services",
      },
      "send_type": {
        "email": "Email",
      },
      "time_diff_type": {
        "day": "Day",
        "month": "Month",
      },
      "help": [
        "How many times the email is sent on the same day or month (-1: Always, 0: Once)",
        "Number of days/months before (negative value) or after (positive value) the control date",
        "Email address receiving the message in addition to the client's (optional)",
        "Activates/Deactivates the task",
        "Only for unpaid invoices. Executes server deactivation if checked.",
      ]
    },
    "miscellaneous": {
      "title": "Miscellaneous",
      "none_option": "None",
      "param": {
        "title": "Settings",
        "button": "New",
        "header": [
          "Name",
          "Kind",
          "Value",
          "Wording",
          "Date",
          "Min value",
          "Max value",
          "Dictionary key",
          "Dictionary value",
        ],
        "operation": {
          "title": [
            "Creation",
            "Update",
          ],
          "header": [
            "Name",
            "Kind",
            "Value",
            "Wording",
            "Date",
            "Min value",
            "Max value",
            "Wording",
          ],
          "message": [
            "Successful creation",
            "Error while creating",
            "Update successful",
            "Error while updating",
            "Successfully deleted",
            "Error while deleting",
          ],
        },
      },
      "function": {
        "title": "Functions",
        "all": "All",
        "help": [
          "Choose a company or leave blank for all companies",
          "Enter the primary IPv4 and click the button. The 'redirection' field will be cleared.",
          "Enter an IPv4 address and choose one or more checks to perform. Then press the button.",
          "Enter the deletion deadline and click the button.",
        ],
        "function": [
          "Balance recalculation",
          "Reset primary IPv4 redirection",
          "Controls on IP address",
          "Create a subscription",
          "Removal of 'Provisioning VM'",
        ],
        "message": {
          "successful": "Update successful",
          "unsuccessful": "Update failed",
        },
        "api-result": {
          "title": "Results of IP {ip} controls",
          "more": "Show raw result",
          "less": "Hide raw result",
          "header": [
            "PING",
            "NMAP",
            "TRACEROUTE",
            "ABUSE",
          ],
          "show-result": "Click on the control to be performed",
          "result-ping": [
            "Number of packets transmitted: ",
            "Number of packets received: ",
            "Percentage of packets lost: "
          ],
          "result-nmap": [
            "List of open ports: ",
            "No open ports"
          ],
          "result-traceroute": [
            "Hop {hop}: ",
            "No result"
          ],
          "result-abuse": [
            "Part of whitelist",
            "IP address blacklisted",
            "IP address not blacklisted",
            "No result"
          ],
        },
        "api-buttons": {
          "button-ping": "Launch a Ping on this IP address",
          "button-nmap": "Launch an Nmap on this IP address",
          "button-traceroute": "Launch a Traceroute on this IP address",
          "button-abuse": "Launch an Abuse on this IP address",
        },
        "outsourcing": {
          "header": [
            "Company to bill",
            "Server(s)",
            "Item",
            "Frequency",
            "Project Name",
            "Company owning the servers",
            "Subscription Type",
          ],
          "number-of-servers": "No server | 1 server | {nb} servers",
        },
      },
      "ticket-trash": {
        "title": "Trash Tickets",
      },
    },
    "outsourcing": {
      "title": "Outsourcing",
      "button": "New",
      "no_param": "Please create all parameters needed to create service levels.",
      "month": "month",
      "header": [
        "Level",
        "Wording",
        "Description",
        "Criticality",
        "Outsourcing",
        "Priority",
        "Intervention",
        "Ht price",
        "On",
        "Item",
        "Duration",
        "Notice",
      ],
      "operation": {
        "title": [
          "Creation",
          "Update",
          "Editing the Description",
        ],
        "header": [
          "Level of outsourcing",
          "Wording",
          "Description",
          "Criticality",
          "Outsourcing",
          "Priority",
          "Intervention time",
          "Ht price",
          "Date of application",
          "Item",
          "Duration of Commitment",
          "Notice (in months)",
        ],
        "message": [
          "Successful creation",
          "Error while creating",
          "Update successful",
          "Error while updating",
          "Successfully deleted",
          "Error while deleting",
          "Please enter the required fields",
          "Required fields",
        ],
      },
    },
    "param": {
      "outsourcing": {
        "outsourcing": "Service level",
        "standard": "Standard outsourcing",
        "advanced": "Advanced outsourcing",
        "full": "Full outsourcing",
      },
      "outsourcing_criticality": {
        "outsourcing_criticality": "Criticality",
        "low": "Low",
        "medium": "Moderate",
        "high": "High",
      },
      "outsourcing_priority": {
        "outsourcing_priority": "Priority",
        "medium": "Average",
        "high": "High",
        "priority": "Priority",
      },
      "outsourcing_level": {
        "outsourcing_level": "Outsourcing",
        "none": "None",
        "medium": "Moderate",
        "full": "Complete",
      },
      "intervention_type": {
        "intervention_type": "Intervention type",
        "day": "Day(s)",
        "hour": "Hour(s)",
        "minute": "Minutes",
      },
    },
    "ticket-indexer": {
      "title": "Ticket reindexing",
      "header": [
        "Maximum number of emails to process",
        "Oldest date to process",
      ],
      "help": "Leave these values empty to reindex all emails",
      "message": [
        "Task started"
      ],
    },
    "subscription": {
      "type": {
        "0": "Standard",
        "1": "Managed services",
        "2": "Disaster Recovery Plan",
        "3": "IP",
        "4": "Storage",
        "5": "Credit",
        "6": "VSwitch",
        "7": "Port",
        "8": "QoS",
        "100": "Framework Agreement",
      },
      "title": "Subscription Details",
      "return": "Return",
      "return-text": "Return to subscription list",
      "tab": [
        "General",
        "Invoices",
      ],
      "overview": {
        "header": [
          "Project Name",
          "Frequency",
          "Start Date",
          "End Date",
          "Update Date",
          "Status",
          "Payment disabled for invoice renewal",
          "Enter a suffix",
        ],
        "footer": [
          "Total Excl. Tax",
          "VAT",
          "Total Incl. Tax",
          "Discount",
          "Total Net Excl. Tax",
        ],
        "update": {
          "success": "Subscription update successful",
          "nothing": "No information to update",
          "commercial-success": "Commercial update successful",
          "date-warning": "Please enter an end date",
          "partial-billing": "Create Partial Invoice",
        },
        "detail": {
          "title": "Subscription Items",
          "header": [
            "Item",
            "Description",
            "Server",
            "Quantity",
            "Unit Price",
            "Discount",
            "Net Amount",
            "Deployed",
          ],
        },
        "button": {
          "modify": "Modify the subscription header",
          "modify-header": "Modify the subscription header",
          "add-item": "Add Item",
          "validate": "Validate Changes",
          "cancel": "Cancel Changes",
          "create-invoice": "Create Invoice",
          "generate-invoice": "Generate Invoice",
        },
        "messages": [
          "Required fields",
          "Validation of information and creation of the subscription",
        ],
      },
    },
    "demo": {
      "enable": {
        "title": "Enable demo mode",
        "server-name": "Server name",
        "transfer": "Transfer the server to a client?",
        "message-success": "Demo mode successfully activated",
        "message-error": "Error during the activation of the demo mode",
      },
      "disable": {
        "title": "Deactivation of demo mode",
        "server-name": "Server name",
        "return": "Return of the server to the Titan pool",
        "true": "Yes",
        "false": "No",
        "project": "Destination subscription",
        "frequency": "Frequency",
        "billing": "To be billed?",
        "billing-date": "Billing from",
        "message-success": "Demo mode deactivated successfully",
        "message-error": "Error during the deactivation of the demo mode",
      },
    },
    "license": {
      "title": "Licenses",
      "plesk": {
        "title": "Plesk",
        "list-empty": "Empty list",
        "generate": "Generate list",
        "tooltip": [
          "Generate or regenerate the list",
          "Download a CSV",
        ],
        "waiting": "The current processing time may exceed 2 minutes. Please wait.",
        "search": "Search by identifier, item, IP, client, server",
        "goto-server": "View the server",
        "goto-subscription": "View the subscription",
        "goto-invoice": "View the invoice",
        "header": [
          "Identifier",
          "Status",
          "Item",
          "Creation",
          "Binding",
          "Client",
          "Server",
          "Server state",
          "Subscription",
          "Last invoice",
          "Key ID",
          "Expiration",
          "Activation Code",
          "Description",
          "Reported IP(s)",
        ],
        "sort": {
          "status": {
            "all": "All",
            "ACTIVE": "Active",
            "TERMINATED": "Terminated",
          },
        },
        "errors": {
          "title": "Errors",
          "legend": "Legend",
          "error_legend": [
            "Unknown company",
            "Deleted or unknown server",
            "Terminated subscription",
            "Incorrect server assignment",
          ],
          "error_code": {
            "0": "None",
            "1": "Company",
            "2": "Server",
            "3": "Subscription",
            "4": "Wrong",
          },
        },
        "modal": {
          "disable": {
            "title": "Deactivation of the license",
            "message": "By confirming this action, the license will be deactivated.",
            "warning": "You can always reactivate this license",
            "extra": "Are you sure you want to deactivate the license?",
          },
        },
        "message": [
          "The license has been deactivated",
          "Error during deactivation",
        ],
        "info": {
          "title": "License details",
          "button": {
            "modify": "Modify the license",
            "stop-modify": "Stop modifying",
          },
        },
      },
    },
    "quote": {
      "overview": {
        "title": [
          "Quote No.",
          "New Quote",
        ],
        "header": [
          "Company",
          "Sales Representative",
          "Creation Date",
          "Valid Until",
          "Status",
          "Accepted on",
        ],
        "footer": [
          "Total excl. VAT",
          "VAT",
          "Total incl. VAT",
          "Discount",
          "Net Total excl. VAT",
        ],
        "state": {
          "ongoing": "Ongoing",
          "accepted": "Accepted",
          "canceled": "Canceled",
        },
        "messages": {
          "create-success": "Quote creation successful",
          "create-error": "Error creating quote",
          "update-success": "Quote update successful",
          "update-error": "Error updating quote",
          "nothing": "No information to update",
          "already-used": "Name already used",
        },
        "detail": {
          "title": "Quote Detail",
          "header": [
            "Item",
            "Description",
            "Server",
            "Quantity",
            "Unit Price",
            "Discount",
            "Amount excl. VAT",
          ],
        },
        "button": {
          "modify": "Modify the quote",
          "open-menu": "Open the menu",
          "close-menu": "Close the menu",
          "delete-item": "Delete the item",
          "add-item": "Add an item",
          "add-comment": "Add a comment",
          "validate": "Validate changes",
          "cancel": "Exit and cancel changes",
          "create": "Create the quote",
          "show-quote": "Show the quote",
          "show": "Show",
          "exit": "Exit",
          "item-up": "Insert an item above",
          "item-down": "Insert an item below",
          "comment-up": "Insert a comment above",
          "comment-down": "Insert a comment below",
        },
      },
    },
    "dispute": {
      "modal": {
        "document_number": "Dispute No.",
        "created": "Creation",
        "amount": "Payment Amount",
        "fees": "Bank Fees",
        "total_amount": "Total Due",
        "client": "Client",
        "invoices": "Invoices",
        "status": "Status",
        "bank_dispute_id": "Dispute Reference",
        "bank_payment_id": "Payment Reference",
        "reason": "Reason",
        "paid": "Settled",
        "authorize_SEPA": "Authorize the client to pay via SEPA",
        "invoice_unlocked": "Unlock invoices for separate payment",
        "cancel-deactivation": "Cancel scheduled server deactivations",
        "dialog-cancel-deactivation": {
          "title": "Cancel Scheduled Server Deactivations",
          "warning": "This action is sensitive",
          "message": "By confirming this action, the deactivations will be canceled.",
          "extra": "Are you sure you want to cancel the deactivations?",
          "button": "Cancel Deactivations",
        },
      },
    },
  },
  "invoice": {
    "server": "Server",
    "status": "Status",
    "number": "Nº",
    "client_code": "Customer code",
    "client_name": "Customer",
    "balance": "Balance",
    "final_date": "Deadline",
    "product": "Product",
    "created_at": "Creation date",
    "create": "Created",
    "start": "Started",
    "update": "Updated",
    "not_found": "No invoice found.",
    "not_found_search": "No invoice matches your search.",
    "type": "Type",
    "due_date": "End date",
    "amount_ht": "Amount excl.",
    "amount_ttc": "Amount incl.",
    "changing_project_name": {
      "title": "Are you sure you want to change the project name ?",
      "description": "The new project name will be : ",
      "success": "The name has been successfully changed ",
    },
    "frequency": {
      "title": "Frequency",
      "detailed_title": "Billing period",
      "changing_title": "Are you sure you want to change the billing frequency?",
      "success": "The change in billing frequency was successful.",
      "MONTHLY": "Monthly",
      "QUARTERLY": "Quarterly",
      "BIANNUALLY": "Biannually ",
      "ANNUALLY": "Annually",
    },
    "last_invoice": "Last invoice",
    "next_invoice": "Next invoice",
    "see_all_invoice": "See all invoices",
    "company": "Company",
    "price": "Price",
    'project_name': 'Project',
    "add_item": "Add an item",
    "manage_item": "Manage items",
    "generate_invoice": "Generate an invoice",
    "payment_method": "Payment method",
    "details": {
      "status": "Invoice status",
      "billing_address": "Billing Address:",
      "shipping_address": "Shipping address:",
      "same_shipping_billing": "Delivery and Billing to:",
      "description": "Description",
      "initial_amount": "VAT excl. Amount",
      "discount_rate": "Discount rate",
      "discount_amount": "Discount",
      "net_amount": "VAT excl. Total",
      "vat_rate": "VAT Rate",
      "vat_amount": "VAT Amount",
      "total_amount": "VAT incl. Total",
      "remaining_amount": "Due amount",
      "download": "download",
      "legal_notice_text": "Penalty Rate: Pursuant to Law No. 921442 of December 31, 1992, this invoice is payable on the due date indicated above without discount for early payment. Any invoice issued and not paid on its due date shall automatically bear interest at a rate equal to three times the legal rate as defined in Article L.441-6 of the French Commercial Code, up to perfect payment. Lump-sum compensation for collection costs of 40 euros (Decree 2012-1115 of October 2, 2012).\n",
      "tva_notice_in_eu": "Reverse charge mechanism Art. 196 EC Dir. 2006/112",
      "tva_notice_out_eu": "Not applicable VAT Art. 44 EC Dir. 2006/112",
      "facturation_date": "Billing date:",
      "credit_date": "Credit date :",
      "payment": "Payment: Upon reception",
      "due_date": "Due date:",
      "billing_period": "Billing period:",
      "billing_period_sm": "Period",
      "project": "Project:",
      "page": "page",
      "of": "of",
      "subtotal": "Subtotal",
      "subtotalTag": "VAT excl.",
      "cancel": "Cancel",
      "paid_by": "Paid by:",
      "promotion_code": "Promotional code ",
      "item": {
        "name": "Product",
        "quantity": "Q.T",
        "unit_price": "P.U",
        "total_price": "VAT excl."
      },
      "billing_date": "Billing start date",
    },
    "modal": {
      "button": {
        "pay": "Pay",
        "cancel": "Close",
        "return": "Return",
        "next": "Next",
        "generate_credit": "Generate Credit",
        "clear": "Clear",
        "deploy": "Deploy",
      },
      "payment": {
        "invoices_page": "Invoices page",
        "servers_page": "Servers page",
      },
      "clear": {
        "title": "Settle an Invoice or Credit Note",
        "header": [
          "New Amount",
        ],
        "success": "Operation completed successfully"
      },
      "deploy": {
        "ask_deploy": "Are you sure you want to deploy the resources?",
        "success": "Deployment in progress",
        "failed": "Request failed",
      },
    },
    "recurring": {
      "not_found": "No subscription found.",
      "not_found_search": "No subscription matches your search.",
      "payment_method": {
        "text": [
          "Select the payment method to use for the subscription and click 'Apply'.",
          "To use the default payment method, click 'Reset'.",
          "In case of payment failure, the default payment method will be used.",
        ],
      },
    },
    "next_frequency": "Next frequency",
    "account_balance": "Your account balance: {amount}€"
  },

  "credit": {
    "created_at": "Creation date:",
    "start_date": "Start date:",
    "expiration_date": "Expiration date:",
    "credit_date": "Credit's date :",
    "reference_period": "Reference period :",
    "referring_invoice": "Referring invoice:",
    "number": "Credit N° ",
    "remaining_amount": "Remaining amount",
    "modal": {
      "title": "Creating a credit note ({invoice})",
      "period": "Period concerned",
      "header1": "Item",
      "header2": "Quantity",
      "header3": "Amount",
      "header4": "Action",
      "placeholder1": "Price",
      "placeholder2": "Quantity",
      "footer": {
        "amount_allowed": "Amount available excl. VAT:",
        "amount_excluding_vat": "Total excl. VAT:",
        "amount_including_vat": "Total incl. VAT:",
        "amount_vat": "VAT:",
        "amount": "Amount excl. VAT:",
        "keep_old_values": "Global credit note with original amounts",
      },
    },
  },

  "payment_method": {
    "waiting": {
      "message_less_than_hour": "Your payment is pending please wait for",
      "message": "Your payment is still pending, please contact support.",
      "link": "Create a ticket"
    },
    "card": {
      "empty": "No payment methods, add one to continue.",
      "existing": "Existing payment methods ",
      "numbers": "Card numbers",
      "expiration_date": "Expiration date (MM/YYYY)",
      "full_name": "Holder's name",
      "reference": "Reference:",
      "default": "Default",
      "default_ribbon": "Default ",
      "remove": "Remove",
      "expired": "Card expired",
      "iban": "IBAN: ",
      "default_method": 'This is your default payment method, it will be used for your upcoming subscriptions.',
      "creation": {
        "title_sepa": "New IBAN information",
        "add_sepa": "Add a new IBAN",
        "title": "New card information",
        "valid_card": "Add",
        "valid_sepa": "Accept and add",
        "name_placeholder": "Full name",
        "email_placeholder": "Email",
        "add_card": "Add a new card",
        "cancel": "Cancel"
      },
      "invalid": "Invalid",
      "validate": "Validate",
      "expired_message": "This payment method has expired, you may delete it.",
      "invalid_message": "This payment method has not been validated by the bank. You may proceed to validate it or delete it.",
    },
    "sepa_mandate": "By providing your payment information and confirming this payment, you " +
      "authorise (A) Titan Datacenters France and Stripe, our payment service provider, to " +
      "send instructions to your bank to debit your account and (B) your bank to " +
      "debit your account in accordance with those instructions. As part of your " +
      "rights, you are entitled to a refund from your bank under the terms and " +
      "conditions of your agreement with your bank. A refund must be claimed " +
      "within 8 weeks starting from the date on which your account was debited. " +
      "Your rights are explained in a statement that you can obtain from your bank.",
    "details": {
      "payment_period": "For the period of",
      "to": "to",
      "month": "month",
      "monthly": "Month",
      "quarterly": "Quarter",
      "biannually": "Semester",
      "annually": "Year",
      "initial_amount": "VAT excl. Amount:",
      "initial_amount_monthly": "VAT excl. Total/month:",
      "discount": "Discount:",
      "net_amount": "VAT excl. Total:",
      "vat": "VAT:",
      "total_amount": "VAT incl. Total:",
      "total_amount_monthly": "VAT incl. Total/month:",
      "amount_due": "Amount due:",
      "amount_paid": "Amount paid:",
      "remaining_amount": "VAT incl. remaining amount:",
      "amount_too_low": "This amount is too low to be charged immediately, the invoice will be automatically paid along with your subscription renewal.",
      "amount_too_low_accept": "I accept the deferred debit.",
      "non_recurring_amount": "Non-recurring VAT excl. amount:",
      "recurring_amount": "Recurring VAT excl. amount:"
    },
    "credit": {
      "list": "List of credits",
      "id": "Credit ID",
      "credit": "Credit",
      "allocated_amount": "Total (incl. VAT)",
      "available_amount": "Balance",
      "provisional_amount": "Provisional balance *",
      "provision_tips": "Update when selected.",
      "use": "Credit used:",
      "expiration_date": "Expiration",
      "created_at": "Creation date"
    },
    "modal": {
      "select_preferred": {
        "title": "Change the default payment method",
        "warning": "This payment method will be used for all your subscriptions."
      },
      "confirm_payment": {
        "title": "Payment summary: ",
        "amount": "Total order amount including VAT: ",
        "method": "Payment method used: ",
        "cgv_confirm": "By validating my order, I declare that I have read and accepted: the GCS and the privacy policy.",
        "credit": "Credit used:",
        "cgv_see": "See ToS",
        "sepa_info_title": "SEPA information",
        "sepa_info": "The usual delay for a SEPA transaction is between 7 and 14 working days. " +
          "Your service will be delivered only after the complete validation of the transaction. " +
          "If you wish, you can make this payment by card to enjoy immediate service delivery.",
        "offline": "Offline Payment",
      }
    },
    "state": {
      "failed": "Payment failed",
      "action": "Paiement en attente d'action.",
      "action-detail": "Cliquez sur confirmer pour finaliser votre paiement.",
      "pending": "Payment in progress",
      "success": "Payment success, thanks.",
      "processing": "The payment is being processed by the bank.",
      "failed-comment": [
        'Your order has been saved.',
        'You can find it in the "Company" section, "Documents" tab',
      ],
      "validation": {
        "failed": "The validation of your payment method has failed",
        "action": "Action required on your part.",
        "action-detail": "Click confirm to finalize the validation.",
        "pending": "Payment method validation is being processed",
        "success": "Your payment method has been validated",
        "processing": "The validation is being processed by the bank.",
        "failed-comment": [
          "Your payment method is still present.",
          "You can find it under the 'Company' tab 'Payment Methods'"
        ],
      },
    },
    "payment_state": {
      "success": "Success",
      "error": "Error",
      "waiting": "Waiting",
      "canceled": "canceled",
      "processing": "Processing"
    },
    "list": {
      "id": "#",
      "exec_date": "Date",
      "type": "Type",
      "state": "State",
      "amount": "Amount",
      "methodID": "ID",
      "card": "Carte",
      "credit": "Avoir",
      "psepa": "PSEPA",
      "vsepa": "VSEPA",
      "cancel_success": "Your payment was successfully canceled."
    },
    "promotional_code": {
      "invalid": "Invalid promotional code.",
      "valid": "Promotional code applied",
      "valid_cant_apply": "Promotional code is valid but it can't be applied on your order",
      "amount": "Promotional code:",
    }
  },
  "image": {
    "modal": {
      "title": "Modification storage space",
      "description": "Here you can increase or decrease your total storage space. You are currently using:",
      "downgrade": "You are in the process of removing storage, the reduction of your storage space will be realized immediately after validation.",
      "current": "Current storage space",
      "warning": "This action is important",
      "future": "Storage space after modification",
      "update": "Modification",
      "unit-disk": "Gb",
      "price-recuring-final": "* This amount will be added to the periodic billing for your storage space"
    },
    "edit": "Edit quota",
    "quotas": "Use of storage",
    "template-origin": "Original template: ",
    "empty": "Empty images…",
    "diskSize": "Disk size",
    "state": "State",
    "name": "Name",
    "date": "Creation date",
    "more-info": "More information",
    "less-info": "Less information",
    "states": {
      "created": "Created",
      "creating": "Creating",
      "error": "Error",
      "deleted": "Deleted",
      "deleting": "Deleting ..."
    }
  },
  "server": {
    "select": "Select one or more servers",
    "user-name": "User Name",
    "from": "From ",
    "has-notification": "This server requires your attention",
    "server-empty": "No servers…",
    "more-info": "More information",
    "less-info": "Less information",
    "_": "Server",
    "plan": "Plan",
    "name": "Name",
    "state": "State",
    "uuid": "UUID",
    "oid": "OID",
    "ram": "RAM",
    "ip-protocol": "Protocol",
    "ipv4": "IPV4",
    "ipv6": "IPV6",
    "ip": "IP",
    "os": "OS",
    "mac": "MAC",
    "cpu": "CPU",
    "vcpu": "vCPU",
    "disk": "Disk",
    "hypervisor": "Hypervisor",
    "ssh": "SSH",
    "kvmip": "KVM IP",
    "network": "Network",
    "deleted_at": "Deleted at",
    "bandwidth": "Bandwidth",
    "disk-qos": "Disk QoS",
    "license": "License",
    "delete-network": "Delete this Vswitch",
    "reverse": "Reverse",
    "gateway": "Gateway",
    "private-network": "Private network",
    "ssh-keys": "SSH Keys",
    "hostname": "Hostname",
    "company": "Company",
    "termination": "Scheduled termination date",
    "outsourcing_date": "Subscribed on",
    "outsourcing_end": "Renewal",
    "outsourcing_notice": "End of Notice Period",
    "created_at": "Creation",
    "ctrl-alt-del": "Ctrl-Alt-Del",
    "demo": "Demo",
    "disabled": "Server disabled",
    "upgrade": "Upgrade",
    "downgrade": "Downgrade",
    "pay": "Payment",
    "processor": "Processor : ",
    "ram-capacity": "RAM : ",
    "disk-space": "Disk space : ",
    "metric_not_available": "Metrics unavailable.",
    "outsourcing": "Outsourcing",
    "no-outsourcing": "No outsourcing",
    "drp": "DRP",
    "drp-pending": "DRP activation pending",
    "default-reverse": "Default reverse",
    "forced-hypervisor": "Forced Hypervisor",
    "no-drp": "No DRP",
    "drp-activated": "DRP Activated",
    "drp-syncing": "Syncing",
    "drp-fail": "DRP Error",
    "drp-info": {
      "title": "DRP Information",
      "last-sync": "Last Update"
    },
    "actions": {
      "start": "Start",
      "stop": "Stop",
      "reboot": "Reboot",
      "hardstop": "Forced shutdown",
      "resume": "Resume",
      "reset": "Reset",
      "terminate": "Terminate",
      "reset-network": "Reset Network",
      "cancel-terminate": "Cancel Termination",
      "activate-drp": "Activate DRP",
      "deactivate-drp": "Deactivate DRP",
      "schedule-reboot": "Scheduled reboot",
    },
    "events": {
      "snapshot": "Snapshot",
      "network": "Private network",
      "ipkvm": "KVM",
      "changestate": "Server",
      "servercreation": "Creation",
      "serverdeletion": "Deletion",
      "serverupdate": "Updated field",
      "serveraddons": "Addon(s)",
      "migration": "Hypervisor migration",
      "serveroperations": "Server operation",
      "storageupdate": "Update"
    },
    "states": {
      "unknow": "Provisioning",
      "createserverimage": "Created image",
      "starting": "Starting",
      "started": "Started",
      "suspending": "Suspending",
      "suspended": "Suspended",
      "stopping": "Stopping",
      "stopped": "Stopped",
      "reseting": "Reset in progress",
      "resuming": "Resuming",
      "resumed": "Resumed",
      "upgrading": "Updating",
      "disabling": "Disabling",
      "disabled": "Disabled",
      "enabling": "Enabling",
      "enabled": "Enabled",
      "creating": "Creating",
      "created": "Created",
      "deleting": "Deleting",
      "deleted": "Deleted",
      "rebooting": "Rebooting",
      "restoring": "Restoring",
      "restored": "Restored",
      "terminated": "Terminated",
      "diskconfigured": "Disk configured",
      "diskconfiguration": "Disk configuration",
      "diskcreated": "Disk created",
      "diskcreation": "Disk creation",
      "diskdeleted": "Disk deleted",
      "diskdeletion": "Disk deletion",
      "configurationcreation": "Configuration creation",
      "configurationcreated": "Configuration created",
      "configurationdeletion": "Configuration deletion",
      "configurationdeleted": "Configuration deleted",
      "attach-ip": "Attach ip",
      "detach-ip": "Detach ip",
      "attach": "Attach",
      "attached": "Attached",
      "detach": "Detach",
      "detached": "Detached",
      "install": "Install",
      "installed": "Installed",
      "uninstalled": "Uninstalled",
      "unpaid": "Waiting for payment",
      "load-iso": "ISO Loaded",
      "loadiso": "ISO Loaded",
      "unload-iso": "ISO Unloaded",
      "unloadiso": "ISO Unloaded",
      "up": "Up",
    },
    "requests": {
      "title": "Action requested",
      "action": "Action",
      "user": "User",
      "ip": "IP",
      "create": "Create",
      "delete": "Delete",
      "stop": "Shutdown",
      "start": "Start",
      "reboot": "Reboot",
      "restore": "Restore",
      "refresh": "Refresh",
      "hardstop": "Force shutdown",
      "reset": "Reset",
      "terminate": "Resiliate",
      "attach": "Attach",
      "attached": "Attached",
      "detach": "Detach",
      "detached": "Detached",
      "install": "Upgrade",
      "installed": "Upgraded",
      "rename": "Rename",
      "decrease": "Decrease",
      "increase": "Increase",
      "update": "Update",
      "disable-drp": "Deactivating DRP in progress",
    },
    "status": {
      "title": "State:",
      "success": "success",
      "error": "error",
    },
    "entities": {
      "changestate": "Server",
      "serverupdate": "Server",
      "serverdeletion": "Server",
      "servercreation": "Creation",
      "serveraddons": "Addons",
      "serverreset": "Server",
      "ipkvm": "KVM IP",
      "snapshot": "Snapshot",
      "network": "Private network",
      "storageupdate": "Storage pool"
    },
    "pending-actions": {
      "key": "Action(s) requiring a reboot: ",
      "info": "You must restart via the web interface",
      "addons": "addon(s) installation",
      "load_iso": "ISO mount",
      "unload_iso": "ISO unmount",
      "notification": "upgrade",
    },
    "notifications": {
      "addons": "Please restart your server from the interface for the addons to take effect.",
      "mount_iso": "Please restart your server from the interface and launch your remote access.",
      "plesk": "Your Plesk license has been modified. You will find the new activation code in the detailed license information.",
      "user_agreement": "Do not show this message again",
    },
    "server-page": "Server page",
    "no-addons": "No action possible. This server's bill is unpaid.",
    "gauge": {
      "menu": {
        "title": "Models",
        "model": {
          "bar": "Bar",
          "arc": "bow",
          "circle": "Circle",
          "stroke": "Strokes",
        },
        "change-model": "Change model",
      },
      "save": {
        "button": {
          "save-server": "Server Preferences",
          "save-user": "User Preferences",
        },
        "tooltip": {
          "save-server": "Save as preferences for this server",
          "save-user": "Save as default preferences",
        },
        "message": {
          "successful": "Successful backup",
          "unsuccessful": "Failed to save",
        },
      },
    },
    "chart": {
      "activity": "Activity {type} ({unit})",
      "tooltip": [
        "Zoom In",
        "Zoom Out",
        "Refresh",
        "Do Not Fill Chart Area",
        "Fill Chart Area"
      ]
    },
    "tooltips": {
      "delete": "Immediate server deletion",
      "show-company": "View company information",
    },
    "drp-text": [
      "Due to the high popularity of our Disaster Recovery Plan (DRP), we regret to inform you that its activation is temporarily unavailable.",
      "Please try again later or contact our technical support team for immediate assistance.",
      "We apologize for any inconvenience this may cause and thank you for your understanding.",
      "Sincerely,",
      "The Titan SmallCloud Team",
      "+33(0)9 88 99 88 20"
    ],
    "alert-text": [
      "This feature is temporarily out of service.",
      "We are actively working to restore it as soon as possible.",
      "We apologize for any inconvenience this may cause and appreciate your understanding.",
      "Sincerely,",
      "The Titan SmallCloud Team",
      "+33(0)9 88 99 88 20"
    ],
    "uptime": {
      "title": "Uptime",
      "down": "Down",
      "up": "Up",
      "pending": "Pending",
      "checkEverySecond": "Check every {0} seconds",
      "days": "d",
      "hours": "h",
      "latency": "Latency",
    },
    "managed-service": {
      "title": "Levels of Managed Services",
      "add": "Add managed service",
      "update": "Change managed service level",
      "terminate": "Terminate Managed Services",
      "goto-server": "Server anzeigen",
    },
    "drp-modal": {
      "header": [
        "Server(s)",
        "Price",
      ],
      "total": "Total cost of your DRP",
      "prefix": "DRP_",
    },
    "license-info": {
      "button-info": "Detailed Information",
      "button-change": "Change",
      "title-change": "Change License",
      "id": "License ID",
      "code": "Item",
      "key": "Activation Code",
      "in-cart": "License already in the cart",
      "empty": "No license",
      "plesk": {
        "description": "Description",
        "code": "Code",
        "server": "Server"
      },
    },
    "os-modification": {
      "popup": "Modify the OS"
    },
  },
  "event_log": {
    "requests": {
      "title": "Action requested",
      "action": "Action",
      "user": "User",
      "server": "Server ",
      "old_event": "Old event"
    },
    "type": {
      "network": "Network",
      "ip": "IP Address",
      "iso": "ISO",
      "configuration": "Configuration",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disk",
      "vswitch": "Vswitch",
      "addons": "Addons",
      "state": "State",
      "ipkvm": "KVM",
      "changestate": "Server - Changes",
      "servercreation": "Server - Creation",
      "createserver": "Server - Creation",
      "serverdeletion": "Server - Suppression",
      "serverupdate": "Server - Update",
      "serveraddons": "Server - Addon(s)",
      "migration": "Hypervisor migration",
      "serveroperations": "Server - Operations",
      "storageupdate": "Disk - Update",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentication",
      "material": "Material",
      "hypervisor": "Hypervisor",
      "payment_method": "Payment method",
      "company": "Company",
      "user": "User",
      "invitation": "Invitation",
      "role": "Role",
      "cron": "Task",
      "accounting_code": "Account Number",
      "outsourcing": "Managed Services",
      "order": "Order",
      "drp": "D.R.P.",
    },
    "subtype": {
      "network": "Network",
      "ip": "IP Address",
      "iso": "ISO",
      "configuration": "Configuration",
      "snapshot": "Snapshot",
      "note": "Note",
      "image": "Image",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disk",
      "vswitch": "Vswitch",
      "addons": "Addons",
      "state": "State",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Authentication",
      "material": "Material",
      "hypervisor": "Hypervisor",
      "payment_method": "Payment method",
      "company": "Company",
      "user": "User",
      "invitation": "Invitation",
      "role": "Role",
      "cron": "Task",
      "accounting_code": "Account Number",
      "drp": "D.R.P.",
    },
    "action": {
      "load": "Load",
      "unload": "Unload",
      "mount": "Mount",
      "unmount": "Unmount",
      "create": "Creation",
      "creation": "Creation",
      "created": "Created",
      "attach": "Attachment",
      "attached": "Attached",
      "detach": "Detachment",
      "detached": "Detached",
      "start": "Start",
      "started": "Started",
      "stop": "Stop",
      "stopping": "Stopping",
      "stopped": "Stopped",
      "resume": "Resume",
      "resumed": "Resumed",
      "reset": "Reset",
      "increase": "Increase",
      "decrease": "Decrease",
      "update": "Update",
      "updated": "Update",
      "cancel": "Cancel",
      "delete": "Delete",
      "deletion": "Deletion",
      "deleted": "Deleted",
      "upgrade": "Upgrade",
      "upgraded": "Upgraded",
      "downgrade": "Downgrade",
      "downgraded": "Downgraded",
      "install": "Installation",
      "installed": "Installed",
      "uninstall": "Uninstallation",
      "uninstalled": "Uninstalled",
      "restore": "Restore",
      "restored": "Restored",
      "rename": "Rename",
      "renamed": "Renamed",
      "configuration": "Configuration",
      "configured": "Configured",
      "disable": "Disable",
      "disabled": "Disabled",
      "enable": "Enable",
      "enabled": "Enabled",
      "hardstop": "HardStop",
      "reboot": "Reboot",
      "suspend": "Suspend",
      "revoked": "Revoked",
      "activate": "Activate",
      "activated": "Activated",
      "deactivate": "Deactivate",
      "deactivated": "Deactivated",
      "termination": "Termination",
    },
    "status": {
      "title": "State :",
      "success": "success",
      "error": "error",
    },
    "messages": {
      "material_hypervisor_enable": "Hypervisor enabled",
      "material_hypervisor_disable": "Hypervisor disabled",
      "authentication_two_fa_enabled": "2FA authentication enabled",
      "authentication_two_fa_disabled": "2FA authentication disabled",
      "authentication_ssh_create": "SSH authentication created",
      "authentication_ssh_created": "SSH authentication created",
      "authentication_ssh_delete": "SSH authentication deleted",
      "authentication_ssh_deleted": "SSH authentication deleted",
      "authentication_ssh_update": "SSH authentication updated",
      "authentication_ssh_updated": "SSH authentication updated",
      "ip_attach": "IP Attachment Request",
      "ip_detach": "IP Detachment Request",
      "ip_attached": "IP Address Attached",
      "ip_detached": "IP Address Detached",
      "ip_updated": "IP address updated",
      "ip_termination": "IP termination request",
      "ip_cancel_termination": "IP termination request canceled",
      "server_iso_mount": "Image mount",
      "server_iso_unmount": "Unmount image",
      "server_iso_mounted": "Server ISO image mounted",
      "server_iso_unmounted": "Server ISO image unmounted",
      "server_iso_create": "Server ISO image created",
      "server_iso_deletion": "Server ISO image deleted",
      "server_addons_upgrade": "Server upgrade",
      "server_addons_downgrade": "Server downgrade",
      "server_network_enabled": "Server network enabled",
      "server_network_disabled": "Server network eisabled",
      "server_configuration_creation": "Server configuration creation",
      "server_configuration_created": "Server configuration created",
      "server_configuration_deletion": "Server configuration deletion",
      "server_configuration_deleted": "Server configuration deleted",
      "server_storage_creation": "Disk space creation",
      "server_storage_created": "Disk space created",
      "server_storage_configured": "Disk space configured",
      "server_storage_deletion": "Disk space deletion",
      "server_storage_deleted": "Disk space deleted",
      "server_termination": "Termination request",
      "server_cancel_termination": "Termination request cancelled",
      "server_state_started": "Server started",
      "server_state_stopped": "Server stopped",
      "server_state_resumed": "Server restarted",
      "server_state_stopping": "Server stopping",
      "server_start": "Server starting",
      "server_stop": "Server stop",
      "server_update": "Server updated",
      "server_updated": "Server updated",
      "server_renamed": "Server renamed",
      "server_create": "Server created",
      "server_started": "Server started",
      "server_stopped": "Server stopped",
      "server_resumed": "Server resumed",
      "server_stopping": "Server stopping",
      "server_hardstop": "Server hardstop",
      "server_reboot": "Server restarting",
      "storage_snapshot_create": "Snapshot creation",
      "storage_snapshot_restore": "Snapshot restore",
      "storage_snapshot_deletion": "Snapshot deletion",
      "storage_snapshot_created": "Snapshot created",
      "storage_snapshot_restored": "Snapshot restored",
      "storage_snapshot_deleted": "Snapshot deleted",
      "storage_snapshot_updated": "Snapshot updated",
      "storage_image_create": "Creating an image",
      "storage_image_delete": "Deleting an image",
      "storage_image_created": "Image created",
      "storage_image_deleted": "Image deleted",
      "storage_image_updated": "Image updated",
      "storage_decreased": "Disk space decreased",
      "storage_increased": "Disk space increased",
      "note_updated": "Note updated",
      "console_kvmip_start": "IpKvm console starting",
      "console_kvmip_stop": "IpKvm console stop",
      "console_kvmip_update": "IpKvm console updated",
      "console_kvmip_started": "IpKvm console started",
      "console_kvmip_stopped": "IpKvm console stopped",
      "console_kvmip_stopping": "IpKvm console stopping",
      "network_vswitch_attach": "Attach Vswitch to Network",
      "network_vswitch_detach": "Network Vswitch Detachment",
      "network_vswitch_attached": "Vswitch attached to network",
      "network_vswitch_detached": "Vswitch detached from network",
      "network_vswitch_create": "Creating Vswitch",
      "network_vswitch_created": "Vswitch created",
      "network_vswitch_delete": "Deleting Vswitch",
      "network_vswitch_deleted": "Vswitch deleted",
      "network_vswitch_update": "Vswitch Update",
      "network_vswitch_updated": "Vswitch updated",
      "payment_method_created": "Payment method Added",
      "createserver": "Server creation",
      "metric_cpu_alert": "CPU Activity",
      "metric_ram_alert": "RAM Activity",
      "metric_disk_alert": "Disk Activity",
      "metric_net_alert": "Network Activity",
      "company_created": "Company created",
      "company_update": "Updating company information",
      "company_updated": "Company information updated",
      "company_invitation_create": "Creating an invitation",
      "company_invitation_created": "Sending an invitation",
      "company_invitation_delete": "Deleting an invitation",
      "company_invitation_deleted": "Invitation deleted",
      "company_user_revoke": "Revoking a user",
      "company_user_revoked": "User revoked",
      "company_user_delete": "Deleting a user",
      "company_user_deleted": "User deleted",
      "company_user_join": "Attempting to join the company",
      "company_user_joined": "The user has joined the company",
      "company_role_create": "Creating a role",
      "company_role_created": "Role created",
      "company_role_delete": "Deleting a role",
      "company_role_deleted": "Role deleted",
      "company_role_update": "Updating a role",
      "company_role_updated": "Role updated",
      "cron_reboot_create": "Scheduling a reboot task",
      "cron_reboot_created": "Reboot task scheduled",
      "cron_reboot_delete": "Deleting a reboot task",
      "cron_reboot_deleted": "Reboot task deleted",
      "cron_reboot_update": "Updating a reboot task",
      "cron_reboot_updated": "Reboot task updated",
      "cron_reboot_activate": "Activating a reboot task",
      "cron_reboot_activated": "Reboot task activated",
      "cron_reboot_deactivate": "Deactivating a reboot task",
      "cron_reboot_deactivated": "Reboot task deactivated",
      "cron_snapshot_create": "Scheduling a snapshot task",
      "cron_snapshot_created": "Snapshot task scheduled",
      "cron_snapshot_delete": "Deleting a snapshot task",
      "cron_snapshot_deleted": "Snapshot task deleted",
      "cron_snapshot_update": "Updating a snapshot task",
      "cron_snapshot_updated": "Snapshot task updated",
      "cron_snapshot_activate": "Activating a snapshot task",
      "cron_snapshot_activated": "Snapshot task activated",
      "cron_snapshot_deactivate": "Deactivating a snapshot task",
      "cron_snapshot_deactivated": "Snapshot task deactivated",
      "accounting_code_create": "Creating account number",
      "accounting_code_created": "Account number created",
      "accounting_code_delete": "Deleting account number",
      "accounting_code_deleted": "Account number deleted",
      "api_token_create": "Creating an API key",
      "api_token_created": "API key created",
      "api_token_delete": "Deleting an API key",
      "api_token_deleted": "API key deleted",
      "api_token_update": "Updating an API key",
      "api_token_updated": "API key updated",
      "outsourcing_termination": "Request for termination of managed services",
      "outsourcing_cancel_termination": "Request for termination of managed services deleted",
      "outsourcing_deleted_termination": "Managed services terminated",
      "vswitch_ports_increase": "Ports Added to VSwitch",
      "order_deletion": "Order Deletion",
      "server_drp_activated": "DRP Activated",
      "server_drp_deactivated": "DRP Deactivated",
      "server_drp_activate": "DRP activation request",
      "server_drp_deactivate": "DRP deactivation request",
      "server_maintenance_level_update": "Managed Service Update",
      "server_service_managed_update": "Managed Service Update",
      "server_maintenance_level_updated": "Managed Service Updated",
      "server_service_managed_updated": "Managed Service Updated",
      "server_plesk_update": "Plesk License Update",
      "server_plesk_updated": "Plesk License Updated",
    },
  },
  "intervention": {
    "title": "Services"
  },
  "pages": {
    "devis": {
      "title": "Quote",
      "number": "Number",
      "commercial": "Commercial ",
      "created_at": "Created on",
      "expire_at": "Expires on",
      "amount": "Amount",
      "tooltip-download": "Download pdf",
      "tooltip-cart": "Add to cart",
      "empty": "No estimate at this time.",
      "tooltip-accept": "Accept the quote",
      "validate_at": "Accepted the ",
      "accept": "Accepted",
      "invoice_number": "Document ",
      "details": "Invoice # ",
      "quote_date": "Quote date ",
      "quote_validity": "Valid until ",
      "create_quote": "Create a quote",
      "company": "Company",
      "tooltip-delete": "Delete quote",
    },
    "lang": "en",
    "ip-histo": {
      "empty": "The history is empty.",
      "company_uuid": "Company",
      "server_uuid": "Server",
      "start_date": "Start date",
      "end_date": "End date"
    },
    "login": {
      "title": "Login",
      "email-label": "Email",
      "password-label": "Password",
      "login-action": "Login",
      "email-not-validated": "Please validate your email before logging in",
      "email-confirmation-sent": "A confirmation email has been sent to you (please also check your spams).",
      "promotional_code_saved": "Promotion code has been saved."
    },
    "login2fa": {
      "title": "Two-factor authentication",
      "tfa-label": "Security code"
    },
    "register": {
      "title": "To deploy your instances and provide you with the best service, we need information. This information will remain confidential. You can contact us at any time to request its deletion.",
      "company_invite_title": "Welcome on Titan SmallCloud! To approve  your account, please fill out this form. This information will remain confidential. You can contact us at any time to request its deletion.",
      "action": "Register",
      "billing-address": "Billing address",
      "modal": {
        "title": "You have modified the information previously filled in.",
        "danger": "",
        "message": "",
        "button": "Confirm",
        "extra": "Can you confirm these changes?",
      },
    },
    "dashboard": {
      "status-map": "Status map TITAN",
      "status-page": "Status",
      "favorite": "Your favorites",
      "notifications": "Notifications",
      "overview-server": "Overview server",
      "overview-resource": "My Resources",
      "status": {
        "bandwidth": "Bandwidth",
        "v4-peers-visibility": "V4 peers visibility",
        "v6-peers-visibility": "V6 peers visibility",
        "ping-latency": "Latency"
      },
      "weather-cpu-ram": "CPU/RAM",
      "weather-public-network": "Public network (WAN)",
      "weather-private-network": "Private network (LAN)",
      "weather-storage": "Storage",
      "pending_servers": "Servers waiting for setting",
      "last_op": "Last operations",
      "title-tickets": "Your last tickets",
      "title-history": "Your last actions",
      "weather-ras": "OK",
      "weather-degraded": "Degraded",
      "weather-failure": "Critical",
      "started-server": "Started servers : ",
      "stopped-server": "Stopped servers : ",
      "other-server": "Waiting servers : ",
      "ipv4-active": "Active IPv4: ",
      "ipv4-free": "Available IPv4: ",
      "ipv6-active": "Active IPv6: ",
      "ipv6-free": "Available IPv6: ",
      "disk-space": "Available storage: ",
      "disk-used": "Storage used: ",
      "modal": {
        "you_will_be_redirected_to": "You will be redirected to",
        "status_link": "https://titandc.statuspage.io/",
      },
      "popup": [
        "Titan Datacacenter Status"
      ],
    },
    "servers": {
      "mac": "MAC Address",
      "plans": {
        "SC1": "SC1",
        "SC2": "SC2",
        "SC3": "SC3"
      }
    },
    "server-new": {
      "devis-mode": {
        "false": "Activate the quote generation mode",
        "true": "Deactivate the generation mode of an estimate",
      },
      "title": "Set up your server",
      "choose-managed": "Choose your network management mode",
      "choose-plan": "Choose your ressources",
      "choose-os": "Choose your operating system",
      "setup-ssh": "Set up your connection",
      "licenses": "Licenses",
      "bandwidth": "Bandwidth",
      "managed-true": "Managed network",
      "managed-false": "Dedicated network",
      "managed-true-text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.",
      "managed-false-text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "iops": "Disk QoS : ",
      "ssh-password": "Connect with password",
      "ssh-password-input": "Enter a password",
      "ssh-key": "Connect with SSH Key",
      "ssh-key-input": "Enter your key to connect",
      "ssh-key-hint": "Enter your public SSH key",
      "ssh-key-public-msg": "You had to be logged in to use SSH keys.",
      "ssh-key-placeholder": "Public SSH key",
      "confirm": "Confirmation",
      "finalize": "Complete the order",
      "order-plan": "Server command",
      "order-with": "with the operating system",
      "order-preinstalled": "preinstalled",
      "order-button": "command for",
      "month": "month VAT excl.",
      "duty-free": "VAT excl.*",
      "recurring_description": "* This amount will be added to your server's recurring invoice",
      "tooltip_windows_to_windows": "Windows can only migrate to Window.",
      "tooltip_password": "Enter a password",
      "tooltip_license": "You cannot change the license type.",
      "tooltip": "Enter a password or SSH key",
      "tooltip_windows": "Windows is only available for SC2 and above.",
      "tooltip_add": "Add to cart",
      "tooltip_packetfence": "PacketFence is only available on a SC3.",
      "order-count": "You haven't any order… | You have one order in the cart… | You have {nb} orders in the cart…",
      "order-added": "Order added…",
      "order-cart": "Go to cart",
      "order-reset": "Discard the cart",
      "version-placeholder": "Version",
      "plesk-title": "Choice of plesk licenses",
      "plesk-selection": "Select your license:",
      "unit-price": "Unit price :",
      "os": "Operating System",
      "qty": "Qty.:",
      "pwd_hint": "Choose a password and / or a SSH key",
      "pwd_and_ssh": "Choose a SSH key and / or a password",
      "cpu-desc": " ",
      "ram-desc": " ",
      "disk-desc": " ",
      "next-step-btn": "Next step",
      "promo_code": "Promotion code",
      "unit": {
        "from_to_gb": "{ from } GB to { to } GB",
        "from_to_cpu": "{ from } vCPU to { to } vCPU",
      },
      "update-os": "New OS",
    },
    "server-detail": {
      "ips": "IP addresses",
      "note": "Note",
      "note-edit": "Edit this note",
      "note-save": "Save this note",
      "note-help": "Styling with Markdown is supported (click here for help)",
      "note-default": `This server has been intalled with **{templates}** on {creation_date}`,
      "note-empty": "No notes",
      "activity": "Activity",
      "activity-empty": "No activity yet",
      "graph-reset": "Reset graph",
      "network-activity": "Network activity",
      "ram-activity": "RAM activity",
      "cpu-activity": "CPU activity",
      "disk-activity": "Disk space",
      "Vswitch-activity": "Vswitch activity",
      "history": "History",
      "last-activity": "Last activity",
      "server-history": "Server history",
      "no_ip": "No IP address",
      "snapshots": "Snapshots",
      "snapshot-create": "Create a snapshot",
      "snapshot-restore": "Restore",
      "snapshot-delete": "Delete",
      "snapshot-to-template": "Image",
      "snapshot-to-template-modal": {
        "title": "Generating an image from this snapshot",
        "name": "Name of the image"
      },
      "snapshot-empty": "No snapshot.",
      "snapshot-error": "Snapshot has failed",
      "snapshot-schedule": "Schedule a Snapshot",
      "terminate-success": "Terminating server",
      "terminate-canceled": "Server termination canceled.",
      "modal-server-downgrade-nothing": "No addon to downgrade.",
      "modal-need-stop-title": "Server reset",
      "modal-need-stop-text": "Required server stop.",
      "modal-need-stop-description": "You need to stop your server to continue.",
      "modal-need-stopped-description": "Please wait for your server to stop to continue",
      "modal-server-upgrade-description": "This upgrade will be complete only after a server reboot triggered from the dashboard.",
      "modal-mount-cdrom-title": "Mount CD-ROM",
      "modal-mount-cdrom-text": "A CD-ROM can be loaded onto the server from a remote ISO file. The server will then boot on the CD-ROM in priority until the latter is unloaded (reboot required). Configuring your ISO is done from the KVM.",
      "modal-mount-cdrom-label": "URL of a bootable ISO to pulling (https only):",
      "modal-mount-cdrom-list-label": "Predefined ISO list",
      "modal-mount-cdrom-list-placeholder": "— Select an ISO —",
      "modal-unmount-cdrom-title": "Unmount CD-ROM",
      "modal-unmount-cdrom-text": "This action will unmount the loaded CD-ROM. If the server is currently booted from the CD-ROM, you will need to trigger a hard stop in order to enact the unmouting and reboot on your OS.",
      "modal-server-downgrade-description": "Addons removal will be carried out immediately after validation.",
      "see-all-server": "View servers",
      "terminate-descriptions-title": "What is the reason for this termination?",
      "terminate-descriptions-placeholder": "— Reasons —",
      "terminate-descriptions": {
        "over": "The project associated with this product is over.",
        "unsuitable": "The product is unsuitable for my needs.",
        "quality": "The quality of service (breakdowns, slowdowns, etc.) does not meet my expectations.",
        "assistance": "Online assistance (customer service, FAQ, support, etc.) does not allow me to properly manage my services.",
        "expensive": "Products are too expensive.",
        "loyalty": "Lack of recognition (loyalty program, decreasing prices, etc.).",
        "mistake": "I made a mistake when ordering (wrong resources, double order, etc.).",
      },
      "goto_images": "My images",
      "modal-addon-exists": "An upgrade is already added to cart",
      "modal-actual-addon": "(Actual { quantity } { type })",
      "modal-none": "None",
      "addon-added": "Addons added to cart",
      "modal-no-ip": [
        "Server Termination Not Possible",
        "Termination is only possible if the server has at least one attached IPv4 address.",
        "This IPv4 must have a corresponding IPv6 that is not attached to another server and is part of your address pool.",
        "Please go to your network interface and attach an IPv4 address to this server.",
        "Thank you.",
      ],
    },
    "faq": {
      "title": "FAQ",
      "updated": "Updated"
    },
    "payment-list": {
      "create-payment": "Create a payment",
      "date": "Date",
      "number": "Payment number",
      "ref": "Reference",
      "facture": "Related invoices",
      "mode": "Regulated by",
      "total-amount": "Amount",
      "charged-amount": "Amount charged",
      "unused-amount": "Unused amount",
      "client_code": "Client code",
      "client": "Client",
      "no_payment": "No payment found",
      "regularized_by": "Regularized by payment(s) {document_number}",
      "modes": {
        'VSEPA': "Transfer",
        'PSEPA': "Debit",
        'CB': "CB",
        'CREDIT': "Credit",
        'UNPAID': "Unpaid",
      },
      "states": {
        'title': "Status",
        'waiting': "Pending",
        'processing': "Processing",
        'succeeded': "Success",
        'success': "Success",
        'error': "Error",
        'canceled': "Canceled",
        'requires_action': "Action",
        "regularized": "Regularized",
      },
      "date-range": {
        'month': 'Month',
        'all': 'All',
        'last-month': 'Last Month',
        'last-quarter': 'Last Quarter',
        'format': 'yyyy/MM/dd',
        'apply': 'Apply',
        'cancel': 'Cancel',
        'weekLabel': 'W',
        'customRange': 'Custom Range',
        'daysOfWeek': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'monthNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        'monthNamesFull': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      }
    },
    "account": {
      "vat": "VAT Number",
      "title": "Account settings",
      "personal_infos": "Personal information",
      "civility": "Civility",
      "civility-mr": "Mr",
      "civility-mme": "Mrs",
      "civility-mx": "Mx",
      "first-name": "First name",
      "name": "Name",
      "last-name": "Last name",
      "email": "Email",
      "phone": "Phone",
      "phone-country": "Country code",
      "phone-country-error": "Choose a country",
      "phone-ex": "Example:",
      "mobile": "Mobile",
      "add-phone": "Add phone number",
      "save": "Save",
      "languages": "Language",
      "interface-mode": "Interface color",
      "current-password": "Current password",
      "new-password": "New password",
      "confirm-new-password": "Confirm new password",
      "password": "Password",
      "confirm-password": "Confirm password",
      "settings": "Settings",
      "company_infos": "Company information",
      "company-name": "Company name",
      "company-position": "Position",
      "company-id": "D-U-N-S",
      "tva-number": "VAT Number",
      "address": "Address",
      "address-name": "Name",
      "address-street": "Street",
      "address-postal-code": "Zip code",
      "address-city": "City",
      "address-country": "Country",
      "add-address": "Add an address",
      "password-mismatch": "The passwords do not match",
      "recaptcha-error": "Please validate the recaptcha",
      "password-hint": "The password must contain at least 8 characters (uppercase, lowercase, number)",
      "legal-info": "Once your account has been created, you will be able to enjoy Titan Datacenter services.\nThe information you provided via this form, is collected with your consent and intended for Titan Datacenters as data controller.\n\nYour personal data and your orders information will be mainly used to create and manage your account, and to process and track your orders. In addition, it will allow us to give you access to fraud prevention, statistical analysis, and various marketing and advertising tools.\n\nThese legal bases are different and specific to the processing concerned.\n\nYou may always withdraw your consent at any time.",
      "legal-notice": "Legal notice",
      "privacy-policy": "Privacy policy",
      "discount": "Subscription type",
      "auto_debit": "Withdrawal at the end of the month:",
      "disabled": "Company disabled:",
      "renew_with_credits": "Use credits when renewing subscriptions:",
      "reset": "Reset",
      "affiliate_infos": "Affiliation program (optional)",
      "affiliate-code": "Affiliate code (optional)",
      "code": "Code",
      "status": "Status",
      "balance": "Balance",
      "vatConfirmed": "VAT number confirmed",
      "vatNotConfirmed": "Invalid VAT not confirmed yet",
      "modal": {
        "title": "Contact",
        "message_invalid": "Invalid data",
        "message_error": "Error saving",
        "message_success": "Contact updated successfully",
      },
      "send_invoice": "Automatic sending of invoices by email (Super Admin + Accountants)",
      "creation_date": "Created on",
      "update_date": "Updated on",
      "oid": "OID",
      "affiliation_code": "Affiliation code",
      "server-detail-closed": "Server Details Closed",
      "server-detail-help": "In the server profile, keep the panel of detailed information open or closed",
      "create-new-company": "Create a new company",
      "invoice_payment_grouped": "Single payment for multiple invoices",
      "sms_alert": "SMS sending authorization",
      "single_invoice": "Consolidate subscriptions into a single invoice",
    },
    "account-ssh": {
      "title": "SSH keys",
      "management": "Manage your SSH keys",
      "ssh-key-name": "Name",
      "ssh-key-usage": "Number of uses",
      "ssh-key-value": "SSH Key",
      "ssh-key-add": "Add an SSH Key"
    },
    "account-api": {
      "title": "API keys",
      "api-keys": "API keys",
      "api-key-name": "Name",
      "api-key-token": "API Key",
      "api-key-expire": "Expiration date",
      "api-key-add": "Generate an API Key"
    },
    "account-2fa": {
      "title": "Two-factor authentication (2FA)",
      "actions": {
        "enable": "Enable 2FA",
        "disable": "Disable 2FA",
      },
      "enable": {
        "step1": {
          "title": "Write down the secret recovery code",
          "text": "Please carefully write down the recovery code below. It will be mandatory to recover your account if you lose your phone for example."
        },
        "step2": {
          "title": "Scan this QR Code with a TOTP application",
          "text": "Simply scan the QR code below using your favorite TOTP app (Authy, Google Authenticator, etc.)"
        },
        "step3": {
          "title": "Enter the six-digit authorization code",
          "text": "Please enter below the 6 digit code that appears on your TOTP application (hurry up, codes have a limited lifespan)."
        },
        "step4": {
          "title": "Scannez le QR code à l'aide d'une application de TOTP",
          "text": "Il vous suffit de scanner le QR code ci-dessous à l'aide de votre application de TOTP favorite (Authy, Google Authenticator, etc.)"
        }
      },
      "disable": {
        "title": "Disable two-factor authentication",
        "text": "The two-factor authentication is currently enabled on your account. To disable it, please enter your password along with the current six-digit authorization code from your TOTP app.",
        "warning": "Your current secret recovery code will become obsolete. If you wish to re-activate 2FA later, a new recovery code will be communicated to you."
      },
      "disable-success": "Deactivation successful",
      "disable-error": "Error during deactivation",
    },
    "account-preferences": {
      "title": "Account preferences",
      "menu-1": "Saved preferences",
      "cartModal": "Order summary:",
      "show": "Show",
      "hide": "Hide",
      "sorting-col": "Sort column :",
      "sorting-dir": "Sort by :",
      "invoices": "Display of invoices:",
      "recurrings": "Display of subscriptions:",
      "servers": "Display of servers:",
      "tooltips-hide": "To see again the order summary, please go to the \"Account preferences \" section.",
      "sms-alert": "Receive notifications by SMS",
      "sms-help": "To receive SMS, sending authorization must be enabled in the 'Notifications' tab of the company page. Ensure you have entered a valid phone number.",
    },
    "network": {
      "title": "Networks",
      "title-switch": "Vswitch",
      "title-ips": "IP Pool",
      "no_ip": "No IP address",
      "no_vswitch": "No Vswitch",
      "help": "-",
      "name": "Name",
      "ports": "Ports",
      "speed": "Speed",
      "speed-with-unit": "Speed in {unit}",
      "mtu-max": "MTU max",
      "hostname": "Hostname",
      "comment": "Comment",
      "plan": "Plan",
      "mac": "MAC address",
      "cidr": "CIDR",
      "gateway": "Gateway",
      "connected-servers": "Connected you server",
      "connect-button": "Connect your server",
      "add-network": "Add a Vswitch",
      "add-vm": "Connect a server",
      "add-success": "Vswitch created successfully",
      "delete-network": "Delete this Vswitch",
      "cant-delete-network": "Impossible to delete this vswitch. Servers are attached to it",
      "delete-vm": "Detach this server",
      "delete-success": "Vswitch successfully deleted",
      "title-add-server": "Connect a server",
      "server-attach-success": "Server successfully attached",
      "server-detach-success": "Server successfully deleted",
      "order-count": "You have no orders… | You have an IP in the cart… | You have {nb} IPs in the cart…",
      "order-added": "Order added…",
      "order-cart": "See cart",
      "order-reset": "Discard the cart",
      "modal-network-delete-title": "Deleted this Vswitch",
      "modal-network-delete-description": "By confirming this action, this Vswitch will be deleted",
      "modal-network-delete-confirm": "Are you sure you want to delete this Vswitch?",
      "modal-server-detach-title": "Detach this server",
      "modal-server-detach-description": "By confirming this action, this server will be detached from the Vswitch",
      "modal-server-detach-confirm": "Are you sure you want to detach this server?",
      "modal-server-detach-ip-title": "Detach IP {ip}",
      "modal-server-detach-ip-description": "By confirming this action, this IP will be detached from the server.",
      "modal-server-detach-ip-confirm": "Are you sure you want to detach this IP?",
      "modal-server-detach-ip-warning": "You will be able to attach this IP to another server.",
      "modal-server-delete-ip-title": "Terminate IP {ip}",
      "modal-server-delete-ip-description": "By confirming this action, this IP will be terminated.",
      "modal-server-delete-ip-confirm": "Are you sure you want to terminate this IP?",
      "modal-server-delete-ip-warning": "This action is reversible.",
      "modal-network-managed-title": "Managed network",
      "modal-network-managed-label-name": "Name",
      "modal-network-managed-label-gateway": "Gateway",
      "modal-network-managed-label-cidr": "CIDR",
      "reset-network": "Reset the servers network interface",
      "update": "Update Vswitch",
      "reset": "Reset",
      "add-ip": "Add an IP",
      "goto-server": "Go to server",
      "detach-ip": "Detach this IP",
      "attach-ip": "Attach this IP",
      "server-ip-attach-success": "IP successfully attached",
      "server-ip-detach-success": "IP successfully detached",
      "server-search": "Search for a server. Double-click to show the list.",
      "update-reverse": "Update reverse",
      "update-ip": "Update IP",
      "open-port-list": "Show port list",
      "close-port-list": "Close port list",
      "ip-delete-success": "IP termination successful",
      "ip-delete-failed": "IP termination not successful",
      "ip-cancel-delete-success": "Cancellation of IP termination successful",
      "ip-added-to-cart": "IP added to cart",
      "ip-already-in-cart": "IP already in cart",
      "ip-check": "Run checks on this address",
      "ip-summary": {
        "title": "Summary",
        "header": [
          "Attached IPs",
          "IPv4",
          "IPv6",
          "Available IPs",
        ],
      },
      "title-choose-subscription": "Choose Subscription",
      "subtitle-choose-subscription": "Select the subscription for which the IP will be terminated",
      "subscription-search": "Search for a subscription. Double-click to show the list.",
      "drp": "DRP",
      "show-chart": "Show Activity",
      "hide-chart": "Hide Activity",
      "add-ports": "Add Ports",
      "number-of-ports": "Number of ports to add",
      "qos-value": "New QoS in Gbps",
      "company": "Company",
      "vswitch-search": "Search by name or company",
      "ip-search": "Search by address, company, reverse, server, MAC",
      "new-vswitch": {
        "title": "Add a VSwitch",
        "number-of-ports": "Desired number of ports",
        "qos-value": "Desired QoS in Gbps",
      },
    },
    "company": {
      "tabs": {
        "info": "Information",
        "users": "Users",
        "payments": "Payment methods",
        "reglements": "Payments",
        "historic": "Historic",
        "notifications": "Notifications",
        "piggy": "Credits",
        "tag": "Tags",
        "billing": "Billing",
      },
      "title": "Company",
      "title-info": "Company information",
      "title-users": "Users and positions",
      "title-user-invite": "Invite a user",
      "title-role-create": "Create a role",
      "title-role-update": "Update a Role",
      "title-authorization-update": "Update Authorizations",
      "title-notifications": "Notification management",
      "title-piggy": "Use of credits",
      "title-tag": "List of Tags",
      "title-managed-service": "Servers under Managed Services",
      "address-billing": "Billing address",
      "address-shipping": "Shipping address",
      "address-copy": "Use the same for shipping address",
      "company": "Company",
      "save-success": "Information successfully saved",
      "user-add": "Invite a user",
      "user-add-success": "Invitation successfully sent",
      "user-revoke-success": "User revoked with success",
      "invitation-delete-success": "Invitation revoked with success",
      "user-empty": "Please invite a user",
      "user-role-change-success": "User role successfully changed",
      "user-filter-place-holder": "Find a user...",
      "complete-company-error": "Please complete your company information first (name, address, and VAT number).",
      "same_address": "Billing and shipping addresses are identical",
      "item_added": "Item(s) added to cart",
      "piggy": {
        "amount": "Number of remaining credits",
        "provisioning": "Automatic provisioning",
        "add-pack": "Add a pack",
        "order": "Order",
        "header": [
          "Creation",
          "Usage",
          "Company",
          "User",
          "Number of credits",
          "Sent to",
        ],
      },
      "tag": {
        "filter-place-holder": "Search for a tag",
        "add-tag": "Add a tag",
        "list-empty": "Please enter a tag",
        "create": "Creating a tag",
        "update": "Updating the tag",
        "create-success": "Creation successful",
        "create-error": "Error during creation",
        "update-success": "Update successful",
        "update-error": "Error during update",
        "delete-success": "Deletion successful",
        "delete-error": "Error during deletion",
        "select-all": "All",
        "server-list": "Server List",
        "help": {
          "del-tag": "Delete the tag",
          "edit-tag": "Edit the tag",
          "edit-color-off": "Click to change the color",
          "edit-color-on": "Click to keep this color",
          "edit-servers": "Click to edit the server list",
        },
        "header": [
          "Name",
          "Color",
          "Servers",
        ],
        "dialog-delete-tag": {
          "title": "Delete this tag",
          "warning": "This action is irreversible",
          "message": "By confirming this action, this tag will be deleted.",
          "extra": "Are you sure you want to delete this tag?",
        },
      },
    },
    "tickets": {
      "title": "Tickets",
      "title-new": "New ticket",
      "service": "Service concerned",
      "server": "Server concerned",
      "object": "Ticket subject",
      "object-placeholder": "Enter your subject here",
      "message": "Your message",
      "message-placeholder": "Enter your demand here",
      "add-ticket": "New ticket",
      "send-ticket": "Send",
      "sending-ticket": "Currently sending",
      "close-ticket": "Close the ticket",
      "close-ticket-force": "Close Without Response",
      "cancel": "Back to tickets list",
      "close-text": "Have we answered all your questions?",
      "ticket-added": "Ticket added…",
      "ticket-added-text": "We received your email request.",
      "chat": "Chat",
      "level": "Priority level",
      "levels": {
        "low": "Low",
        "medium": "Medium",
        "high": "High"
      },
      "categories": {
        "billing": "Billing",
        "technical": "Technical",
        "sales": "Sales",
        "other": "Other"
      },
      "total": "Total",
      "company": "Related client",
      "pending_client": "Awaiting client response",
      "pending_admin": "Awaiting technician response",
      "pending_you": "Awaiting your response",
    },
    "invoices": {
      "title": {
        "invoice": "Invoices",
        "documents": "Documents",
        "recurring": "Subscriptions",
        "recurrings_invoices": "Subscription invoices:",
        "admin_title": "Invoices, Credit Notes and Orders",
      },
      "differed": "This invoice will be automatically paid along with your subscription renewal.",
      "pay": "Pay the invoice",
      "auto_debit_part_title": "Your services are currently paid by automatic bank transfer.",//
      "auto_debit_part_one": "The payment of your order will be made by the end of the month.",
      "auto_debit_part_two": "If you have any question, please contact our Support :",
      "auto_debit_btn": "Create a ticket",
      "last-invoice": "Last Invoice",
      "details": "Invoice N° ",
      "order_details": "Order N° ",
      "pending": "Pending",
      "multi-pay": "Multi-Invoice Payment",
      "order_cancel": {
        "title": "Cancel the order",
        "msg": "Are you sure you want to cancel this order?",
        "btn": "Cancel the order"
      },
      "tabs": {
        "invoice_tab": "Invoice",
        "order_tab": "Order",
        "credit_tab": "Credit",
        "payment_tab": "Payment",
        "payment_list_tab": "Payments list",
        "expenses_list_tab": "Expenses list",
        "payment_tab_header": "Details",
        "dispute_list_tab": "List of Disputes",
        "payment_detail_header": [
          "Invoice No.",
          "Amount",
          "Discount",
          "Net Amount",
          "VAT",
          "Total Amount",
          "Paid",
          "Balance",
        ],
      },
      "no-payments": "No payments.",
    },
    "cgv": {
      "title": "Terms of service",
      "action": "Accept terms and conditions",
      "next": "Next",
      "previous": "Previous",
      "download": "Download",
      "accept": "I accept the Terms of Service.",
      "signed-at": "You have signed our Terms of Service on {date} from IP address {ip}.",
      "up-to-date": "Our ToS have not been updated since your signature.",
      "not-up-to-date": "Our ToS have been updated since your signature.",
      "current-version": "The current version is V{version}.",
      "read": "Read our Terms of Service",
    },
    "email-validation": {
      "title": "Validation Email",
      "send-action": "Please resend confirmation link"
    },
    "forget-password": {
      "title": "Forgotten password",
      "send-action": "Please send me instructions to reset my password",
      "confirm-success": "An email has been sent to reset your password (please also check your spams)."
    },
    "forget-password-change": {
      "title": "New password",
      "action": "Confirm"
    },
    "commercial": {
      "title": "Sales",
      "last_name": "Last name",
      "first_name": "First name",
      "email": "Email",
      "phone": "Phone",
      "status": "Status",
      "disabled": "Disabled",
      "create_commercial": "Create a salesperson",
    },
    "cron": {
      "add-cron": "Add a task",
      "header": [
        "Task",
        "Name",
        "Company",
        "User",
        "Server",
        "Scheduling",
        "Recurring",
        "Status",
        "Enabled",
      ],
      "job": {
        "type": {
          "schedule-reboot": "Reboot",
          "snapshot": "Snapshot",
          "undefined": "",
        },
        "message": {
          "create-success": "Job created",
          "delete-success": "Job deleted",
          "update-success": "Job updated",
          "no-job": "No scheduled tasks",
        },
        "creation": {
          "title": "Creating a task",
          "label": [
            "Company",
            "Server",
            "Type",
            "Recurring task",
            "Scheduling",
          ],
          "no-credit": [
            "No credits available.",
            "Without credits, scheduled recurring tasks on a server cannot be executed.",
            "We invite you to purchase a credit pack to enable the execution of multiple tasks per server.",
            "Please note that these credits also allow for the reception of SMS alerts.",
            "We thank you for your understanding."
          ],
        },
        "toggle": {
          "button-tip-true": "Click to activate the task",
          "button-tip-false": "Click to deactivate the task",
          "true": "Task deactivated",
          "false": "Task activated",
        },
      },
      "modal": {
        "delete-job-title": "Delete task {job}",
        "delete-job-description": "By confirming this action, this task will be deleted.",
        "delete-job-confirm": "Are you sure you want to delete this task?",
        "delete-job-warning": "This action is reversible.",
      },
    },
    "managed-service": {
      "title": "Managed Services",
      "terminate-canceled": "Managed Services Termination Canceled",
      "help": {
        "terminate": "Terminate Managed Services",
        "cancel-terminate": "Cancel Termination",
        "download": "Download the contract",
      },
      "dialog-terminate": {
        "title": "Terminate Managed Services for server {server}",
        "warning": "This action is reversible",
        "message": "By confirming this action, the managed services will be terminated.",
        "extra": "Are you sure you want to terminate these managed services?",
        "button": "Terminate",
      },
      "dialog-cancel-terminate": {
        "title": "Cancellation of Managed Services Termination",
        "warning": "This action is sensitive",
        "message": "By confirming this action, the termination of your managed services will be canceled.",
        "extra": "Are you sure you want to cancel the termination of these managed services?",
        "button": "Cancel Termination",
      },
    },
    "scheduled-action": {
      "add-action": "Create an action",
      "goto-server": "Go to server",
      "header": [
        "Action",
        "Server",
        "IP",
        "Company",
        "Creation",
        "Scheduling",
        "Pending",
        "Completed",
      ],
      "type": {
        "delete_server": "Server termination",
        "delete_ip": "IP termination",
        "delete_outsourcing": "Outsourcing termination",
        "deactivate_server": "Server deactivation",
      },
      "states": {
        "all": "All",
        "pending": "Pending",
        "terminated": "Completed",
      },
      "dialog-action-delete": {
        "title": "Delete a scheduled action",
        "danger": "This deletion is irreversible",
        "message": "By confirming this request, the scheduled action will be deleted",
        "extra": "Are you sure you want to delete this scheduled action?",
      },
      "operation": [
        "Creation of a scheduled action",
        "Modification of a scheduled action",
      ],
    },
    "dispute-list": {
      "title": "Disputes",
      "create-dispute": "Create a Dispute",
      "date": "Date",
      "number": "Dispute No.",
      "ref": "Reference",
      "facture": "Attached Invoices",
      "total-amount": "Amount",
      "fees": "Fees",
      "client_code": "Client Code",
      "client": "Client",
      "no_dispute": "No Disputes Found",
      "paid": "Settled",
      "status": "Status",
      "states": {
        "title": "Status",
        "lost": "Lost",
        "needs_response": "Pending",
        "under_review": "Under Review",
        "warning_closed": "Warning Closed",
        "warning_needs_response": "Warning Pending",
        "warning_under_review": "Warning Under Review",
        "won": "Won",
      },
      "reason": {
        "title": "Reason",
        "bank_cannot_process": "Bank processing impossible",
        "credit_not_processed": "Not processed by the bank",
        "customer_initiated": "Customer request",
        "debit_not_authorized": "Debit not authorized",
        "duplicate": "Billing duplicate",
        "fraudulent": "Fraudulent",
        "general": "Various reasons",
        "incorrect_account_details": "Incorrect account details",
        "insufficient_funds": "Insufficient funds",
        "product_not_received": "Product not received",
        "product_unacceptable": "Unacceptable product",
        "subscription_canceled": "Subscription canceled",
        "unrecognized": "Unrecognized payment",
      },
      "date-range": {
        "month": "Month",
        "all": "All",
        "last-month": "Last Month",
        "last-quarter": "Last Quarter",
        "format": "dd/MM/yyyy",
        "apply": "Apply",
        "cancel": "Cancel",
        "weekLabel": "W",
        "customRange": "Custom",
        "daysOfWeek": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "monthNames": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        "monthNamesFull": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      },
      "help": {
        "pay": "Pay the invoices",
      },
    },
    "drp-location": {
      "empty": "List empty",
      "name": "Name",
      "url": "URL",
      "dialog-action-delete": {
        "title": "Delete Location",
        "danger": "This deletion is irreversible",
        "message": "Confirming this request will delete the location",
        "extra": "Are you sure you want to delete this location?",
      },
      "operation": [
        "Creating a location",
        "Modifying a location",
      ],
    },
    "statistics": {
      "title": "Statistics",
      "tab": {
        "title": "Invoices",
      },
      "header": {
        "year": "Year",
        "month": "Month",
        "paid": "Settled",
        "pending": "Pending",
        "unpaid": "Unpaid",
      },
    },
  },
  "unit": {
    "GB": "Go",
    "vcpu": "vCores",
    "mbps": "mbps",
    "Cores": "vCores",
  },
  "form": {
    "required-field": "Required field",
    "missing-fields": "Please complete the missing fields",
    "password-constraints": "The password must contain at least 6 characters",
    "email-invalid": "Your email address is not valid",
    "affiliate-code-invalid": "Invalid affiliation code",
    "country-invalid": "The country is not valid",
    "password-condition": {
      "characters": "characters min.",
      "contains-number": "1 number",
      "contains-uppercase": "1 uppercase",
      "contains-special": "1 special character",
    },
  },
  "beta-functionnality-modal": {
    "title": "Limited access functionality.",
    "content": "To activate this feature, you must contact our sales department by phone or ticket."
  },
  "tour": {
    "next": "Next",
    "previous": "Previous",
    "end": "End",
    "skip": "Skip the tour",
    "global": {
      "play": "Replay the tour",
      "dashboard": {
        "header": {
          "title": "Dashboard"
        },
        "content": "Here you will find a summary of your ticket information, actions and consumption statistics.",
      },
      "servers": {
        "header": {
          "title": "Here you can control your servers with many features:"
        },
        "content": "- Remote access (troubleshooting via kvm)<br />" +
          "- Upgrade / Downgrade of cpu/ram/disk resources<br />" +
          "- ISO set-up and recovery ISO<br />" +
          "- Stop / Start of servers<br />" +
          "- ipv6 activation and creation of reverses<br />" +
          "- Reset, snapshot and template creation<br />" +
          "- Cpu/ram/disk/network resource statistics",
      },
      "networks": {
        "header": {
          "title": "Network"
        },
        "content": "Create and manage your private networks for securing and setting up your server platform."
      },
      "company": {
        "header": {
          "title": "Company"
        },
        "content": "Here you can manage your company's information as well as your payment methods and role management.",
      },
      "tickets": {
        "header": {
          "title": "Ticket"
        },
        "content": "Here you can communicate with our different departments: technical, sales and accounting.",
      },
      "affiliations": {
        "header": {
          "title": "Affiliations"
        },
        "content": "Here you can manage your affiliation account and the amounts credited to it.<br />" +
          "« Contact our sales department to check your eligibility »",
      },
      "recurring": {
        "header": {
          "title": "Subscription"
        },
        "content": "Manage the list of your subscriptions and the names of your projects that appear on your invoices.",
      },
      "invoice": {
        "header": {
          "title": "Document"
        },
        "content": "Here you can find all your accounting documents (orders, invoices and credit notes)",
      },
      "vms": {
        "header": {
          "title": "Caddie"
        },
        "content": "Here you can order your servers and have them delivered in less than a minute.",
      },
      "header-user": {
        "header": {
          "title": "Account settings"
        },
        "content": "- Here you can manage your different account settings<br />" +
          "- Switch interfaces between your different accounts<br />" +
          "- Manage your <strong>SSH</strong> and <strong>API</strong> key shop<br />" +
          "- Activate your 2FA authentication<br />" +
          "- Purchase your services (one-off intervention and Microsoft licence)<br />" +
          "- Access the API documentation"
      }
    }
  },
  "object_search": {
    "empty": "Enter at least {length} characters",
    "selected": "The selected value is {value}",
    "match": "The name must exist in the list"
  },
  "company_search": {
    "placeholder": "Search for a client",
    "empty": "Search and then select a company",
    "selected": "The selected company is {value}",
  },
  "cron-date": {
    "minute": "Minute",
    "hour": "Hour",
    "month": "Month",
    "day-of-month": "Day of the month",
    "day-of-week": "Day of the week",
    "help": "The asterisk (*) represents any possible value for the field in question.",
    "hours": "Hours",
    "at": "At",
    "all": "All",
  },
  "days-of-week": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  "months-of-year": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "Forbidden": "Permission Denied",
  "errors": {
    "CREATE_SUCCESSFUL": "Creation successful",
    "UPDATE_SUCCESSFUL": "Update successful",
    "DELETE_SUCCESSFUL": "Deletion successful",
    "NO_DOCUMENT_FOUND": "Does not exist!",
    "PAGE_NOT_FOUND": "Page not found.",
    "ALREADY_EXISTS": "Already exists",
    "TASK_ALREADY_RUNNING": "Task already running",
    "INVALID_RECAPTCHA": "Invalid Recaptcha.",
    "UPDATE_UNSUCCESSFUL": "Update unsuccessful",
    "ERR_ORDER_DELETION": "Error during the deletion of the order",
    "DOWNLOAD_ERROR": "Error during download",
    "DISABLE_SUCCESS": "Deactivation successful",
    "DISABLE_ERROR": "Error during deactivation",

    "TOO_MANY_KVM": "You have too many KVMs open",

    "PERMISSION_DENIED": "You do not have the permissions for this action.",
    "2FA_INVALID_SECRET": "Secret invalid",
    "USER_UPDATE_SSH_KEY_SUCCESS": "Updated successfully.",
    "USER_UPDATED_SUCCESSFULLY": "Updated successfully.",
    "TOKEN_UPDATE_SUCCESS": "Update successful.",

    "SERVER_RESET_FAIL_NOT_LICENSED_OS_TO_LICENSED_OS": "This feature is not yet available for your user. Please contact support.",

    "IMAGES_EMPTY": "You have no image, you can create one from the snapshot of a machine.",

    "APP_BETA_FUNCTIONNALITY": "Soon, a titan mobile application will allow you to control all the features.",

    "BETA_FUNCTIONNALITY": "To activate this feature, you must contact our sales department by phone or ticket.",

    "INVALID_EMAIL_OR_PASSWORD": "Invalid email address or password.",
    "MISSING_EMAIL_OR_PASSWORD": "Adresse e-mail ou mot de passe manquant.",
    "ACCOUNT_NOT_VALIDATED": "Your account has not been validated, please check your emails.",
    "USER_OR_COMPANY_MISSING_NAME_OR_ADDRESS_INFORMATION": "Vous devez d'abord compléter vos informations personnelles ainsi que celles de votre entreprise.",

    "INTERNAL_ERROR": "Internal error.",
    "BAD_PERMISSION": "Bad permission.",
    "BAD_REQUEST": "Bad request.",
    "SERVER_NOT_FOUND": "Server not found.",
    "COMPANY_NOT_FOUND": "Company not found.",
    "INVOICE_NOT_FOUND": "Invoice not found.",
    "INVOICE_ALREADY_PAID": "Invoice already paid.",
    "PAYMENT_METHOD_NOT_FOUND": "Payment method not found.",
    "PLAN_NOT_FOUND": "Plan not found.",
    "CREDIT_NOT_FOUND": "Credit not found.",
    "CREDIT_EXPIRED": "Credit has expired.",
    "CREDIT_SPENT": "Credit already spent.",
    "CREDIT_INVALID": "Invalid credit, please contact support.",
    "PAYMENT_FAIL": "Payment failed.",
    "PROJECT_NAME_INVALID": "Project name invalid, length must be between 4 and 60 characters.",
    "PROJECT_NAME_CONFLICT": "Project name is already taken, please choose another one.",
    "ROLE_NAME_INVALID": "Role name invalid, length must be between 2 and 50 characters.",
    "REVERSE_INVALID": "Invalid reverse: make sure to set appropriate IP address on reverse DNS zone.",

    "AVATAR_INVALID_TYPE": "The chosen avatar is not in the right format (jpg or png).",
    "AVATAR_INVALID_SIZE": "The chosen avatar does not respect the conditions.",
    "AVATAR_UPDATED_SUCCESSFULLY": "Avatar updated successfully",
    "PHONE_NUMBER_INVALID": "The phone number is invalid.",

    // License
    "LICENSE_NOT_FOUND": "License not found",

    // Snapshot
    "SNAPSHOT_NOT_FOUND": "Snapshot not found.",
    "SNAPSHOT_RESTORE_STARTED": "Snapshot restoration pending.",
    "SNAPSHOT_RENAME_SUCCESS": "Snapshot renamed successfully.",
    "SNAPSHOT_DELETE_SUCCESS": "Snapshot deleted successfully.",
    "SNAPSHOT_CREATE_FAIL_LIMIT_EXCEEDED": "Snapshot limit exceeded.",
    "SNAPSHOT_DELETE_INVALID_STATE": "Unable to delete snapshot: invalid state.",
    "SNAPSHOT_RESTORE_INVALID_SNAPSHOT_STATE": "Unable to restore snapshot: invalid snapshot state.",
    "SNAPSHOT_CREATE_FAIL_TOO_FAST": "Unable to create new snapshot: duration between two snapshots must be higher than 30 minutes.",

    // Image
    "IMAGE_CREATE_FAIL_INSUFFICIENT_SPACE": "Your storage space is insufficient.",
    "IMAGE_CREATE_FAIL_QUOTA_EXCEEDED": "The image creation quota has been exceeded.",
    "SERVER_CREATE_FAIL_SERVER_DISK_IMAGE_AND_TEMPLATE_CONFLICT": "Conflict between the disk image and the template.",
    "SERVER_CREATE_FAIL_INVALID_DISK_SIZE": "Invalid disk size.",
    "SERVER_CREATE_FAIL_INVALID_IMAGE_STATE": "Invalid image status.",
    "SERVER_CREATE_FAIL_DISK_SOURCE_INVALID": "Invalid disk source.",
    "SERVER_CREATE_FAIL_IMAGE_UUID_MISSING": "Missing unique identifier.",
    "SERVER_CREATE_FAIL_TEMPLATE_UUID_MISSING": "Missing unique identifier.",
    "IMAGE_DELETE_SUCCESS": "Image successfully deleted.",
    "IMAGE_UPDATE_SUCCESS": "Image successfully updated.",
    "IMAGE_CREATE_SUCCESS": "Image successfully created.",
    "NO_IMAGE": "You have no image, you can create one from a snapshot",
    "IMAGE_NOT_FOUND": "Image not found.",
    "IMAGE_DELETE_FAIL_IS_ACTUALLY_USED": "This image is used, you cannot delete it.",
    "IMAGE_CREATE_FAIL_PLESK_DISABLED": "It is impossible to create PLESK templates.",
    "IMAGE_CREATE_FAIL_NAME_ALREADY_USED": "This name is already in use.",
    "IMAGE_INVALID_NAME_SIZE": "The size of the name is incorrect.",
    "IMAGE_INVALID_OS_NAME_SIZE": "The size of the OS name is incorrect.",
    "IMAGE_INVALID_OS_VERSION_SIZE": "The OS version size is incorrect.",
    "IMAGE_CREATE_FAIL_SNAPSHOT_INVALID_STATE": "The snapshot is in an invalid state.",
    "IMAGE_FAIL_TO_RETRIEVE_ORIGINAL_TEMPLATE": "Impossible to find the original template.",
    "INVALID_TARGET_STATE": "Target non-existent or in a non-compliant state with the request.",

    // Addon
    "ADDON_INSTALL_FAIL_INVALID_PLAN": "Invalid plan.",
    "ADDON_INSTALL_FAIL_INVALID_QUANTITY": "Invalid addons quantity.",
    "ADDON_INSTALL_FAIL_INVALID_ITEM": "Invalid addon item.",
    "ADDON_INSTALL_FAIL_CPU_LIMIT_EXCEEDED": "Unable to install addon(s): CPUs limit exceeded, please contact support.",
    "ADDON_INSTALL_FAIL_RAM_LIMIT_EXCEEDED": "Unable to install addon(s): RAM limit exceeded, please contact support.",
    "ADDON_INSTALL_FAIL_DISK_LIMIT_EXCEEDED": "Unable to install addon(s): disk limit exceeded, please contact support.",
    "ADDON_INSTALL_FAIL_SRV_ON_TERMINATION": "Unable to install addon(s): server deletion is scheduled.",
    "ADDON_UNINSTALL_FAIL_SERVER_BAD_STATE": "Cannot uninstall addon(s): the server must be stopped.",
    "ADDON_UNINSTALL_FAIL_NOT_FOUND": "Cannot uninstall addon(s): addon (s) not found.",
    "ADDON_UNINSTALL_FAIL_SERVER_DO_NOT_BELONG_TO_SERVER": "Cannot uninstall addon(s): ​addon(s) are not installed.",
    "ADDON_UNINSTALL_FAIL_ITEM_TYPE_DISK_INVALID": "Cannot uninstall addon(s): Disk addons cannot be uninstalled.",
    "ADDON_UNINSTALL_FAIL_INVALID_QUANTITY": "Cannot uninstall addon(s): invalid quantity.",
    "ADDON_UNINSTALL_FAIL_SRV_ON_TERMINATION": "Cannot uninstall addon(s): server deletion is scheduled.",
    "ADDON_UNINSTALL_SUCCESS": "Addon(s) successfully uninstalled.",
    "ADDON_LIST_FAIL": "Unable to list addons.",
    "ADDON_ERR_INVOICE_NOT_PAID": "Addon addition not possible. Invoice(s) unpaid.",

    // Invoice
    "INVOICE_PAYMENT_NOT_FOUND": "Unable to find invoice payment.",
    "INVOICE_CONTACT_NOT_RECOGNIZED": "Invoice contact not recognized.",
    "INVOICE_CREATE_FAIL": "Unable to create new invoice.",
    "INVOICE_CREATE_SUCCESS": "New invoice created.",
    "INVOICE_CREATE_NOT_REQUIRED": "New invoice creation is not yet required.",
    "INVOICE_ITEM_BUILD_FAIL": "Unable to build invoice items.",
    "UNPAID_INVOICES_EXIST": "Sorry, your account shows unpaid invoices.",

    // Order
    "ORDER_CANCEL_SUCCESS": "Order canceled successfully.",
    "ORDER_ALREADY_INVOICE": "The order has already been transformed to invoice.",
    "ORDER_ALREADY_CANCELED": "The order is already canceled.",
    "ORDER_ALREADY_PAID": "The order is already paid.",
    "ORDER_HAS_SUCCESS_PAYMENT": "Order payment already registered. Please contact support.",
    "ORDER_HAS_WAITING_PAYMENT": "Pending order payment already registered. Please wait.",
    "ORDER_HAS_DEPLOYED_ITEM": "Order item already deployed. Please contact support.",
    "ORDER_NOT_CANCELABLE": "The order cannot be canceled. Please contact support.",

    // Recurring invoices
    "RECURRING_INVOICE_NOT_FOUND": "Subscription not found.",
    "RECURRING_INVOICE_UPDATE_FAIL": "Unable to update subscription.",
    "RECURRING_INVOICE_CANCEL_SUCCESS": "Subscription terminated successfully.",
    "RECURRING_INVOICE_UPDATE_SUCCESS": "Subscription updated successfully.",
    "RECURRING_INVOICE_CREATE_FAIL": "Unable to create a new subscription.",
    "RECURRING_INVOICE_CREATE_SUCCESS": "New subscription successfully created.",
    "RECURRING_INVOICE_INVALID_FREQ": "The subscription frequency is not valid.",
    "RECURRING_INVOICE_ITEM_BUILD_FAIL": "Unable to add articles to your subscription.",
    "RECURRING_INVOICE_NOT_ONGOING": "The subscription is not in progress.",
    "RECURRING_INVOICE_START_TOO_EARLY": "Subscription starting date is too early, remove it to dynamically start it after making payment.",
    "RECURRING_INVOICE_CANCELED": "The subscription is canceled.",
    "RECURRING_INVOICE_PENDING": "Subscription is pending.",
    "RECURRING_INVOICE_ONGOING": "Deletion not possible. An active subscription in this account requires at least one registered payment method.",
    "RECURRING_ITEM_NOT_LICENSE_TYPE": "The item is not of license type",
    "RECURRING_INVOICE_NAME_CANCELED": "Subscription canceled. Please change the project name.",
    "SUBSCRIPTION_ALREADY_EXISTS": "The subscription already exists",
    "OVER_FRAMEWORK_AGREEMENT_QUOTA": "You have exceeded the resource quantities of the framework agreement",

    // Payment methods
    "PAYMENT_METHOD_ADD_FAIL_EMPTY_NAME": "You must specify the full name on the card.",
    "PAYMENT_METHOD_UPDATE_SUCCESS": "The payment method was updated successfully.",
    "PAYMENT_METHOD_REMOVE_SUCCESS": "The payment method was removed successfully.",
    "PAYMENT_METHOD_ADD_SUCCESS": "The payment method was added successfully.",
    "PAYMENT_METHOD_ALREADY_REGISTERED": "The payment method is already registered.",
    "PAYMENT_METHOD_ADD_FAIL": "Unable to add the new payment method.",
    "PAYMENT_EXCEED_AMOUNT": "The payment amount exceeds the payable amount.",
    "PAYMENT_FAILED": "Payment failed.",
    "PAYMENT_METHOD IS_DEFAULT": "Default payment method deletion not allowed.",
    "Credit card number is not long enough": "Credit card number is not long enough",
    "Invalid credit card number": "Invalid credit card number",
    "Test numbers are not allowed": "Test numbers are not allowed",
    "Invalid year": "Invalid year",
    "Invalid month": "Invalid month",
    "Credit card has expired": "Credit card has expired",
    "Invalid CVV": "Invalid CVV number",
    "Unknown credit card method": "Unknown credit card method",
    
    // Payment delay
    "PAYMENT_DELAY_ALREADY_EXISTS": "This payment delay already exists.",
    
    // Credit
    "CREDIT_CREATE_SUCCESS": "The credit was created successfully.",
    "CREDIT_FUNDING_EXCEEDED": "The credit funding for a targeted invoice was exceeded.",
    "CREDIT_FUNDING_INVOICE_NOT_PAID": "A targeted funding invoice is not paid.",
    "CREDIT_FUNDING_ITEM_NOT_FOUND": "The item of a targeted funding invoice was not found.",
    "CLEAR_AMOUNT_EXCEED": "The amount exceeds the document balance.",

    // Migration
    "MIGRATE_FAIL_ITEM_NOT_DEPLOYED": "Unable to migrate, one or more items are not yet deployed.",

    // Server
    "BAD_OID": "Invalid OID",

    // Delete
    "SERVER_DELETE_FAIL_ALREADY_ON_TERMINATION": "This server is already scheduled for termination.",
    "SERVER_DELETE_FAIL_PLAN_NOT_FOUND": "Unable to delete server: plan not found.",
    "SERVER_DELETION_PENDING_SUCCESS": "Server(s) deletion pending.",
    "SERVER_DELETION_PENDING": "Unauthorized action. Server termination has been requested.",
    "SERVER_DELETE_SUCCESS": "Server deleted successfully.",
    "SERVER_DELETE_FAIL_RECURRING_NOT_FOUND": "Unable to delete server: subscription not found.",
    "SERVER_DELETE_FAIL_WAN_CONFIG_ERROR": "Unable to delete server: WAN config error.",
    "SERVER_DELETE_FAIL_UPDATE_RECURRING_ERROR": "Unable to delete server: subscription update error.",
    "SERVER_CANCEL_DELETE_FAIL_ALREADY_DELETED": "Unable to undo server deletion: the server is already deleted.",
    "SERVER_DELETE_FAIL_ACTION_NOT_SCHEDULED": "The deletion of this server is not scheduled.",
    // Create
    "SERVER_CREATE_FAIL": "Unable to find corresponding invoice.",
    "SERVER_CREATE_FAIL_FREE_WAN_NOT_FOUND": "Unable to create server: not enough WAN available, please contact support.",
    "SERVER_CREATE_FAIL_SERVER_LIMIT_EXCEEDED": "Unable to create server: servers quota exceeded, please contact support.",
    "SERVER_CREATE_FAIL_CPUS_LIMIT_EXCEEDED": "Unable to create server: CPUs quota exceeded, please contact support.",
    "SERVER_CREATE_FAIL_RAM_LIMIT_EXCEEDED": "Unable to create server: RAM quota exceeded, please contact support.",
    "SERVER_CREATE_FAIL_DISK_LIMIT_EXCEEDED": "Unable to create server: disk quota exceeded, please contact support.",
    "SERVER_CREATE_FAIL_IDENT_MISSING": "Unable to create server: missing password and/or SSH key.",
    "SERVER_CREATE_FAIL_ADDON_QUANTITY_EXCEEDED": "Unable to create server: addons limit exceeded, please contact support.",
    "SERVER_CREATE_FAIL_INVALID_ADDON": "Unable to create server: addon(s) not found or invalid.",
    "SERVER_CREATE_FAIL_INVALID_QUANTITY": "Unable to create server: wrong quantity.",
    "SERVER_CREATE_FAIL_INVALID_PLAN": "Unable to create server: unknown plan.",
    "SERVER_CREATE_FAIL_INVALID_CPU_QUANTITY": "Unable to create server: invalid CPU(s) quantity.",
    "SERVER_CREATE_FAIL_INVALID_TEMPLATE": "Unable to create server: invalid template.",
    "SERVER_CREATE_FAIL_INVALID_PASSWORD": "Unable to create server: invalid password.",
    "SERVER_CREATE_FAIL_FREE_MAC_NOT_FOUND": "Unable to create server: no MAC address available.",
    "SERVER_CREATE_FAIL_FREE_IP_NOT_FOUND": "Unable to create server: no IP address available.",
    "SERVER_CREATE_FAIL_MISSING_LICENSE": "Unable to create server: missing license.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_INVALID": "Unable to create server: invalid project name.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_DUPLICATE": "Unable to create server: the project name already exists.",
    // Reset
    "SERVER_RESET_SUCCESS": "Server reset pending.",
    "SERVER_RESET_FAIL_MISSING_PASSWORD_OR_KEY": "Unable to reset server: password and/or SSH keys missing.",
    "SERVER_RESET_FAIL_INVALID_PLAN": "Unable to reset server: invalid or unknown plan.",
    "SERVER_RESET_FAIL_INVALID_TEMPLATE": "Unable to reset server: invalid template.",
    "SERVER_RESET_FAIL_WAN_NOT_FOUND": "Unable to reset server: WAN entry not found.",
    "SERVER_RESET_FAIL_NO_PRIMARY_IP": "Unable to reset the server: Primary IP not found.",
    // Update
    "SERVER_UPDATE_SUCCESS": "Server updated successfully.",
    "SERVER_UPDATE_FAIL_INVALID_NAME": "Unable to update server: invalid format for new name.",
    "SERVER_UPDATE_FAIL_MISSING_PARAMETER": "Unable to update server: missing parameter.",
    "SERVER_UPDATE_FAIL_UPDATE_REVERSE": "Unable to update the server reverse entry, please contact support.",
    "SERVER_UPDATE_FAIL_INVALID_REVERSE": "Unable to update server: server reverse invalid format.",
    "SERVER_UPDATE_FAIL_WAN_NOT_FOUND": "Unable to update server: WAN entry not found.",
    "SERVER_UPDATE_FAIL_NOTES_TOO_LONG": "Unable to update server: notes field too long.",
    "SERVER_UPDATE_FAIL_NO_PRIMARY_IP": "Unable to update server: no primary IP found.",
    // Actions
    "SERVER_ACTION_FAIL_UNKNOWN_ACTION": "Unknown action.",
    "SERVER_ACTION_FAIL_CONFLICT": "The action is not compatible with current server state.",
    "SERVER_ACTION_SUCCESS": "Server action sent successfully.",
    // ISO
    "SERVER_LOAD_ISO_BAD_PATH": "Unable to pulling ISO: invalid path.",
    "SERVER_LOAD_ISO_BAD_PROTOCOL": "Unable to pulling ISO: invalid protocol.",
    "SERVER_LOAD_ISO_SUCCESS": "ISO loaded successfully.",
    "SERVER_UNLOAD_ISO_SUCCESS": "ISO unloaded successfully.",
    // OUTSOURCING - MANAGED SERVICES
    "SERVER_OUTSOURCING_SET": "Service level active on the server. Please contact the accounting department.",
    "SERVER_OUTSOURCING_ALREADY_SET": "Service level already active for the server(s)",
    "SERVER_OUTSOURCING_TERMINATION_EXISTS": "A termination request already exists.",
    "SERVER_OUTSOURCING_TERMINATION_TOO_LATE": "Termination must be requested at least two months before the end of the contract.",
    "SERVER_OUTSOURCING_TERMINATION_NOT_EXISTS": "There is no termination request.",
    // DRP
    "SERVER_DRP_ALREADY_SET": "DRP already activated for the server(s)",
    // DEACTIVATION
    "SERVER_DEACTIVATION_ALREADY_SCHEDULED": "Server deactivation already scheduled.",
    "SERVER_DEACTIVATION_DATE_INVALID": "The deactivation date is invalid.",

    // Template
    "TEMPLATE_DELETE_SUCCESS": "Template deleted successfully.",
    "TEMPLATE_GET_FAIL_INVALID_OS": "Unable to retrieve templates.",
    "TEMPLATE_DELETE_NOT_FOUND": "Unable to delete template: not found.",

    // IP KVM
    "IPKVM_FAIL_ACTION_UNKNOWN": "Unknown action on KVM IP.",
    "IPKVM_SEND_ACTION_SUCCESS": "KVM IP action pending.",
    "IPKVM_GET_URI_FAIL_KVM_NOT_STARTED": "KVM IP is not started on server.",
    "IPKVM_FAIL_INVALID_DEADLINE": "Unable to send KVM IP action: Invalid timeout.",
    "IPKVM_FAIL_TOO_LONG_DEADLINE": "Unable to send KVM IP action: Timeout must not exceed 5 hours.",
    "IPKVM_FAIL_NOT_STOPPED": "Action impossible: the KVM is not stopped",

    // Migrate
    "MIGRATE_FAIL_MISSING_SRC": "Unable to migrate: missing source (hypervisor or server).",
    "MIGRATE_FAIL_CONFLICT_SRC": "Unable to migrate: source conflict (hypervisor and server).",
    "MIGRATE_FAIL_HYPERV_SRC_EQUAL_DST": "Unable to migrate: hypervisor source and destination are the same.",
    "MIGRATE_FAIL_HYPERV_SRC_NOT_FOUND": "Unable to migrate: hypervisor source not found.",
    "MIGRATE_FAIL_HYPERV_DST_NOT_FOUND": "Unable to migrate: hypervisor destination not found.",
    "MIGRATE_SUCCESS": "Migration request sent successfully.",

    // Event / History
    "SERVER_EVENT_FAIL_INVALID_INTEGER": "Unable to retrieve server events: invalid integer.",
    "SERVER_EVENT_FAIL_INTEGER_LIMIT_EXCEEDED": "Unable to retrieve server events: number of events must be between 1-50.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_START": "Unable to retrieve server history: invalid start timestamp.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_END": "Unable to retrieve server history: invalid end timestamp.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_TIMER": "Unable to retrieve server history: interval must not exceed 12 hours.",

    // Network
    "NETWORK_NOT_FOUND": "Network not found.",
    "NETWORK_DELETE_FAIL_NOT_FOUND": "Unable to delete network: not found.",
    "NETWORK_DELETE_FAIL_SERVER_ATTACHED": "Unable to delete network: a server is still attached.",
    "NETWORK_CREATE_FAIL_COUNT_EXCEEDED": "Unable to create network: quota exceeded, please contact support.",
    "NETWORK_CREATE_FAIL_INVALID_CIDR": "Unable to create network: invalid CIDR.",
    "NETWORK_CREATE_FAIL_CIDR_CONFLICT": "Unable to create network: CIDR overlaps with another network.",
    "NETWORK_CREATE_FAIL_CIDR_TOO_LARGE": "Unable to create network: CIDR too large, a single network must not exceed 65536 IPs (/16).",
    "NETWORK_CREATE_FAIL_MANAGED_SERVICES_DISABLED": "Unable to create managed network: managed services are disabled for your company.",
    "NETWORK_CREATE_FAIL_INVALID_NAME": "Unable to create network: invalid name format.",
    "NETWORK_CREATE_FAIL_GATEWAY_NOT_FOUND": "Unable to create network: gateway not found.",
    "NETWORK_ATTACH_FAIL_SERVER_FULL": "Unable to attach server: all server's ports used.",
    "NETWORK_ATTACH_FAIL_MAX_SERVER_EXCEEDED": "Unable to attach server: all network's ports used",
    "NETWORK_ATTACH_FAIL_NETWORK_DOWN": "Unable to attach server: network is down.",
    "NETWORK_ATTACH_FAIL_SERVER_DISABLED": "Unable to attach server: server is disabled, please contact support.",
    "NETWORK_ATTACH_FAIL_MANAGED_SERVICE_CONFLICT": "Unable to attach server: conflict with managed service",
    "NETWORK_ATTACH_FAIL_MULTIPLE_MANAGED_NETWORKS": "Unable to attach server: a managed server can only be attached to a single managed network.",
    "NETWORK_ATTACH_FAIL_ALREADY_ATTACHED_TO_THIS_SERVER": "The server is already attached to this network.",
    "NETWORK_DETACH_FAIL_SERVER_DISABLED": "Unable to detach server: server is disabled, please contact support.",
    "NETWORK_DETACH_FAIL_SERVER_NOT_ATTACHED": "Unable to detach server: server is not attached to the network.",
    "NETWORK_DETACH_FAIL_MANAGED_SERVER": "Unable to detach server: managed servers cannot be manually detached from network.",
    "NETWORK_DETACH_FAIL_NETWORK_DOWN": "Unable to detach server: network is down.",
    "NETWORK_UPDATE_FAIL_INVALID_NAME": "Unable to update network: invalid name format.",
    "NETWORK_SET_GATEWAY_FAIL_NOT_MANAGED": "Unable to set gateway: the network is not managed.",
    "NETWORK_SET_GATEWAY_FAIL_IP_NOT_FOUND": "Unable to set gateway: IP address not found.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_MANAGED": "Unable to delete gateway: the network is not managed.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_FOUND": "Unable to delete gateway: gateway not found.",
    "NETWORK_ADD_FW_RULE_FAIL_NOT_MANAGED": "Unable to add firewall rule: the network is not managed.",
    "NETWORK_ADD_FW_RULE_FAIL_INTERFACE_NOT_FOUND": "Unable to add firewall rule: interface not found.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_SOURCE": "Unable to add firewall rule: invalid source.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PORT": "Unable to add firewall rule: invalid port.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PROTOCOL": "Unable to add firewall rule: invalid protocol.",
    "NETWORK_REMOVE_FW_RULE_FAIL_NOT_FOUND": "Unable to remove firewall rule: rule not found.",
    "NETWORK_ADD_FW_RULE_SUCCESS": "Firewall rule added successfully.",
    "NETWORK_REMOVE_FW_RULE_SUCCESS": "Firewall rule removed successfully.",
    "NETWORK_SET_GATEWAY_SUCCESS": "Gateway set successfully.",
    "NETWORK_DELETE_GATEWAY_SUCCESS": "Gateway deleted successfully.",
    "NETWORK_ATTACH_SERVER_SUCCESS": "Server attached successfully.",
    "NETWORK_DETACH_SERVER_SUCCESS": "Server detached successfully.",
    "NETWORK_DELETION_SUCCESS": "Network deletion pending.",
    "NETWORK_UPDATE_SUCCESS": "Network updated successfully.",

    // Metric
    "METRIC_EMPTY_NET_IN": "Incoming network data not available.",
    "METRIC_EMPTY_NET_OUT": "Outgoing network data not available.",
    "METRIC_UNAVAILABLE": "Data unavailable.",

    // Ticket
    "TICKET_NOT_FOUND": "Ticket not found.",
    "TICKET_CREATE_FAIL": "Unable to create new ticket: the server UUID is missing.",
    "TICKET_CREATE_FAIL_INVALID_OBJECT": "Unable to create new ticket: invalid object.",
    "TICKET_CREATE_FAIL_INVALID_CATEGORY": "Unable to create new ticket: invalid category.",
    "TICKET_CREATE_FAIL_INVALID_MESSAGE": "Unable to create new ticket: invalid message.",
    "TICKET_CREATE_FAIL_INVALID_LEVEL": "Unable to create new ticket: invalid level.",
    "TICKET_CREATE_FAIL_UNKNOWN_SERVER": "Unable to create new ticket: server not found.",
    "TICKET_ADD_MESSAGE_INVALID_TEXT": "Unable to add message on ticket: invalid message format.",
    "TICKET_ADD_MESSAGE_FAIL_MISSING_TICKET_UUID": "Unable to add message on ticket: missing ticket UUID.",
    "TICKET_ADD_MESSAGE_FAIL_TICKET_LOCKED": "Unable to add message on ticket: ticket locked by someone else.",
    "TICKET_DELETE_FAIL_MISSING_UUID": "Unable to delete ticket: missing UUID.",
    "TICKET_GET_FAIL_MISSING_TICKET_UUID": "Unable to retrieve ticket: missing UUID.",
    "TICKET_LOCK_FAIL_ALREADY_LOCKED": "Ticket already locked.",
    "TICKET_UNLOCK_FAIL_ALREADY_UNLOCKED": "The ticket is not locked",
    "TICKET_UNLOCK_FAIL_LOCKED_BY_OTHER_USER": "Unable to unlock the ticket: the ticket is locked by someone else.",
    "TICKET_LOCK_SUCCESS": "Ticket locked successfully.",
    "TICKET_UNLOCK_SUCCESS": "Ticket unlocked successfully.",
    "TICKET_CREATE_SUCCESS": "Ticket created successfully.",
    "TICKET_CLOSE_SUCCESS": "Ticket closed successfully.",
    "TICKET_DELETE_SUCCESS": "Ticket deleted successfully.",
    "TICKET_ADD_MESSAGE_SUCCESS": "Ticket message added successfully.",
    "TICKET_DELETE_MESSAGE_SUCCESS": "Ticket message deleted successfully.",
    "TICKET_UPDATE_LEVEL_SUCCESS": "Ticket level updated successfully.",
    "TICKET_UPDATE_LEVEL_FAIL_CLOSED": "Unable to update ticket level: ticket is closed.",
    "TICKET_INVALID_MESSAGE": "The ticket message length must be between 3 and 5,000 characters.",
    "ERR_TICKET_ADMIN_CANNOT_CREATE_FOR_HIMSELF": "You can't create a ticket for yourself.",
    "TICKET_CLOSED": "the ticket is closed",
    "TICKET_CREATE_FAIL_OTHER": "Unable to create the ticket",
    "TICKET_REPLY_ALREADY_OPENED": "A reply window is already open",

    // Contact
    "CONTACT_REQUEST_FAIL_RECAPTCHA_ERROR": "Invalid recaptcha.",
    "CONTACT_REQUEST_FAIL_INVALID_EMAIL": "Invalid email format.",
    "CONTACT_REQUEST_SUCCESS": "Email sent successfully.",

    // Job
    "JOB_REQUEST_FAIL_RECAPTCHA_ERROR": "Invalid recaptcha.",
    "JOB_REQUEST_FAIL_INVALID_EMAIL": "Invalid email format.",
    "JOB_REQUEST_SUCCESS": "Email sent successfully.",

    // User
    "USER_2FA_ENABLE_FAIL_WRONG_CODE": "Incorrect validation code",
    "USER_UPDATE_INVALID_FIRSTNAME": "Unable to update user: invalid firstname.",
    "USER_LIST_FAIL": "Unable to retrieve the list of users.",
    "USER_NOT_FOUND": "User not found.",
    "USER_DELETE_FAIL_NOT_FOUND": "Unable to delete user: user not found.",
    "USER_DELETE_SUCCESS": "User deleted successfully.",
    "USER_RESET_PASSWORD_FAIL_INVALID_TOKEN": "Unable to reset user password: invalid token.",
    "USER_RESET_PASSWORD_FAIL_INVALID_PASSWORD": "Unable to reset user password: invalid format.",
    "USER_RESET_PASSWORD_SUCCESS": "Password reset successfully.",
    "USER_RESET_PASSWORD_ASK_SUCCESS": "If you have an existing account, a message containing instructions to reset your password will be sent to your email.",
    "USER_UPDATE_INVALID_LASTNAME": "Invalid lastname",
    "USER_UPDATE_INVALID_PHONE_NUMBER": "Invalid phone number.",
    "USER_UPDATE_INVALID_MOBILE_NUMBER": "Invalid mobile number.",
    "USER_UPDATE_SUCCESS": "User updated successfully.",
    // API KEY
    "USER_ADD_API_KEY_SUCCESS": "Key successfully created",
    // SSH key
    "USER_DELETE_SSH_KEY_SUCCESS": "SSH key deleted successfully.",
    "USER_DELETE_SSH_KEY_FAIL_MISSING_NAME": "The SSH key name is missing.",
    "USER_DELETE_SSH_KEY_FAIL_NOT_FOUND": "SSH key not found.",
    "USER_ADD_SSH_KEY_FAIL_EMPTY": "The SSH key is empty.",
    "USER_ADD_SSH_KEY_FAIL_KEY_INVALID": "The SSH key is invalid.",
    "USER_ADD_SSH_KEY_SUCCESS": "SSH key added successfully.",
    "USER_ADD_SSH_KEY_FAIL_ALREADY_NAME_EXIST": "Key name already exists.",
    // Password
    "USER_UPDATE_PASSWORD_FAIL_WRONG_CURRENT_PASSWORD": "The current password is incorrect.",
    "USER_UPDATE_PASSWORD_FAIL_NEW_PASSWORD_INVALID": "The new password is invalid.",
    "USER_UPDATE_PASSWORD_SUCCESS": "Password updated successfully.",
    // Registration
    "USER_CREATE_FAIL_DEFAULT_PREFERENCES": "Failed to create user default preferences.",
    "USER_CREATE_FAIL_COMPANY": "Failed to create user's company.",
    "USER_UPDATE_FAIL_COMPANIES": "Failed to associate user with companies.",
    "VALIDATE_REGISTRATION_FIRST": "Please validate your registration first.",
    "VALIDATE_USER_SUCCESS": "Your registration is validated.",
      
      // Company
    "COMPANY_DISABLED": "Your company is disabled, please contact support for more information.",
    // Create
    "COMPANY_CREATE_FAIL_USER_ASSIGNMENT": "Unable to assign the user to the new created company.",
    "COMPANY_CREATE_FAIL_ALREADY_EXISTS": "Creation failed, the company already exists",
    // Delete
    "COMPANY_DELETE_FAIL": "Unable to delete company on all users.",
    "COMPANY_DELETE_SUCCESS": "Company deleted successfully.",
    // (Re)Validation
    "VALIDATE_USER_FAIL_CREATE_CONTACT_ERROR": "Unable to create new contact.",
    "VALIDATE_USER_FAIL_UPDATE_COMPANY_ERROR": "Unable to assign accounting ID to the company.",
    "REVALIDATE_USER_FAIL_ALREADY_VALIDATED": "The user is already validated.",
    "REVALIDATE_USER_FAIL_ASSIGN_NEW_COMPANY": "Unable to assign the new company to the new user.",
    "REVALIDATE_USER_SUCCESS": "Validation email sent successfully.",
    // Update
    "INVALID_EMAIL": "The email address is invalid",
    "COMPANY_CREATE_SUCCESS": "Company created successfully.",
    "COMPANY_UPDATE_SUCCESS": "Company updated successfully.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_TYPE": "Unable to update company address: wrong address type.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_NAME": "Unable to update company: invalid address name.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET": "Unable to update company: invalid address.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET_2": "Unable to update company: invalid street.",
    "COMPANY_UPDATE_FAIL_INVALID_POSTAL_CODE": "Unable to update company: invalid postal code.",
    "COMPANY_UPDATE_FAIL_INVALID_CITY": "Unable to update company: invalid city name.",
    "COMPANY_UPDATE_FAIL_INVALID_COUNTRY": "Unable to update company: invalid country name.",
    "COMPANY_UPDATE_FAIL_INVALID_PHONE": "Unable to update company: invalid phone number.",
    // Register user with company
    "REGISTER_COMPANY_FAIL_RECAPTCHA_ERROR": "Invalid recaptcha.",
    "REGISTER_COMPANY_FAIL_COMPANY_NAME_ALREADY_USED": "Unable to register new user: company name already used.",
    "REGISTER_COMPANY_FAIL_USER_EMAIL_ALREADY_USED": "Unable to register new user: user email already used.",
    "REGISTER_COMPANY_FAIL_COMPANY_EMAIL_ALREADY_USED": "Unable to register new user: company email already used.",
    "REGISTER_COMPANY_FAIL_ASSIGN_USER_ERROR": "Unable to assign the new company to the new user.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_EMAIL": "Unable to register new user: invalid user email address.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_EMAIL": "Unable to register new user: invalid company email.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_NAME": "Unable to register new user: invalid company name.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_CIVILITY": "Unable to register new user: invalid user civility.",
    "REGISTER_COMPANY_FAIL_INVALID_COUNTRY": "Unable to register new user: invalid company country.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET": "Unable to register new user: invalid company street.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET_2": "Unable to register new user: invalid company street 2.",
    "REGISTER_COMPANY_FAIL_INVALID_POSTAL_CODE": "Unable to register new user: invalid company postal code.",
    "REGISTER_COMPANY_FAIL_INVALID_CITY": "Unable to register new user: invalid company city name.",
    "REGISTER_COMPANY_FAIL_INVALID_PREFERRED_LANGUAGE": "Unable to register new user: invalid preferred language.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_FIRST_NAME": "Unable to register new user: invalid first name.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_LAST_NAME": "Unable to register new user: invalid last name",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PASSWORD": "Unable to register new user: invalid password.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PHONE": "Unable to register new user: invalid user phone number",
    "REGISTER_COMPANY_FAIL_INVALID_USER_MOBILE": "Unable to register new user: invalid user mobile number",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_PHONE": "Unable to register new user: invalid company phone number.",
    "REGISTER_COMPANY_FAIL_INVALID_AFFILIATE_CODE": "Unable to register new user: invalid affiliate code.",
    "REGISTER_COMPANY_SUCCESS": "New company and user successfully registered.",
    "REGISTER_COMPANY_FAIL_INVALID_VAT_NUMBER": "Invalid VAT number.",
    "INVALID_VAT_NUMBER": "Invalid VAT number.",
    "REGISTER_COMPANY_FAIL_CHECK_VAT_NUMBER": "Error while checking.",
    "VAT_NUMBER_EMPTY": "Please enter the VAT Number.",
    // Register user to company
    "REGISTER_USER_FAIL_RECAPTCHA_ERROR": "Invalid recaptcha.",
    "REGISTER_USER_FAIL_INVALID_FIRSTNAME": "Unable to register user on company: invalid first name.",
    "REGISTER_USER_FAIL_INVALID_LASTNAME": "Unable to register user on company: invalid last name.",
    "REGISTER_USER_FAIL_INVALID_PASSWORD": "Unable to register user on company: invalid password.",
    "REGISTER_USER_FAIL_INVALID_PHONE": "Unable to register user on company: invalid phone number.",
    "REGISTER_USER_FAIL_INVALID_MOBILE": "Unable to register user on company: invalid mobile number.",
    "REGISTER_USER_FAIL_INVALID_CIVILITY": "Unable to register user on company: invalid civility.",
    "REGISTER_USER_FAIL_INVALID_PREFERRED_LANGUAGE": "Unable to register user on company: invalid preferred language.",
    "REGISTER_USER_FAIL_INVALID_EMAIL": "Unable to register user on company: invalid email.",
    "REGISTER_USER_FAIL_EMAIL_ALREADY_EXIST": "Unable to register user on company: email address already exists.",
    "REGISTER_USER_FAIL_CGV_NOT_FOUND": "Unable to register user on company: CGV not found.",
    "REGISTER_USER_FAIL_COMPANY_NOT_FOUND_OR_INVALID_TOKEN": "Unable to find company or the invitation token is invalid.",
    "REGISTER_USER_FAIL_EMAIL_REJECTED": "Unable to register user on company: email address rejected.",
    "REGISTER_USER_FAIL_CLEAR_INVITATION": "Unable to clear invitation.",
    "REGISTER_USER_FAIL_INVALID_OR_EXPIRED_LINK": "Registration link invalid or expired.",
    "REGISTER_USER_SUCCESS": "New user successfully registered.",
    // Invite user to company
    "INVITE_USER_FAIL_INVALID_EMAIL": "Unable to invite user: invalid email format.",
    "INVITE_USER_FAIL_BAD_PERMISSION": "You do not have the permission to invite a user.",
    "INVITE_USER_FAIL_ALREADY_INVITED": "The user already has a role in the company.",
    "INVITE_USER_FAIL_ALREADY_ADMIN_OR_ACCOUNTANT": "The user is already administrator or accountant of another company.",
    "INVITE_USER_FAIL_INVALID_POSITION": "Unable to invite user: invalid position.",
    // Join
    "JOIN_COMPANY_FAIL_INVALID_COMPANY_OR_TOKEN": "Unable to find company or the invitation token is invalid.",
    "JOIN_COMPANY_FAIL_EMAIL_REJECTED": "Your email address was rejected.",
    "JOIN_COMPANY_FAIL_UNABLE_TO_UPDATE_USER": "Unable to assign the user to the company.",
    "JOIN_COMPANY_FAIL_INVITATION_NOT_FOUND": "Unable to find the invitation.",
    // Revoke
    "REVOKE_USER_FAIL_BAD_PERMISSION": "Only admin can revoke a user.",
    "REVOKE_USER_FAIL_SELF_REVOCATION": "You cannot revoke yourself from a company.",
    "REVOKE_USER_FAIL_EMAIL_NOT_FOUND": "Unable to find user email to revoke",
    "REVOKE_USER_FAIL_IS_ADMIN": "Unable to revoke the owner of the company.",
    "REVOKE_USER_FAIL_WRONG_ROLE": "Unable to find requested user with role.",
    "REVOKE_USER_FAIL_ACCOUNTING_ERROR": "Unable to delete user accounting infos.",
    "REVOKE_USER_SUCCESS": "User revoked successfully.",

    // Role
    "ROLE_NOT_FOUND": "Role not found.",
    "ROLE_CREATE_FAIL_BAD_PERMISSION": "Only company administrators can create new roles.",
    "ROLE_DELETE_FAIL_BAD_PERMISSION": "Only company administrators can delete roles.",
    "ROLE_GET_FAIL_BAD_PERMISSION": "Only company administrators can retrieve role list.",
    "ROLE_UPDATE_FAIL_BAD_PERMISSION": "Only company's administrators can update roles.",
    "ROLE_DELETE_FAIL_NOT_FOUND": "Unable to delete role: role not found in company.",
    "ROLE_UPDATE_FAIL_NOT_FOUND": "Unable to update role: role not found in company.",
    "ROLE_UPDATE_FAIL_ALREADY_EXIST": "Unable to update role: role name already exists.",
    "ROLE_CREATE_FAIL_ALREADY_EXIST": "Unable to create role: role name already exists.",
    "ROLE_DELETE_FAIL_IN_USE": "Unable to delete role: role is assigned to one or more user(s).",
    "ROLE_CREATE_SUCCESS": "Role created successfully.",
    "ROLE_UPDATE_SUCCESS": "Role updated successfully.",
    "ROLE_DELETE_SUCCESS": "Role deleted successfully.",

    // CGV
    "CGV_NOT_FOUND": "Unable to find latest CGV.",
    "CGV_VALIDATE_FAIL_UPDATE_ERROR": "Unable to update user with latest CGV signature.",
    "CGV_VALIDATE_FAIL_INVALID_VERSION": "You did not signed the latest CGV version.",
    "CGV_VALIDATE_FAIL": "Pay attention that you need to agree with CGV to use our services.",
    "CGV_VALIDATE_SUCCESS_ALREADY_SIGNED": "You already signed the latest CGV.",
    "CGV_VALIDATE_SUCCESS": "Thanks for signing our CGV.",

    // Token
    "TOKEN_NOT_FOUND": "Token not found.",
    "TOKEN_CREATE_FAIL_UPDATE_USER": "Unable to create token for current user.",
    "TOKEN_CREATE_FAIL_INVALID_DATE": "Unable to create token: invalid expiration date.",
    "TOKEN_CREATE_FAIL_NAME_ALREADY_USED": "Unable to create token: name already exists.",
    "TOKEN_GET_FAIL_EMPTY_NAME": "The token name is invalid.",
    "TOKEN_REVOKE_SUCCESS": "Token revoked successfully.",

    // Plan
    "PLAN_LIST_FAIL": "Unable to retrieve list of plans.",

    // IPs
    "IP_NOT_FOUND": "IP non trouvée.",
    "IP_BLACKLISTED": "IP blacklisted.",
    "IP_DELETE_FAIL_ALREADY_ON_TERMINATION": "The deletion of this IP address is already scheduled.",
    "IP_DELETE_FAIL_ACTION_NOT_SCHEDULED": "The deletion of this IP address is not scheduled.",
    "IP_DELETE_FAIL_IPV4_DELETION_SCHEDULED": "Deletion of the corresponding IPv4 address is scheduled.",
    "IP_V6_UNAVAILABLE": "IPV6 not available.",
    "IP_V4_V6_COUPLE_NOT_FOUND": "IP_V4_V6_COUPLE_NOT_FOUND",
    // Attach/detach
    "DETACH_IP_SUCCESS": "IP successfully detached.",
    "ATTACH_IP_SUCCESS": "IP successfully attached.",
    "ATTACH_IP_FAIL_NO_MORE_AVAILABLE_IP": "Unable to attach IP: no more available IP address.",
    "ATTACH_IP_FAIL_IP_IS_NOT_IN_YOUR_COMPANY_POOL": "Unable to attach IP: the IP address is not in the company pool.",
    "ATTACH_IP_FAIL_SERVER_MANAGED": "Unable to attach IP: direct IP attachment is not compatible with managed servers, please use PNAT rules instead.",
    "DETACH_IP_FAIL_BAD_SERVER_IP": "Unable to detach IP: the IP address is not attached to the server.",
    "DETACH_IP_FAIL_IP_NOT_FOUND": "Unable to detach IP: IP address not found.",
    "DETACH_IP_FAIL_SERVER_MANAGED": "Unable to detach IP: IP detachment is not compatible with managed servers.",
    "DETACH_IP_FAIL_PRIMARY_ADDRESS": "Unable to detach IP: Primary IP address.",
    // PNAT rules add/del
    "ADD_IP_PNAT_RULE_SUCCESS": "PNAT rule added successfully.",
    "DEL_IP_PNAT_RULE_SUCCESS": "PNAT rule deleted successfully.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Unable to add PNAT rule: invalid source port.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Unable to add PNAT rule: invalid destination port.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Unable to add PNAT rule: invalid protocol.",
    "ADD_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Unable to add PNAT rule: the server is not managed.",
    "ADD_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Unable to add PNAT rule: IP address not found.",
    "ADD_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Unable to add PNAT rule: private interface not found.",
    "ADD_IP_PNAT_RULE_FAIL_TRANSPARENT_CONFLICT_PNAT": "Unable to add PNAT rule: you must first remove all existing PNAT rules on this IP before enabling transparent mode.",
    "ADD_IP_PNAT_RULE_FAIL_PNAT_CONFLICT_TRANSPARENT": "Unable to add PNAT rule: you must first disable transparent redirection on this IP before adding PNAT rules.",
    "ADD_IP_PNAT_RULE_FAIL_SRC_PORT_CONFLICT": "Unable to add PNAT rule: a PNAT rule from this IP's source port is already defined.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Unable to delete PNAT rule: invalid source port.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Unable to delete PNAT rule: invalid destination port.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Unable to delete PNAT rule: invalid protocol.",
    "DEL_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Unable to delete PNAT rule: the server is not managed.",
    "DEL_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Unable to delete PNAT rule: IP address not found.",
    "DEL_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Unable to delete PNAT rule: private interface not found.",
    "DEL_IP_PNAT_RULE_FAIL_NOT_FOUND": "Unable to delete PNAT rule: PNAT rule not found.",

    // Demo
    "DEMO_SEND_CREATE_SERVER_SUCCESS": "Create demo server request sent successfully.",

    // Managed services
    "MANAGED_SERVICES_ENABLED": "Managed services are already enabled.",
    "MANAGED_SERVICES_SUCCESS": "Managed services activation request sent successfully.",

    // Promotional code
    "PROMOTION_CODE_NOT_FOUND": "Invalid promotional code.",
    "PROMOTION_CODE_ALREADY_EXISTS": "The promotional code already exists.",
    "PROMOTION_CODE_INVALID": "Promotion code invalid, length must be between 4 and 60 characters.",

    // Items
    "ITEM_INVALID_QUANTITY": "Quantité d'item invalide.",
    "ITEM_NOT_FOUND": "Item not found",

    // Generate affiliation Links
    "GENERATE_AFF_LINKS_SUCCESS": "The affiliate link has been successfully generated. It is registered in your print media.",
    "UPDATE_AFF_LINKS_SUCCESS": "The affiliate link has been successfully updated.",

    // Email validation
    "VALIDATE_EMAIL": "An email has been sent to you to activate your account (please also check your spam).",
    "VALIDATE_EMAIL_SUCCESS": "Your email has been validated successfully.",
    "VALIDATE_EMAIL_SUCCESS_ALREADY_VALIDATED": "Your email has already been validated.",
    "VALIDATE_EMAIL_INVITE_SUCCESS": "You have accepted the invitation.",
    "VALIDATE_EMAIL_FAIL": "Unable to validate user account, please contact support.",
    "VALIDATE_EMAIL_FAIL_TOKEN_INVALID": "Invalid link, please re-submit a validation request for your email.",
    
    // Parameters
    "PARAM_SINGLE_NOT_FOUND": "SINGLE type parameter not found.",
    "PARAM_ALREADY_EXISTS": "Parameter already exists",
    
    // Transfer
    "TRANSFER_SUCCESS": "Transfer successful",
    "ERR_TRANSFER_NO_TYPE": "Transfer type not specified",
    "ERR_TRANSFER_SAME_COMPANY": "Transfer to the same company not possible.",
    "ERR_TRANSFER_DEMO_MODE_SERVER": "Transfer not possible, server in DEMO mode.",
    "ERR_TRANSFER_GETTING_SUBSCRIPTIONS": "Error when retrieving subscriptions",
    "ERR_TRANSFER_STATE_INVALID": "Transfer not possible, server deleted or status invalid",
    "ERR_TRANSFER_SAME_SUBSCRIPTION": "Server transfer to the same subscription is not possible.",
    // Stripe
    "stripe": {
      "account_closed": "Bank account closed",
      "account_country_invalid_address": "Invalid bank account country",
      "account_error_country_change_requires_additional_steps": "Changing country requires additional steps",
      "account_information_mismatch": "Some account information does not match",
      "account_invalid": "The account ID provided as a value for the Stripe-Account header is not valid",
      "account_number_invalid": "The provided bank account number is not valid",
      "acss_debit_session_incomplete": "The ACSS debit session is not ready yet",
      "alipay_upgrade_required": "This method of creating Alipay payments is no longer supported",
      "amount_too_large": "The specified amount is greater than the maximum allowed amount",
      "amount_too_small": "The specified amount is less than the minimum allowed amount",
      "api_key_expired": "The provided API key has expired",
      "application_fees_not_allowed": "Application of fees is not allowed",
      "authentication_required": "Authentication required",
      "balance_insufficient": "Insufficient balance",
      "balance_invalid_parameter": "Invalid parameter provided for balance method",
      "bank_account_bad_routing_numbers": "The bank account does not support this currency",
      "bank_account_declined": "Payment not authorized on this bank account",
      "bank_account_exists": "The bank account already exists",
      "bank_account_restricted": "The bank account does not accept this payment method",
      "bank_account_unusable": "Bank account unusable",
      "bank_account_unverified": "Bank account unverified",
      "bank_account_verification_failed": "Bank account verification failed",
      "billing_invalid_mandate": "Invalid payment mandate",
      "bitcoin_upgrade_required": "Bitcoin payment is no longer supported",
      "capture_charge_authorization_expired": "Funds cannot be withdrawn because the authorization has expired",
      "capture_unauthorized_payment": "You are not authorized to withdraw the funds",
      "card_error": "Invalid payment method",
      "card_decline_rate_limit_exceeded": "Decline rate limit exceeded",
      "card_declined": "Card declined",
      "cardholder_phone_number_required": "Phone number required for creating European cards",
      "charge_already_captured": "Funds already withdrawn",
      "charge_already_refunded": "Funds already refunded",
      "charge_disputed": "Funds already recharged",
      "charge_exceeds_source_limit": "Exceeding the limit over the period",
      "charge_expired_for_capture": "Expiration of the period to withdraw funds",
      "charge_invalid_parameter": "Invalid parameter to withdraw funds",
      "charge_not_refundable": "Funds not refundable",
      "clearing_code_unsupported": "The provided clearing code is not supported",
      "country_code_invalid": "Invalid country code",
      "country_unsupported": "Country not supported",
      "coupon_expired": "Coupon expired",
      "customer_max_payment_methods": "Maximum number of payment methods reached",
      "customer_max_subscriptions": "Maximum number of subscriptions reached",
      "debit_not_authorized": "Payment not authorized",
      "email_invalid": "Invalid email address",
      "expired_card": "Expired card",
      "insufficient_funds": "Insufficient funds",
      "intent_invalid_state": "Payment intent in an invalid state",
      "invalid_card_type": "Unsupported card type",
      "invalid_charge_amount": "Invalid charge amount",
      "payment_intent_mandate_invalid": "Invalid payment mandate",
      "payment_method_bank_account_blocked": "Bank account blocked",
      "payouts_limit_exceeded": "Daily limit exceeded",
      "payouts_not_allowed": "Payment not allowed",
      "incorrect_number": "Incorrect card number",
      "generic_decline": "Card Declined",
    },
    // Scheduled Actions
    "ERR_SCHEDULED_ACTION_INVALID_DATE": "The scheduled date is invalid.",
    "ERR_SCHEDULED_ACTION_ALREADY_EXISTS": "This action is already scheduled.",
    // Disputes
    "ERR_PAYMENT_NOT_BELONGS_TO_COMPANY": "This payment method does not belong to this company",
    "ERR_DISPUTE_NOT_FOUND": "Dispute not found",
    "ERR_DISPUTE_PAID": "Dispute already settled",
    "ERR_PAYMENT_BY_CARD_REQUIRED": "Card payment required",
    "ERR_NO_INVOICES": "No invoices to pay",
    "ERR_INCORRECT_NUMBER_OF_REFERENCES": "Incorrect number of references",
    "ERR_SOME_INVOICE_ARE_IN_DISPUTE": "At least one invoice is in dispute.",
  },
  "tools": {
    "coming_soon": "Upcoming functionality.",
    "bad_level": "Insufficient service level",
  },
}
