module.exports = {
  "or": "o",

  "affiliation": {
    "tooltip-uncomplete": "Conferma in sospeso, questa transazione sarà confermata all'inizio del mese",
    "title": "Affiliazioni",
    "threshold": "Classifica",
    "thresholds": "Classificas",
    "transactions": "Transazioni",
    "companies": "Società affiliate",
    "click-to-copy": "Clicca per copiare il link di affiliazione",
    "click-to-copy-success": "Copiato",
    "code": "Codice programma",
    "name": "Nome del programma",
    "date": "Data",
    "total": "Totale disponibile",
    "forecast-total": "Previsione totale",
    "valid-date": "È attivo dal",
    "valid-date-future": "Sarà valido il",
    "amount": "Fatturato mensile da generare",
    "percentage": "Percentuale",
    "empty-transactions": "Nessuna transazione ancora…",
    "empty-companies": "Nessuna azienda ancora…",
    "monthly-ca": "Fatturato mensile generato",
    "ca": "Fatturato generato",
    "percentage-apply": "Percentuale applicata",
    "teasing-warning": "Attualmente la tua azienda non ha un programma di affiliazione.\n" +
      "Per attivare questo programma, si prega di contattare il nostro ufficio vendite via e-mail o telefono",
    "teasing-detail": "Il nostro programma di affiliazione ti permette di liberarti dalla fatturazione dei tuoi clienti, ma anche di ricevere una remunerazione su tutti i servizi raccolti nell'ambito di questo programma",
    "tabs": {
      "info": "Informazioni",
      "earn": "I miei guadagni",
      "my-links": "I miei inviti",
      "generate-unique-link": "Generare un nuovo link"
    }
  },

  "affiliation-links": {
    "title": "I miei link affiliati",
    "new": "Creare un link personalizzato",
    "code": "Codice generale di appartenenza",
    "company": "Azienda",
    "used_by_company": "Usato dall'azienda?",
    "email": "Posta",
    "actions": "Azioni",
    "false": "No",
    "true": "Sì",
    "modal": {
      "title": "Rimuovi questo link affiliato",
      "danger": "Questa azione è irreversibile.",
      "message": "",
      "button": "Cancellare",
      "extra": "Sei sicuro?"
    }
  },

  "help": {
    "key-rename": "Rinominare la chiave",
    "key-expire-edit": "Modifica la data di scadenza della chiave",
    "ssh-rename": "Rinominare la chiave",
    "ssh-rewrite": "Cambiare la chiave",
    "network": "Collegando il tuo server al Vswitch, verrà collegata automaticamente una scheda di rete.",
    "server-password": "Troverai l'identificativo SSH nel pannello di amministrazione, nella sezione \"Maggiori informazioni\".\nPer connetterti come root, devi usare il comando \"su\" seguito dalla tua password.",
    "server-ssh": "Puoi gestire le chiavi dal menu del tuo account",
    "server-create": "-",
    "server-snapshots": "Anche se utile, lo snapshot non sostituisce il salvataggio.",
    "image-rename": "Rinomina l'immagine.",
    "image-os-rename": "Cambia il nome del sistema operativo.",
    "image-os-version-rename": "Rinomina la versione del sistema operativo.",
    "server-rename": "Rinominare il server.",
    "server-rename-reverse": "Rinominare il reverse.",
    "server-open-ssh": "Aprire un  terminale SSH.",
    "server-iso-mount": "Montare un ISO.",
    "server-iso-unmount": "Smonta un ISO.",
    "server-pin-dash": "Pin in il dashboard.",
    "account-ssh": "Le tue chiavi saranno visibili durante il processo di creazione dei server.",
    "account-api": "Disponibile nella prossima release.",
    "account-2fa": "\"L'autenticazione a due fattori aumenta la sicurezza del tuo account.",
    "user-pref": "Per personalizzare la sua esperienza SmallCloud, salva le tue preferenze di visualizzazione qui. Puoi cambiarli in qualsiasi momento.",
    "licenses": "ATTENZIONE: La scelta della vostra licenza è definitiva. Una volta che il server è stato ordinato, non sarà possibile cambiare la licenza.",
    "company-user": "Il tipo 'Contabile' consente di accedere alle fatture e alla gestione dei pagamenti.\nIl tipo 'Manager' fornisce accesso alla gestione tecnica dei server.\nIl tipo 'Amministratore' garantisce l'accesso a tutte le funzionalità.",
    "company-del-user": "Eliminare l'utente.",
    "company-send-inv": "Invia nuovamente l'invito.",
    "company-role-edit": "Modifica accesso.",
    "company-del-invitation": "Rimuovi invito.",
    "company-del-role": "Elimina accesso.",
    "show-user-vcard": "Vedere la vCard dell'utente.",
    "reinit-filter": "Reimposta tutti i filtri",
    "tickets-prio-change": "Ridefinire le priorità",
    "tickets-header-link": "Crea un nuovo ticket nel supporto.",
    "tickets-link-to-vm": "Aprire il server in una nuova scheda",
    "subscription-rename": "Rinominare il progetto.",
    "subscription-change-freq": "Modificare la frequenza.",
    "subscription-see-all": "Visualizza tutte le fatture relative a questo progetto.",
    "subscription-see-next": "Vedi la tua prossima scadenza.",
    "subscription-next-frequency": "La nuova frequenza verrà presa in considerazione al rinnovo della fattura.",
    "network-rename": "Rinomina Vswitch",
    "company-oid": "Identificatore univoco dell'azienda.",
    "ticket-indexer": "Aggiorna ticket da IMAP",
    "subscription-payment-method": "Assegna o resetta il metodo di pagamento per l'abbonamento",
  },

  "severity": {
    "0": "Controlla impostazioni",
    "1": "Informazione",
    "2": "Avvertimento",
    "3": "Mettere in guardia",
  },

  "actions": {
    "send": "Invia",
    "filter": "Filtro",
    "confirm": "Confermare",
    "cancel": "Annulla",
    "save": "Salva",
    "generate": "Genera",
    "close": "Chiudi",
    "delete": "Cancellare",
    "back": "Indietro",
    "next": "Prossimo",
    "buy": "Compra",
    "quit": "Smettere",
    "validate": "Convalida",
    "pay": "Paga",
    "enable": "Abilitare",
    "disable": "Disattivare",
    "apply": "Applicare",
    "reboot": "Riavvia ora",
    "reboot-acceptation": "Accetto di riavviare immediatamente il mio server",
    "more": "Mostra di più",
    "less": "Mostra di meno",
    "search-servers": "Trova server",
    "search-networks": "Trova reti",
    "click-to-copy": "Clicca per copiare",
    "copied": "Copiato",
    "change-avatar": "Cambia logo",
    "remove-avatar": "Cancellare l'avatar",
    "create_notification": "Crea notifica",
    "buy_space": "Acquista spazio",
    "create": "Creare",
    "update": "Aggiorna",
    "refund": "Rimborso",
    "add": "Aggiungi",
    "message-avatar": "Fai clic per modificare",
    "download": "Scarica",
    "new": "Nuovo",
    "modify": "Modifica",
    "create-list": "Creare una lista",
    "show-list": "Visualizza/Modifica la lista",
    "open-all": "Apri tutto",
    "close-all": "Chiudi tutto",
    "re-init": "Reinizializza",
    "new-f": "Nuova",
    "open-list": "Apri l'elenco",
    "close-list": "Chiudi l'elenco",
    "terminate": "Termina",
    "cancel-terminate": "Annulla la terminazione",
    "multi-pay": "Pagamento multiplo",
    "cancel-delete": "Annulla eliminazione",
    "phone-link": "Chiamata Diretta",
    "init-drp": "Accedi al PRD",
    "reset-drp": "Esci dal PRD",
  },
  "popup": {
    "coming-soon": "Coming soon",
  },
  "modal": {
    "definitive-action": "Questa operazione è definitiva",
    "cancel": "Annulla",
    "confirm": "Conferma",
    "finalize": "Finalizza l'ordine",
    "confirm-text": "Per confermare questa operazione cliccare su 'CONFERMA'.",
    "confirm-placeholder": "Clicca su CONFERMA",
    "confirmation-word": "CONFERMA",
    "server-list": {
      "attached-servers": "Server collegati",
      "not-attached-servers": "Server non collegati",
      "all": "Tutti",
      "no-server": "Nessun server",
    },
    "dialog-reset-network": {
      "title": "Ripristina interfaccia di rete",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, l'interfaccia di rete del server verrà ripristinata.",
      "extra": "Sei sicuro di voler ripristinare l'interfaccia di rete del server?",
      "message2": "Confermando questa azione, l'interfaccia di rete del server verrà ripristinata.",
      "extra2": "Sei sicuro di voler ripristinare l'interfaccia di rete di questi server?"
    },
    "dialog-reset": {
      "title": "Reinizializza il server",
      "warning": "Questa operazione è irreversibile",
      "message": "Confermando questa azione, il vostro server sarà resettato. I tuoi dati e le tue istantanee saranno cancellati in modo permanente.",
      "extra": "Vuoi davvero reinizializzare questo server?"
    },
    "dialog-terminate": {
      "title": "Disattiva il server",
      "warning": "Questa operazione è pericolosa ma reversibile",
      "message": "Confermando questa operazione, il server verrà disattivato. I tuoi dati e i tuoi snapshot verranno cancellati.",
      "extra": "Vuoi davvero disattivare questo server?",
      "button": "Disattiva"
    },
    "dialog-cancel-terminate": {
      "title": "Annulla la disattivazione del server",
      "warning": "Questa operazione è pericolosa",
      "message": "Confermando questa operazione, la disattivazione del server verrà annullata.",
      "extra": "Vuoi davvero annullare la disattivazione di questo server?",
      "button": "Annulla la disattivazione"
    },
    "dialog-snapshot-restore": {
      "title": "Ripristina lo snapshot",
      "warning": "Questa operazione è irreversibile",
      "message": "Confermando questa operazione il tuo snapshot verrà ripristinato sul server. Attenzione: questa operazione sovrascriverà i dati presenti sul disco per sostituirli con i dati contenuti nel tuo snapshot.",
      "extra": "Vuoi davvero ripristinare questo snapshot?"
    },
    "dialog-snapshot-delete": {
      "title": "Cancella lo snapshot",
      "warning": "Questa operazione è irreversibile",
      "message": "Confermando l'operazione questo snapshot verrà cancellato.",
      "extra": "Vuoi davvero cancellare questo snapshot?"
    },
    "dialog-ip-detach": {
      "title": "Rimuovi un IP",
      "warning": "Questa operazione è irreversibile",
      "message": "Confermando l'operazione questo IP verrà rimosso dal server.",
      "extra": "Vuoi davvero rimuovere questo IP?"
    },
    "dialog-payment-method-remove": {
      "title": "Cancella questo metodo di pagamento.",
      "warning": "Questa operazione è importante.",
      "message": "Confermando l'operazione questo metodo di pagamento verrà cancellato.",
      "extra": "Vuoi davvero cancellare questo metodo di pagamento?",
      "extra2": "Le sottoscrizioni che utilizzano questo metodo di pagamento verranno ripristinate al metodo di pagamento predefinito.",
    },
    "dialog-order-cancel": {
      "title": "Annulla quest'ordine",
      "warning": "Questa operazione è importante.",
      "message": "Confermando l'operazione, quest'ordine verrà annullato",
      "extra": "Vuoi davvero annullare l'ordine?"
    }
    , "dialog-changing-frequency": {
      "title": "Vuoi davvero cambiare la cadenza di fatturazione?",
      "warning": "La modifica sarà effettiva dalla tua prossima fatturazione",
      "message": "La nuova cadenza di fatturazione è: ",
    },
    "dialog-changing-project-name": {
      "title": "Vuoi davvero cambiare il nome del progetto?",
      "warning": "La modifica del nome sulle fatture sarà effettiva dalla tua prossima fatturazione",
      "message": "Il tuo nuovo nome del progetto sarà: ",
    },
    "dialog-ConfirmRoleChange": {
      "title": "Vuoi davvero cambiare il ruolo di ",
      "warning": "Questa operazione è importante.",
      "message": "Il tuo nuovo ruolo sarà: ",
    },
    "dialog-user-delete": {
      "title": "Cancella questo utente",
      "warning": "Questa operazione è definitiva",
      "message": "Confermando l'operazione questo utente verrà cancellato.",
      "extra": "Vuoi davvero cancellare quest'utente?"
    },
    "dialog-invitation-delete": {
      "title": "Cancella questo invito",
      "warning": "Questa operazione è definitiva",
      "message": "Confermando l'operazione questo invito verrà cancellato.",
      "extra": "Vuoi davvero cancellare quest'utente?"
    },
    "dialog-ticket-level-change": {
      "title": "Cambiare il livello di priorità del tuo ticket",
      "warning": "Questa operazione è  importante",
      "message": "Confermando l'operazione il tuo ticket cambierà priorità.",
      "extra": "Vuoi davvero cambiare la priorità del tuo ticket?"
    },
    "dialog-company-update": {
      "title": "Cambiare le informazioni dell'azienda",
      "success": "La modifica delle informazioni sulle fatture sarà effettiva dalla tua prossima fatturazione",
      "message": "Confermando l'operazione le informazione dell'azienda saranno modificati.",
      "extra": "Vuoi davvero modificare questa informazione?",
    },
    "dialog-payment-cancel": {
      "title": "Annullamento di un pagamento in attesa.",
      "warning": "Questa azione è definitiva.",
      "message": "Confermando l'operazione il suo pagamento sarà annullato.",
      "button": "Confermare"
    },
    "dialog-payment-refund": {
      "title": "Rimborso di questo pagamento",
      "warning": "Questa azione è definitiva",
      "message": "Confermando questa azione, effettuerai questo rimborso",
      "button": "Conferma"
    },
    "dialog-delete-image": {
      "title": "Cancellare l'immagine.",
      "warning": "Questa azione è definitiva",
      "message": "Confermando questa azione, la tua immagine sarà definitivamente cancellata.",
      "button": "Cancellare"
    },
    "dialog-role-delete": {
      "title": "Eliminazione del ruolo",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, il tuo ruolo verrà eliminato.",
      "extra": "Sei sicuro di voler eliminare questo ruolo?"
    },
    "dialog-ticket-creation": {
      "title": "Creazione biglietto",
      "success": "Il nuovo ticket verrà creato dopo la conferma",
      "message": "Confermando questa azione, verrà creato il nuovo ticket.",
      "button": "Conferma"
    },
    "dialog-ticket-close": {
      "title": "Biglietto di chiusura",
      "title2": "Chiusura del ticket senza risposta",
      "warning": "Il ticket verrà chiuso dopo la conferma",
      "message": "La conferma di questa azione chiuderà il ticket.",
      "button": "Conferma"
    },
    "dialog-item-pricing-update": {
      "title": "Aggiunto nuovo prezzo",
      "warning": "Questa azione è importante",
      "message": "Stai modificando l'articolo {item} - Nuovo prezzo: {price}",
      "button": "Crea",
      "extra": "Sei sicuro?"
    },
    "dialog-item-pricing-remove": {
      "title": "Eliminazione di un nuovo prezzo",
      "warning": "Questa azione è irreversibile",
      "message": "Stai eliminando l'articolo aggiornato {item} - Il nuovo prezzo era: {price}",
      "button": "Elimina",
      "extra": "Sei sicuro?"
    },
    "dialog-item-creation": {
      "title": "Creazione di un articolo",
      "warning": "Questa azione è importante",
      "message": "Stai creando un elemento {item} di tipo: {type}",
      "button": "Crea",
      "extra": "Sei sicuro?"
    },
    "dialog-item-update": {
      "title": "Aggiornamento di un articolo",
      "warning": "Questa azione è importante",
      "message": "Stai aggiornando l'elemento {item} di tipo: {type}",
      "button": "Aggiorna",
      "extra": "Sei sicuro?"
    },
    "dialog-item-account-remove": {
      "title": "Eliminazione di un nuovo codice account",
      "warning": "Questa azione è irreversibile",
      "message": "Stai eliminando l'aggiornamento dell'elemento {item} - Il nuovo codice account era: {code}",
      "button": "Elimina",
      "extra": "Sei sicuro?"
    },
    "dialog-item-account-update": {
      "title": "Aggiunto un nuovo codice account",
      "warning": "Questa azione è importante",
      "message": "Stai modificando l'elemento {item} - Nuovo codice account: {code}",
      "button": "Crea",
      "extra": "Sei sicuro?"
    },
    "dialog-multi-pay-confir": {
      "title": "Pagamento multiplo",
      "warning": "Questa azione registrerà un pagamento",
      "message": "Convalidi la creazione di questo pagamento?",
      "button": "Convalida",
    },
    "dialog-confirm-hyperV-migration": {
      "title": "Inizierai una migrazione",
      "warning": "Questa azione è importante",
    },
    "dialog-confirm-hyperV-change-state": {
      "title": "Cambia stato hypervisor - { hostname } -",
      "danger": "Disabiliterai questo hypervisor",
      "warning": "Stai per attivare questo hypervisor",
      "message": "Sei sicuro di voler cambiare lo stato dell'hypervisor - { hostname } -?"
    },
    "dialog-confirm-hyperV-change-state-all": {
      "title": "Modifica dello stato di tutti gli ipervisori",
      "danger": "Stai per cambiare lo stato di tutti gli ipervisori",
      "message": "Sei sicuro di voler procedere con questa operazione?",
    },
    "dialog-quote-delete": {
      "title": "Elimina citazione",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, questa citazione verrà eliminata",
      "extra": "Sei sicuro di voler eliminare questa citazione?"
    },
    "dialog-quote-accept": {
      "title": "Accetta un preventivo",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, questa citazione sarà accettata",
      "extra": "Sei sicuro di voler accettare questa citazione?"
    },
    "dialog-notification-delete": {
      "title": "Elimina notifica",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, questa notifica verrà eliminata",
      "extra": "Sei sicuro di voler eliminare questa notifica?"
    },
    "dialog-vat-update": {
      "title": "Aggiungerai l'IVA al paese {country}.",
      "warning": "Questa azione è importante",
      "message": "La nuova IVA per {country} sarà {percentage}% in {date}",
      "button": "Aggiorna",
      "extra": "Sei sicuro?"
    },
    "dialog-vat-remove": {
      "title": "Rimuoverai l'IVA dal paese {country}.",
      "warning": "Questa azione è importante",
      "message": "L'IVA di {percentage}% in {date} in {country} verrà rimossa.",
      "button": "Aggiorna",
      "extra": "Sei sicuro?"
    },
    "dialog-start-demo": {
      "title": "Avvia demo",
      "warning": "Questa azione distribuirà un server non pagato",
      "message": "Confermando questa azione, il server verrà distribuito senza pagamento da parte del client.",
      "button": "Avvia",
      "extra": "Sei sicuro di voler abilitare la modalità demo?"
    },
    "dialog-stop-demo": {
      "title": "Interrompi demo",
      "warning": "Questa azione distribuirà un server non pagato",
      "message": "Confermando, il server verrà disabilitato fino a quando il cliente non pagherà. Puoi comunque attivare la modalità demo.",
      "button": "Ferma",
      "extra": "Sei sicuro di voler disabilitare la modalità demo?"
    },
    "dialog-enable-server": {
      "title": "Abilita server",
      "success": "Questa azione attiverà il server",
      "message": "Confermando questa azione, il server verrà attivato anche se il cliente non ha pagato la sua fattura. Puoi sempre disattivarlo",
      "button": "Abilita",
      "extra": "Sei sicuro di voler attivare il server?"
    },
    "dialog-disable-server": {
      "title": "Disattiva server",
      "danger": "Questa azione disabiliterà il server",
      "message": "Confermando questa azione, tutte le comunicazioni con il server saranno disabilitate. Puoi sempre riabilitarla",
      "button": "Disabilita",
      "extra": "Sei sicuro di voler disabilitare il server?"
    },
    "dialog-commercial-delete": {
      "title": "Rimuovi vendite",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, questa pubblicità verrà eliminata",
      "extra": "Sei sicuro di voler eliminare questa pubblicità?"
    },
    "dialog-alert-delete": {
      "title": "Eliminare un'attività",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, quest'attività verrà eliminata",
      "extra": "Sei sicuro di voler eliminare quest'attività?",
    },
    "dialog-line-delete": {
      "title": "Elimina una riga",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, questa riga verrà eliminata",
      "extra": "Sei sicuro di voler eliminare questa riga?"
    },
    "dialog-changing-outsourcing": {
      "title": "Modifica esternalizzazione",
      "warning": "Sei sicuro di voler cambiare il livello di outsourcing?",
      "message": "Confermando questa azione, il livello di outsourcing verrà modificato",
    },
    "dialog-delete-server": {
      "title": "Elimina immediatamente il server",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, il server verrà eliminato.",
      "extra": "Sei sicuro di voler eliminare questo server?"
    },
    "dialog-invoice-ip": {
      "title": "Accordo prima della cancellazione",
      "warning": "L'antico indirizzo IP principale di questo server verrà fatturato",
      "message": "Confermando questa azione, verrà fatturato l'antico indirizzo IP principale del server.",
      "extra": "Sei sicuro di voler continuare?"
    },
    "dialog-cancel-terminate-ip": {
      "title": "Annulla la terminazione dell'indirizzo IP",
      "warning": "Questa azione è reversibile",
      "message": "Confermando questa azione, verrà annullata la terminazione dell'IP.",
      "extra": "Sei sicuro di voler annullare la terminazione di questa IP?",
      "button": "Annulla terminazione"
    },
    "dialog-changing-payment-state": {
      "title": "Sei sicuro di voler cambiare lo stato di pagamento?",
      "warning": "La modifica avrà effetto immediato.",
      "message": "Il nuovo stato di pagamento è:",
    },
    "dialog-subscription-update": {
      "title": "Aggiorna abbonamento",
      "warning": "Se hai rimosso degli articoli, saranno persi.",
      "message": "Confermando questa azione, l'abbonamento verrà modificato.",
      "extra": "Sei sicuro di voler modificare questo abbonamento?",
    },
    "dialog-add-ipv6": {
      "title": "Aggiunta di IPV6 al server",
      "success": "L'indirizzo IPV6 verrà aggiunto dopo la conferma",
      "message": "Puoi sempre rimuoverlo successivamente dal tuo pool di indirizzi",
      "button": "Conferma",
    },
    "dialog-ticket-trash-delete": {
      "title": "Elimina un ticket 'Cestino'",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, il ticket verrà eliminato (non verrà rimosso dalla casella di posta elettronica).",
      "extra": "Sei sicuro di voler eliminare questo ticket?",
    },
    "dialog-ticket-trash-delete-many": {
      "title": "Elimina più ticket 'Cestino'",
      "danger": "Questa azione è irreversibile",
      "message": "Confermando questa azione, i ticket selezionati verranno eliminati (non verranno rimossi dalla casella di posta elettronica).",
      "extra": "Sei sicuro di voler eliminare i ticket selezionati?",
    },
    "dialog-apply-sub-payment-method": {
      "title": "Sei sicuro di voler utilizzare questo metodo di pagamento?",
      "warning": "La modifica avrà effetto nel prossimo ciclo di fatturazione",
      "message": "Metodo di pagamento da utilizzare: {payment_method}",
    },
    "dialog-remove-sub-payment-method": {
      "title": "Sei sicuro di voler tornare al metodo di pagamento predefinito?",
      "warning": "La modifica avrà effetto nel prossimo ciclo di fatturazione",
      "message": "Confermando questa azione, il metodo di pagamento verrà ripristinato",
    },
    "dialog-order-delete": {
      "title": "Elimina l'Ordine",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, l'ordine sarà cancellato.",
      "extra": "Sei sicuro di voler eliminare questo ordine?",
    },
    "dialog-reset-2fa": {
      "title": "Disattivare la 2FA dell'utente",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, la 2FA dell'utente sarà disattivata.",
      "extra": "Sei sicuro di voler disattivare la 2FA dell'utente?",
    },
    "dialog-deactivate-drp": {
      "title": "Disattivare il P.R.A.",
      "warning": "Questa azione è irreversibile",
      "message": "Confermando questa azione, il P.R.A. del tuo server sarà disattivato. L'abbonamento corrispondente sarà eliminato.",
      "extra": "Sei sicuro di voler disattivare il P.R.A. su questo server?"
    },
    "scheduled-date": {
      "title-schedule-reboot": "Programma un riavvio",
      "title-snapshot": "Programmare uno Snapshot",
      "already-scheduled": "Data già programmata | Date già programmate",
      "new-schedule": "Nuova programmazione",
      "validation-error": "L'orario deve essere almeno 5 minuti nel futuro",
      "dialog-delete-scheduled-date": {
        "title": "Elimina programmazione",
        "warning": "La modifica sarà applicata dopo la conferma",
        "message": "Confermando questa azione, la programmazione sarà cancellata.",
        "extra": "Sei sicuro di voler eliminare questa programmazione?",
      },
    },
    "project-selection": {
      "title": "Elenco progetti",
      "placeholder": "Nome del progetto",
      "name": "Nome del progetto",
      "no-project": "Nessun progetto",
      "number-of-projects": "Nessun progetto | 1 progetto | {nb} progetti",
    },
    "server-selection": {
      "title": "Elenco server",
      "number-of-servers": "Nessun server | 1 server | {nb} server",
    },
  },

  "ip": {
    "type": "V",
    "reverse": "Reverse",
    "attach": "Inserisci degli IP",
    "detach": "Rimuovi un IP",
    "buy": "Compra degli IP",
  },

  "cart": {
    "apply-all": "Applicare per tutti i server",
    "dialog-update-all-pass": {
      "title": "Copia dell'autenticazione ai server",
      "info": "",
      "message": "Vuoi applicare questa password e/o le chiavi SSH a tutti i server del carrello?",
      "extra": "Sei sicuro?"
    },
    "quote": {
      "title": "Creare una citazione",
      "create": "Generare una citazione",
      "validity_period": {
        "text": "Periodo di validità",
        "periode": "Mese"
      },
      "payment_methode": "Metodo di pagamento",
      "payment_type": {
        'PSEPA': "Addebito diretto",
        'card': "CB",
      },
      "modal": {
        "title": "Aggiungi preventivo al carrello",
        "message": "Hai la possibilità di sovrascrivere il tuo carrello attuale, unirli o annullare l'azione corrente",
        "merge": "fusione",
        "erase": "sovrascrivere",
        "cancel": "Annulla"
      },
    },
    "debug": {
      "validate": "Convalida ordine",
    },
    "no-authentication": "Nessun mezzo di autenticazione",
    "update": "Fai domanda per questo server",
    "title": "Il tuo carrello",
    "empty": "Non ci sono ordini nel carrello...",
    "summary": "Riepilogo del tuo ordine:",
    "quantity": "Quantità",
    "add": "Aggiungi al carrello",
    "continue": "Continua l'ordine",
    "no_modal": "Non visualizzare più questo messaggio",
    "pay": "Paga",
    "payment": "Pagamento",
    "order": "Ordina un server",
    "order-add": "Add a server",
    "removed": "Riga eliminata",
    "updated": "Ordine aggiornato",
    "success": "Ordine effettuato correttamente",
    "selected_ssh_keys": "Chiavi SSH selezionate:",
    "password_use": "Password utilizzata:",
    "project_name": "Nome del nuovo progetto",
    "project_name_create": "Scegli o crea un progetto",
    "project_name_input_placeholder": "Digita il nome del progetto",
    "project_name_feedback": "Tra i 4 e i 60 caratteri",
    "project_name_tip": "Scegli un progetto esistente o crealo, altrimenti verrà generato automaticamente.",
    "select_project_name": "Nome del progetto correlato: ",
    "project_name_placeholder": "Nome del progetto",
    "project_name_selector": "Selezione nome del progetto",
    "error_redirect": "Verrai reindirizzato alla pagina Documenti dove potrai concludere il pagamento.",
    "license": "Inclusa licenza",
    "update-credential": "Credenziali aggiornate.",
    "project": "Progetto",
    "frequency": "Frequenza",
    "unit_amount": "Importo unitario",
    "monthly_unit_amount": "Importo unitario mensile",
    "first_payment": "Prima rata",
    "period": "Periodo da {data1} a {data2}",
    "discount": "Sconti",
    "monthly": "Importo mensile",
    "quarterly": "Importo trimestrale",
    "biannually": "Importo semestrale",
    "annually": "Importo annuo",
    "added_to_cart": "Aggiunto al carrello",
    "item_to_cart": "Articolo(i) aggiunto(i) al carrello",
    "cart_updated": "Carrello Aggiornato",
    "server_already_in_cart": "Server già nel carrello",
    "server_with_drp": "Server con DRP già attivato",
    "server_with_outsourcing": "Server già con gestione esternalizzata",
    "server": "Server",
    "label1": "Azienda",
    "label2": "Commerciale",
    "vswitch": "VSwitch",
    "amount": "Importo:",
    "mandatory": "Campo obbligatorio",
    "button_paid": [
      "Seleziona un metodo di pagamento",
    ],
    "item_type": {
      "ip": "Indirizzo IP",
      "drp": "DRP",
      "outsourcing": "Gestione esternalizzata",
      "vswitch": "VSwitch",
      "vswitch_port": "Porta VSwitch",
      "vswitch_qos": "QoS VSwitch",
      "license": "Licenza",
    },
    "project-modal": {
      "title": "Scegli il Nome del Progetto",
      "comment": [
        "Tutti gli elementi dello stesso progetto verranno raggruppati in una singola fattura.",
      ],
      "header": [
        "Lista dei Progetti Esistenti",
        "Nuovo Progetto",
      ],
      "divided": "O",
    },
    "outsourcing": {
      "level": "Livello di Servizio",
      "standard": "Standard",
      "advanced": "Avanzato",
      "full": "Completo",
    },
    "over-quota-text": "# ⚠️ Importante: Superamento Quantità Risorse\n" +
      "\n" +
      "**{name}**\n" +
      "\n" +
      "Vogliamo attirare la sua attenzione su una questione importante riguardante il suo contratto quadro riferimento: **{reference}** in data **{date}**.\n" +
      "\n" +
      "Sembra che le quantità di risorse richieste superino il quota del suo contratto riferimento {reference}. Di conseguenza, non possiamo assegnare automaticamente le risorse richieste immediatamente.\n" +
      "\n" +
      "📞 La invitiamo a contattare il nostro reparto commerciale il prima possibile per discutere possibili soluzioni e concordare le azioni da intraprendere per regolarizzare questa situazione. In caso di emergenza, può comporre il numero +33 9 88 99 8888. Il nostro team di reperibilità sarà in grado di assistervi. In parallelo, stiamo informando il suo rappresentante commerciale.\n" +
      "\n" +
      "🙏 La ringraziamo sinceramente per la sua comprensione e preziosa collaborazione. Restiamo a sua completa disposizione per qualsiasi domanda o chiarimento.\n" +
      "\n" +
      "Con i nostri migliori saluti,\n" +
      "\n" +
      "*Il team di SmallCloud*\n",
  },

  "user": {
    "name": "Nome",
    "title": "Titolo",
    "phone": "Telefono",
    "mobile": "Cellulare",
    "email": "E-mail",
    "authorization": "Tipo",
    "role": "Accesso",
    "roles": {
      "SUPER_ADMINISTRATOR": "Super Amministratore",
      "ADMINISTRATOR": "Amministratore",
      "ACCOUNTANT": "Contabile",
      "MANAGER": "Manager",
      "TITAN_SUPER_ADMINISTRATOR": "TITAN - Super Amministratore",
      "TITAN_ADMINISTRATOR": "TITAN - Amministratore",
      "TITAN_ACCOUNTANT": "TITAN - Contabile",
      "TITAN_COMMERCIAL": "TITAN - Spot",
      "TITAN_MANAGER": "TITAN - Manager",
      "UNKNOWN": "Sconosciuto",
      "undefined": "Undefined",
      "none": "Tutti",
    }
  },

  "role": {
    "name": "Nome del accesso",
    "allowed-servers": "Server consentiti",
    "allowed-networks": "Reti consentite",
    "authorization": "Tipo",
    "role": "Accesso",
    "type": "Genere",
    "servers": "Server",
    "networks": "Reti",
    "custom-roles": "Accesso",
    "add": "Aggiungi un accesso",
    "number_of_users": "Utenti",
    "number_of_invites": "Inviti",
  },

  "tickets": {
    "modal": {
      "title": "Conferma del messaggio"
    },
    "tickets": "Ticket",
    "tickets-empty": "Non hai ancora ticket.",
    "open-ticket": "Apri un ticket",
    "status": {
      "processing": "In corso",
      "in_progress": "In corso",
      "closed": "Chiuso",
      "assigned": "Assegnato",
      "pending": "In attesa",
      "pending_admin": "In attesa",
      "pending_client": "In corso",
      "new_pending_admin": "In attesa",
      "new_pending_client": "In corso",
      "new": "Nuovo",
    },
    "attachments": {
      "received": {
        "title": "Elenco dei file",
        "header": [
          "Nome",
          "Genere",
        ],
        "popup": "Visualizza allegato | Visualizza allegati",
        "error_message": "Errore durante la lettura",
      },
      "sent": {
        "title": "Documenti da aggiungere",
        "popup": "Aggiungi documenti",
        "text": [
          "Trascina qui i tuoi file",
          "o",
          "clicca per selezionarli",
        ],
        "placeholder": "Nome file",
      },
      "content_type_error": "Tipo di file non autorizzato",
    },
    "message": {
      "tooltip": [
        "Mostra di più",
        "Mostra di meno"
      ]
    },
    "trash": {
      "message": [
        "Eliminazione riuscita",
        "Errore durante l'eliminazione",
      ],
    },
  },
  "invoices": {
    "invoice": "Fattura",
    "order_title": "Ordine",
    "credit_title": "Credito",
    "next_invoice_title": "Prossima fattura:",
    "download-pdf": "Scarica il PDF",
    "download-multi-disabled": "Seleziona le fatture da scaricare",
    "download-multi": "Scarica le fatture selezionate",
    "payment-multi-disabled": "Seleziona le fatture non ancora pagate",
    "payment-multi": "Paga le fatture selezionate",
    "payment-multi-unpaid": "Seleziona solo le fatture non pagate.",
    "delete-order": "Elimina l'ordine",
    "delete-success": "Cancellazione riuscita",
    "recurring": {
      "states": {
        "pending": "In attesa",
        "ongoing": "In corso",
        "canceled": "Disattivato",
      }
    },
    "states": {
      "all": "Tutto",
      "upcoming": "Prossime",
      "ribbon_pending": "Pending",
      "pending": "Pending",
      "ribbon_unpaid": "Non pagata",
      "unpaid": "Aperta",
      "ribbon_paid": "Pagata",
      "paid": "Pagata",
      "ribbon_canceled": "Annullata",
      "canceled": "Annullata",
      "available": "A disposizione",
      "spent": "Speso",
      "expired": "Scaduto",
    },
    "types": {
      "order": "Ordine",
      "invoice": "Fattura",
      "credit": "Credito"
    }
  },

  "kvm": {
    "title": "Accesso remoto",
    "p1": "Il tuo accesso remoto è in fase di creazione.",
    "p2": "Ci vorrà qualche istante, grazie per l'attesa.",
    "cancel": "Annulla",
    "start": "Accesso remoto",
    "stop": "Interrompi l'accesso remoto",
    "fullscreen": "Modalità schermo intero",
    "fullscreen_leave": "Esci dalla modalità a schermo intero",
    "expand": "Ingrandire",
    "compress": "Ridurre",
    "pip": "Passa alla modalità PIP"
  },

  "menu": {
    "upgrade": {
      "text": "È disponibile una nuova versione dell'applicazione: ",
      "button": "Ricarica la pagina",
      "later": "In seguito",
    },
    "faq": "FAQ",
    "accounts": "Account",
    "affiliations": "Affiliazioni",
    "menu": "Menù",
    "dashboard": "Pannello di controllo",
    "ip-histo": "Cronologia IP",
    "vms-sidebar": "Server / Immagini",
    "vms": "Server",
    "image": "Immagini",
    "container": "contenitore",
    "dedicated-serveur": "Server dedicati",
    "vms-add": "Ordinazione di server",
    "companies": "Azienda",
    "contacts": "Contatti",
    "network": "Reti",
    "templates": "Templates",
    "company": "Azienda",
    "tickets": "Ticket",
    "recurring": "Abbonamenti",
    "documents": "Documenti",
    "settings": "Parametri",
    "logout": "Logout",
    "lang": "lingua",
    "dark-mode-header": "Dark mode",
    "dark-mode": "Dark",
    "light-mode": "Light",
    "notifications": "Notifiche dei server",
    "intervention": "Altri oggetti",
    "stop_impersonate": "Torna all'utente normale",
    "tools":"Strumenti",
    "cron": "Attività pianificate",
    "preference": "Preferenze",
    "scheduled-action": "Azioni Pianificate",
    "drp-location": "Localizzazioni D.R.P.",
  },

  "filters": {
    "search": "Cerca.",
    "search-server": "Cerca un server, OS, IP, MAC...",
    "reset": "Reinizializza",
    "os": "Sistema operativo",
    "all": "Tutti",
    "service": "Servizio",
    "level": "Priorità",
    "project": "Titolo del progetto",
    "periodicity": "Periodicità",
    "affiliationLinks-per-page": "Numero di link per pagina : ",
    "servers-per-page": "Numero di server per pagina : ",
    "invoices-credits-per-page": "Numero di fatture e note di credito per pagina: ",
    "invoices-orders-per-page": "Numero di ordini e fatture per pagina:",
    "recurring-per-page": "Numero di abbonamenti per pagina: ",
    "images-per-page": "Numero di immagini per pagina :",
    "tickets-per-page": "Numero di ticket per pagina:",
    "payments-per-page": "Numero di regolamenti per pagina: ",
    "clients-per-page": "Numero di clienti per pagina: ",
    "orders-per-page": "Numero di ordini di acquisto per pagina: ",
    "ipsHisto-per-page": "Numero di ip per pagina : ",
    "item-per-page": "Items per pagina : ",
    "discount-per-page": "Discount per pagina : ",
    "country-per-page": "Countries per pagina : ",
    "events-per-page": "Eventi per pagina : ",
    "elements-per-page": "Elementi per pagina : ",
    "notifications-per-page": "Notifiche per pagina : ",
    "invoices-quote-per-page": "Citazioni per pagina : ",
    "ip-per-page": "IP per pagina:",
    "license-per-page": "Numero di licenze per pagina:",
    "line-per-page": "Numero di righe per pagina:",
    "invoice_types": {
      "invoice": "Fattura",
      "order": "Ordine",
      "credit": "Credito"
    },
    "status": {
      "started": "Avviato",
      "stopped": "Interrotto",
      "pending": "In corso",
      "waiting": "In attesa",
      "new": "Nuovo",
      "closed": "Chiuso",
      "deleted": "Cancellato"
    },
    "ticket": {
      "processing": "In corso",
      "in_progress": "In corso",
      "closed": "Chiuso",
      "assigned": "Assegnato",
      "pending": "In corso",
      "pending_admin": "In attesa",
      "pending_client": "In corso",
      "new": "Nuovo",
      "new_pending_admin": "Nuovo",
      "new_pending_client": "Nuovo",
      "number": "Numero biglietto",
      "object": "Oggetto",
      "company": "Azienda",
      "read": "Letto",
      "unread": "Non letto",
      "admin": {
        "pending_admin": "Da trattare",
        "pending_client": "Trattato",
        "closed": "Chiuso",
      },
      "client": {
        "pending_admin": "Trattato",
        "pending_client": "Da trattare",
        "closed": "Chiuso",
      },
    },
  },

  "ticket": {
    "server": "Server | Server",
    "status": "Risposto",
    "number": "N.",
    "user": "Creatore",
    "object": "Oggetto",
    "service": "Servizio",
    "product": "Prodotto",
    "created_at": "Data creazione",
    "messages": "Messaggi",
    "company": "Azienda",
    "lock": "Blocco",
    "unlock": "Sbloccare",
    "company_info": "Informazioni sul cliente",
    "last_reboot": "Ultimo riavvio",
    "indexer": "Reindicizza",
    "state_date": "Ultimo stato",
    "outsourcing_level": "Livello di servizio",
    "level": "Priorità",
    "sort-by": "Ordina per",
    "sort-button-tip-apply": "Applica ordinamento",
    "sort-button-tip-cancel": "Annulla",
    "state-date": "Ultimo stato",
    "ticket-new": "Nuovo Ticket",
    "ticket-response": "Ticket",
    "read": "Letto",
    "toggle-unread": "Contrassegna il ticket come non letto",
    "toggle-read": "Contrassegna il ticket come letto",
    "reply-sent": "Risposta inviata",
  },
  "admin": {
    "client": {
      "actif": {
        "true": "Non attivo",
        "false": "Attivo"
      },
      "filter": "Filtro cliente",
    },
    "discount": {
      "actif": {
        "true": "Attivo",
        "false": "Non attivo"
      },
      "type": {
        "FIX": "Fisso",
        "PERCENTAGE": "Percentuale"
      },
    },
    "item": {
      "title": "Articoli",
      "button": "Nuovo",
      "sort": {
        "sort1": {
          "title": "Idoneo agli sconti",
          "state": {
            "true": "Attivo",
            "false": "Nno attivo"
          },
        },
        "sort2": {
          "title": "Abbonamento idoneo",
          "state": {
            "true": "Sì",
            "false": "No"
          },
        },
        "sort3": {
          "title": "Attivo",
          "state": {
            "true": "Sì",
            "false": "No"
          },
        },
      },
      "header": {
        "name": "Nome",
        "plan": "Piano",
        "category": "Categoria",
        "enabled": "Attivo",
        "type": "Typo",
        "description": "Descrizione",
        "price": "Prezzo attuale IVA esclusa",
        "discount_enabled": "Sconti",
        "status": "Stato",
        "subtype": "Sottotipo",
        "unit": "Unità",
      },
      "type": {
        "CPU": "CPU",
        "DISK": "Disk",
        "RAM": "RAM",
        "HARDWARE": "Hardware",
        "SERVICE": "Service",
        "LICENSE": "License",
        "QOS": "QOS",
        "MAC": "MAC",
        "IP": "IP",
        "SNAPSHOT": "Snapshot",
        "STORAGE": "Pool Storage",
        "CREDIT": "Credito",
        "REBOOT": "Riavvio server",
        "SMS": "SMS",
        "VSWITCH": "VSwitch",
        "rent": "Affitto",
        "buy": "comprare"
      },
      "creation": {
        "creation_title": "Creazione di un nuovo articolo",
        "update_title": "Aggiornamento di un articolo",
        "modal": {
          "label1": "Nome",
          "placeholder1": "",
          "label2": "Descrizione",
          "placeholder2": "Descrizione",
          "label3": "Tipo di elemento",
          "placeholder3": "",
          "label4": "Metodo di fatturazione",
          "placeholder4": "",
          "label5": "Sconto",
          "placeholder5": "",
          "label6": "Attivo",
          "placeholder6": "",
          "label7": "Prezzo IVA esclusa",
          "placeholder7": "Prezzo IVA esclusa",
          "label8": "Codice contabile",
          "placeholder8": "Codice contabile",
          "label9": "Codice Plesk",
          "placeholder9": "",
          "label10": "Sottotipo",
          "label11": "Numero di crediti",
          "edit_price": {
            "label1": "Prezzo IVA esclusa",
            "placeholder1": "Prezzo IVA esclusa",
            "label2": "Applica data",
            "placeholder2": "Applica data",
          },
          "edit_code": {
            "label1": "Codice contabile",
            "placeholder1": "Codice contabile",
            "label2": "Applica data",
            "placeholder2": "Applica data",
          },
          "label12": "Unità",
          "edit_unit": {
            "label1": "Valore",
            "label2": "Tipo",
          },
        },
        "message": {
          "create_success": "Creazione riuscita",
          "update_success": "Aggiornamento riuscito",
          "remove_success": "Eliminazione riuscita",
        },
      },
      "add_item": {
        "title": "Aggiungi un elemento a un abbonamento",
        "header1": "Articolo",
        "header2": "Numero di serie",
        "header3": "OID server",
        "header4": "Qtà",
        "checkbox1": "Crea fattura parziale",
        "checkbox2": "L'oggetto è distribuito",
        "start_date": "Data inizio fattura",
      },
      "manage_item": {
        "title": "Gestione degli elementi dell'abbonamento",
        "title-list": "Elenco degli elementi dell'abbonamento",
        "delete-item": "Elimina elemento",
        "delete-all": "Elimina abbonamento"
      },
    },
    "accounting": {
      "tabs": {
        "title": {
          "invoices": "Documenti",
          "taxes": "Tasse",
          "payment": "Regolamento",
          "orderForm": "Buono d’ordine",
          "csv": "Esportazione CSV",
          "dispute": "Controversie",
          "statistics": "Statistiche",
        },
      },
      "menu": [
        "Clienti",
        "Abbonamenti",
        "Documenti",
        "Abbonamenti",
        "Elementi",
        "Buoni",
        "Le tasse",
        "Periodo di pagamento",
        "Compiti giornalieri",
        "Esternalizzazione",
        "Miscellaneo",
        "Licenze",
      ],
    },
    "company": {
      "contact": {
        "title": "Contatti aziendali",
        "user": "Utente",
        "email": "Indirizzo e-mail",
        "role": "Ruolo",
        "phone": "Telefono",
        "role_from": "Stai per cambiare il ruolo di {name}",
        "role_to": "Il suo nuovo ruolo sarà: {name}",
        "tooltip": [
          "Visualizza come questo utente",
          "Genera una vCard",
          "Informazioni dettagliate",
        ],
      },
      "tabs": [
        "Clienti",
        "Preventivo",
        "Commerciale",
      ],
      "tab_title": "Pubblicità",
      "modal": {
        "title": "Assegna un venditore",
      },
      "popup": [
        "Modifica in corso",
        "Clicca per modificare",
      ],
      "detail": {
        "tab": [
          "Informazioni",
          "I.V.A",
          "Contatti",
          "Commerciale",
          "Abbonamenti",
          "Quote e limitazioni",
          "Trasferimento interaziendale",
          "Impostazioni aggiuntive",
        ],
        "info": {
          "label": [
            "Nome della ditta",
            "E-mail",
            "Livello di riduzione",
            "Azienda disabile",
            "Metodo di pagamento attivo",
            "Commerciale",
            "Scadenza di pagamento",
            "Importo autorizzato",
            "Nessuno",
            "Implementazione automatica delle risorse",
            "Invio automatico della fattura via email",
            "Pagamento unico per più fatture",
            "URL per il PRD",
            "Numero di telefono",
            "Numero di crediti",
            "Autorizzazione all'invio di SMS",
            "Raggruppare i servizi in un'unica fattura",
          ],
          "option": [
            "Nessuno",
            "Immediato | {days} giorno | {days} giorni",
            "Fine mese | {days} giorno fine mese | {days} giorni fine mese",
          ],
          "help": [
            "Per aggiungere uno nuovo sconto, fare clic su questo pulsante in modalità di modifica.",
          ],
        },
        "vat": {
          "label": [
            "Partita IVA intracomunitaria",
            "Valido",
            "Controllato",
          ],
          "information": [
            "Indirizzo",
            "Prefisso internazionale",
            "Nome",
            "Data richiesta",
            "Partita IVA",
            "Stato",
          ],
          "status": {
            "true": "valido",
            "false": "Non valido",
          },
          "popup": [
            "Controlla e visualizza informazioni",
            "Dai un'occhiata",
            "Annulla modifica"
          ],
        },
        "commercial": {
          "message": [
            "Non ci sono venditori assegnati a questa azienda.",
          ],
          "title": "Vendite aziendali",
          "button": [
            "Assegna un venditore",
            "Cambia pubblicità",
          ],
          "header": [
            "Nome",
            "Nome",
            "E-mail",
          ],
          "modal": {
            "title": "Assegna un venditore",
          },
        },
        "quota": {
          "label": [
            "Quota",
            "Restrizioni",
            "Vswitchs",
          ],
          "header": [
            "Reti",
            "Server",
            "PROCESSORE",
            "Memoria",
            "Disco",
            "Istantanee",
            "Magazzinaggio",
            "Interfacce",
            "Numero di Porte",
            "Qos",
          ],
        },
        "transfer": {
          "header": [
            "Tipo di trasferimento",
            "Azienda destinataria",
            "Abbonamento(i) da trasferire",
            "Server da trasferire",
            "Abbonamento di destinazione",
            "Azzeramento dei prezzi",
          ],
          "type": {
            "1": "Trasferimento completo ad un'altra azienda",
            "2": "Trasferimento di abbonamento(i) ad un'altra azienda",
            "3": "Trasferimento di server ad un'altra azienda",
            "4": "Trasferimento di server tra abbonamenti della stessa azienda",
          },
          "help": {
            "1": "Tutti gli abbonamenti, server, IP e Vswitch saranno trasferiti alla società di destinazione.",
            "2": "Gli abbonamenti selezionati insieme ai server e alle IP associate saranno trasferiti alla società di destinazione.",
            "3": "I server selezionati insieme alle loro IP associate saranno trasferiti alla società di destinazione.",
            "4": "I server selezionati saranno trasferiti a un altro abbonamento della stessa azienda.",
            "5": "Per impostazione predefinita, l'abbonamento di destinazione avrà lo stesso nome dell'originale.",
            "6": "I prezzi dei server trasferiti saranno inizializzati a zero nell'abbonamento di destinazione, rendendoli gratuiti."
          },
        },
      },
    },
    "coupon": {
      "title": "Buoni Sconto",
      "button_new": "Nuovo",
      "button_close": "Chiudi",
      "button_add": "Aggiungi",
      "status": {
        "title": "Stato",
        "enable": "Attivo",
        "disable": "Inattivo",
      },
      "code": "Codice",
      "use": "Usa",
      "description": "Descrizione",
      "expiry_date": "Data di scadenza",
      "never": "mai",
      "no_coupon": "Nessun coupon trovato",
      "popover": {
        "modify": "Modifica",
        "enable": "Abilita",
        "duplicate": "Duplicare",
        "disable": "Disattiva",
        "suppress": "Elimina",
        "at_start_date": "Efficace al momento della creazione",
        "always": "sempre",
        "unlimited": "0 = illimitato",
        "need": "Devi selezionare almeno un elemento ed inserire un codice",
        "need_member": "Devi inserire un codice e inserire uno sconto totale o selezionare almeno un elemento",
      },
      "modale": {
        "title": "Dettaglio coupon",
        "code": "Codice:",
        "description": "Descrizione:",
        "expiry_date": "Data di scadenza: ",
        "max_use": "Usi massimi: illimitato | Usi massimi: {count}",
        "first_order": "Solo sul 1° ordine da un'azienda",
        "clients": "Cliente target: | Clienti target:",
        "items": "Nessun elemento | Elemento obiettivo: {count} | Elementi obiettivo: {count}",
        "number": "No",
        "invoice_date": "Data fattura",
        "client": "Cliente",
        "total": "Totale IVA esclusa",
        "discount_amount": "Importo di sconto"
      },
      "creation": {
        "title": "Creazione/Aggiornamento di un coupon",
        "tab1": {
          "title": "Impostazioni coupon",
          "label": "",
          "code": "Codice Coupon",
          "first_order": "Solo sul 1° ordine di un'azienda",
          "clients": "Indirizzare un cliente specifico",
          "description": "Descrizione",
          "items": "Numero di articoli selezionati",
          "start_date": "Data di inizio",
          "expiry_date": "Data di scadenza",
          "max_use": "Numero massimo di utilizzi totali",
          "max_use_client": "Numero massimo di utilizzi per client",
          "amount": "Sconto totale",
          "help": "Inserisci sia uno sconto totale che una lista di articoli",
        },
        "tab2": {
          "title": "Gestione articoli",
          "label1": "Articolo selezionato | Articolo selezionato | Elementi selezionati",
          "label2": "Aggiungi uno o più elementi",
          "label3": "Quantità massima per coupon",
          "header": [
            "Articolo",
            "Tipo di riduzione",
            "Valore di sconto",
            "Quantità massima",
          ],
          "discount": {
            "type": {
              "FIX": "Importo fisso",
              "PERCENTAGE": "Percentuale"
            },
            "type_sign": {
              "FIX": "€",
              "PERCENTUALE": "%"
            },
            "help": "Vuoto significa nessun limite",
          },
        }
      },
      "alert": [
        "Buono attivato",
        "Buono disabilitato"
      ],
      "switch": {
        "coupon": "Generale",
        "member": "Membro",
        "affiliate": "Affiliato"
      },
    },
    "multi_payment": {
      "title": "Pagamento multiplo",
      "amount_received": "Importo ricevuto",
      "total_amount_received": "Importo totale ricevuto ({total} €)",
      "invoice_date": "Data di pagamento",
      "payment_method": "Metodo di pagamento",
      "reference_number": "Numero di riferimento",
      "unpaid_invoices": {
        "title": "Fatture non pagate",
        "header1": "Data",
        "deadline": "data di scadenza",
        "header2": "Numero fattura",
        "header3": "Importo",
        "header4": "Importo dovuto",
        "header5": "Pagamento",
      },
      "non_chargeable_amount": "Importo non addebitabile {amount}€",
    },
    "payment": {
      "modal": {
        "invoice_date": "Data fattura",
        "payment_amount": "Importo di pagamento",
        "refund_amount": "Importo rimborsato",
        "reference_number": "Riferimento",
        "client": "Cliente",
        "invoice_number": "Fattura",
        "status": "Stato",
        "bank_status_code": "Codice di stato della banca",
        "bank_status_message": "Messaggio dello stato bancario",
        "dispute": "Contestazione",
        "bank_payment_id": "Riferimento bancario",
        "regularized": "Regolarizzato da",
        "goto-payment": "Visualizza i dettagli del pagamento",
      },
      "tab": {
        "title": "Aggiungi pagamento",
        "header1": "tipo",
        "header2": "ID transazione",
        "header3": "Importo",
        "header4": "Data di esecuzione",
        "modal_question": "Sei sicuro di voler creare questo pagamento?",
      },
    },
    "refund": {
      "refund_number": "Riferimento {riferimento}",
      "initial_amount": "- Importo della liquidazione IVA inclusa: ",
      "message_no_invoice": "Nessuna fattura è stata pagata da questa transazione",
      "checkbox1": "Non creare nota di credito",
      "checkbox2": "Rimborso parziale",
      "help": "Scegli o inserisci un motivo",
      "related_invoice": "Fattura correlata | Fatture correlate",
      "header1": "Nome",
      "header2": "Qtà",
      "header3": "Prezzo IVA esclusa",
      "credit": "Credito residuo tasse escluse",
      "amount_excluding_vat": "Importo IVA esclusa",
      "amount_including_vat": "Importo comprensivo di IVA",
      "total_IVA_esclusa": "Totale IVA esclusa",
      "total_IVA_compresa": "Totale IVA inclusa",
      "prepend": "Importo del rimborso tasse incluse",
      "total_credit": "Totale credito residuo escl. tasse",
      "total_refund": "Rimborso totale",
      "reasons": [
        "Motivo del rimborso",
        "Pagamento duplicato",
        "Fraudolento",
        "Richiesto dal cliente",
      ],
      "placeholder": [
        "Riferimento",
        "Banca",
        "Data di creazione",
        "Motivo per averlo",
        "Qtà",
        "Prezzo",
        "Rimborso importo senza credito",
      ],
      "excluding_vat": "IVA esclusa",
      "including_vat": "IVA inclusa",
    },
    "migration": {
      "title": "Migrazione Hypervisor",
      "label1": "Modalità migrazione",
      "label2": "Seleziona l'hypervisor di origine",
      "label3": "UUID del server di origine",
      "label4": "Seleziona Hypervisor di destinazione",
      "label5": "Ipervisore",
      "no_hypervisor": "Nessun hypervisor disponibile",
      "success_message1": "Hypervisor { hostname } sarà { action }.",
      "success_message2": "Migrazione iniziata",
      "title-list": "Elenco degli Hypervisor",
      "state": {
        "disable": "Disabilitato",
        "enable": "Abilitato",
      },
      "success_message": "Le modifiche sono state effettuate con successo",
    },
    "notification": {
      "button_migration": "Migrazione HyperV",
      "button_management": "Gestione hyperV",
      "button_assignment": "Assegna notifica",
      "header": [
        "Gravità",
        "Titolo",
        "Messaggio",
        "Ricomincia",
        "Server",
        "Approvazione",
      ],
      "modal": {
        "title": "Assegna una notifica",
        "title_add": "Notifica",
        "label": [
          "Gravità",
          "Titolo",
          "Messaggio",
          "Riavvio forzato",
          "OID server",
          "Consenso per la notifica di cancellazione",
        ],
      },
      "reboot": [
        "Sì",
        "No",
      ],
      "agreement": [
        "Sì",
        "No",
      ],
    },
    "vat": {
      "title": "Modificare l'aliquota IVA di un paese",
      "filter": "IVA > 0",
      "header": [
        "Paese",
        "Prefisso internazionale",
        "Percentuale",
      ],
      "label": [
        "Data di applicazione",
        "Percentuale"
      ],
      "message": [
        "Aggiornamento riuscito",
        "Data scelta < data odierna"
      ],
    },
    "export": {
      "lanel": [
        "Filtro data",
        "Scritture",
        "Aziende",
        "Nuove aziende",
        "Aziende aggiornate",
        "Scarica CSV",
        "Download in corso...",
        "Contatti",
        "Per CRM",
        "Nuovi contatti",
        "Contatti aggiornati",
      ],
    },
    "payment_delay": {
      "title": "Termini di pagamento",
      "buton": "Nuovo",
      "sort": {
        "state": {
          "true": "Attivo",
          "false": "Non attivo"
        },
      },
      "header": [
        "Numero di giorni",
        "Fine del mese",
        "Bene",
      ],
      "operation": {
        "title": [
          "Creazione di un termine di pagamento",
          "Aggiornamento di un termine di pagamento",
        ],
        "header": [
          "Numero di giorni",
          "Fine del mese",
          "Bene",
        ],
        "message": [
          "Creazione riuscita",
          "Errore durante la creazione",
          "Aggiornamento riuscito",
          "Errore durante l'aggiornamento",
        ],
      },
    },
    "alert": {
      "title": "Compiti giornalieri",
      "button": "Nuovo",
      "sort": {
        "state": {
          "true": "Attiva",
          "false": "Inattiva",
        },
      },
      "header": [
        "Tipo di attività",
        "Categoria del template",
        "Nome del template",
        "Estensione del template",
        "Tipo di invio",
        "N. max invii al giorno",
        "Scadenza",
        "Tipo di scadenza",
        "Email Ccn",
        "Attiva",
        "Disabilitare server",
      ],
      "operation": {
        "title": [
          "Creazione di un'attività",
          "Aggiornamento di un'attività",
        ],
        "header": [
          "Tipo di attività",
          "Categoria del template",
          "Nome del template",
          "Estensione del template",
          "Tipo di invio",
          "N. max invii al giorno",
          "Scadenza",
          "Tipo di scadenza",
          "Email Ccn",
          "Attiva",
          "Disabilitare server",
        ],
        "message": [
          "Creazione riuscita",
          "Errore durante la creazione",
          "Aggiornamento riuscito",
          "Errore durante l'aggiornamento",
        ],
      },
      "type": {
        "before_server_deletion": "Prima dell'eliminazione del server",
        "delete_server": "Eliminazione del server",
        "delete_ip": "Eliminazione IP",
        "delete_outsourcing": "Terminare i Servizi Gestiti",
        "invoice_unpaid": "Fattura non pagata",
        "payment_method_expired": "Metodo di pagamento scaduto",
        "invoice_renew": "Fatturazione",
        "payment_error": "Errore di pagamento",
        "cart_is_paid": "Carrello pagato",
        "ticket_before_closing": "Prima della chiusura del ticket",
        "ticket_closing": "Chiusura del ticket",
        "managed_service_notification": "Scadenza del Servizio Gestito",
      },
      "template_category": {
        "server": "Server",
        "renewal": "Rinnovo",
        "payment": "Pagamento",
        "invoice": "Fatture",
        "ticket": "Ticket",
        "ip": "Indirizzo IP",
        "outsourcing": "Gestione Esternalizzata",
      },
      "send_type": {
        "email": "Email",
      },
      "time_diff_type": {
        "day": "Giorno",
        "month": "Mese",
      },
      "help": [
        "Numero di volte che l'email viene inviata nello stesso giorno o mese (-1: Sempre, 0: Una volta)",
        "Numero di giorni/mesi prima (valore negativo) o dopo (valore positivo) la data di controllo",
        "Indirizzo email che riceve il messaggio oltre a quello del cliente (opzionale)",
        "Attiva/Disattiva l'attività",
        "Solo per fatture non pagate. Esegue la disattivazione del server se selezionato.",
      ],
    },
    "miscellaneous": {
      "title": "Varie",
      "none_option": "Nessuno",
      "param": {
        "title": "Impostazioni",
        "button": "Nuovo",
        "header": [
          "Nome",
          "Tipo",
          "Valore",
          "Formulazione",
          "Data",
          "Valore minimo",
          "Valore massimo",
          "Chiave del dizionario",
          "Valore del dizionario",
        ],
        "operation": {
          "title": [
            "Creazione",
            "Aggiornamento",
          ],
          "header": [
            "Nome",
            "Tipo",
            "Valore",
            "Formulazione",
            "Data",
            "Valore minimo",
            "Valore massimo",
            "Formulazione",
          ],
          "message": [
            "Creazione di successo",
            "Errore durante la creazione",
            "Aggiornamento riuscito",
            "Errore durante l'aggiornamento",
            "Eliminato con successo",
            "Errore durante l'eliminazione",
          ],
        },
      },
      "function": {
        "title": "Funzioni",
        "all": "Tutti",
        "help": [
          "Scegliere un'azienda o lasciare vuoto per tutte le aziende",
          "Inserisci l'IPv4 primaria e fai clic sul pulsante. Il campo 'redirezione' verrà cancellato.",
          "Inserisci un indirizzo IPv4 e scegli uno o più controlli da effettuare. Quindi premi il pulsante.",
          "Inserisci la scadenza di eliminazione e fai clic sul pulsante.",
        ],
        "function": [
          "Ricalcolo del saldo",
          "Ripristina redirezione IPv4 primaria",
          "Controlli sull'indirizzo IP",
          "Crea un abbonamento",
          "Eliminazione di 'Provisioning VM'",
        ],
        "message": {
          "successful": "Aggiornamento riuscito",
          "unsuccessful": "Aggiornamento non riuscito",
        },
        "api-result": {
          "title": "Risultati dei controlli sull'IP {ip}",
          "more": "Mostra risultato grezzo",
          "less": "Nascondi risultato grezzo",
          "header": [
            "PING",
            "NMAP",
            "TRACEROUTE",
            "ABUSE",
          ],
          "show-result": "Clicca sul controllo da eseguire",
          "result-ping": [
            "Numero di pacchetti trasmessi: ",
            "Numero di pacchetti ricevuti: ",
            "Percentuale di pacchetti persi: "
          ],
          "result-nmap": [
            "Elenco delle porte aperte: ",
            "Nessuna porta aperta"
          ],
          "result-traceroute": [
            "Hop {hop}: ",
            "Nessun risultato"
          ],
          "result-abuse": [
            "Parte della whitelist",
            "Indirizzo IP in blacklist",
            "Indirizzo IP non in blacklist",
            "Nessun risultato"
          ],
        },
        "api-buttons": {
          "button-ping": "Avviare un Ping su questo indirizzo IP",
          "button-nmap": "Avviare un Nmap su questo indirizzo IP",
          "button-traceroute": "Avviare un Traceroute su questo indirizzo IP",
          "button-abuse": "Avviare un Abuse su questo indirizzo IP",
        },
        "outsourcing": {
          "header": [
            "Azienda da fatturare",
            "Server",
            "Articolo",
            "Frequenza",
            "Nome del progetto",
            "Azienda proprietaria dei server",
            "Tipo di abbonamento",
          ],
          "number-of-servers": "Nessun server | 1 server | {nb} server",
        },
      },
      "ticket-trash": {
        "title": "Ticket nel Cestino",
      },
    },
    "outsourcing": {
      "title": "Esternalizzazione",
      "button": "Nuovo",
      "no_param": "Crea tutti i parametri necessari per creare i livelli di servizio.",
      "month": "mese",
      "header": [
        "Livello",
        "Formulazione",
        "Descrizione",
        "Criticità",
        "Esternalizzazione",
        "Priorità",
        "Intervento",
        "Prezzo Ht",
        "Il",
        "Articolo",
        "Durata",
        "Preavviso",
      ],
      "operation": {
        "title": [
          "Creazione",
          "Aggiornamento",
          "Modifica della Descrizione",
        ],
        "header": [
          "Livello di esternalizzazione",
          "Formulazione",
          "Descrizione",
          "Criticità",
          "Esternalizzazione",
          "Priorità",
          "Tempo di intervento",
          "Prezzo Ht",
          "Data di applicazione",
          "Articolo",
          "Durata dell'Impegno",
          "Preavviso (in mesi)",
        ],
        "message": [
          "Creazione di successo",
          "Errore durante la creazione",
          "Aggiornamento riuscito",
          "Errore durante l'aggiornamento",
          "Eliminato con successo",
          "Errore durante l'eliminazione",
          "Si prega di inserire i campi obbligatori",
          "Campi obbligatori",
        ],
      },
    },
    "param": {
      "outsourcing": {
        "outsourcing": "Livello di servizio",
        "standard": "Esternalizzazione standard",
        "advanced": "Outsourcing avanzato",
        "full": "Esternalizzazione completa",
      },
      "outsourcing_criticality": {
        "outsourcing_criticality": "Criticità",
        "low": "Basso",
        "medium": "Moderato",
        "high": "Alto",
      },
      "outsourcing_priority": {
        "outsourcing_priority": "Priorità",
        "medium": "Media",
        "high": "Alto",
        "priority": "Priorità",
      },
      "outsourcing_level": {
        "outsourcing_level": "Esternalizzazione",
        "none": "Nessuno",
        "medium": "Moderato",
        "full": "Completo",
      },
      "intervention_type": {
        "intervention_type": "Tipo di intervento",
        "day": "Giorno",
        "hour": "Ora",
        "minute": "Minuto",
      },
    },
    "ticket-indexer": {
      "title": "Reindicizzazione biglietto",
      "header": [
        "Numero massimo di email da elaborare",
        "Data più vecchia da elaborare",
      ],
      "help": "Lascia questi valori vuoti per reindicizzare tutte le email",
      "message": [
        "Attività avviata"
      ],
    },
    "subscription": {
      "type": {
        "0": "Standard",
        "1": "Servizi gestiti",
        "2": "Piano di ripristino in caso di disastro",
        "3": "IP",
        "4": "Memorizzazione",
        "5": "Credito",
        "6": "VSwitch",
        "7": "Porta",
        "8": "QoS",
        "100": "Contratto Quadro",
      },
      "title": "Dettagli dell'abbonamento",
      "return": "Ritorno",
      "return-text": "Ritorna alla lista degli abbonamenti",
      "tab": [
        "Generale",
        "Fatture",
      ],
      "overview": {
        "header": [
          "Nome Progetto",
          "Frequenza",
          "Data di Inizio",
          "Data di Fine",
          "Data di Aggiornamento",
          "Stato",
          "Pagamento disabilitato per il rinnovo delle fatture",
          "Inserire un suffisso",
        ],
        "footer": [
          "Totale Escl. IVA",
          "IVA",
          "Totale Incl. IVA",
          "Sconto",
          "Totale Netto Escl. IVA",
        ],
        "update": {
          "success": "Aggiornamento dell'abbonamento riuscito",
          "nothing": "Nessuna informazione da aggiornare",
          "commercial-success": "Aggiornamento commerciale riuscito",
          "date-warning": "Si prega di inserire una data di fine",
          "partial-billing": "Creare una fattura parziale",
        },
        "detail": {
          "title": "Articoli dell'abbonamento",
          "header": [
            "Articolo",
            "Descrizione",
            "Server",
            "Quantità",
            "Prezzo unitario",
            "Sconto",
            "Importo netto",
            "Implementato",
          ],
        },
        "button": {
          "modify": "Modifica abbonamento",
          "modify-header": "Modifica l'intestazione dell'abbonamento",
          "add-item": "Aggiungi articolo",
          "validate": "Conferma modifiche",
          "cancel": "Annulla modifiche",
          "create-invoice": "Crea Fattura",
          "generate-invoice": "Genera Fattura",
        },
        "messages": [
          "Campi obbligatori",
          "Validazione delle informazioni e creazione dell'abbonamento",
        ],
      },
    },
    "demo": {
      "enable": {
        "title": "Attivazione della modalità demo",
        "server-name": "Nome del server",
        "transfer": "Trasferire il server a un cliente?",
        "message-success": "Modalità demo attivata con successo",
        "message-error": "Errore durante l'attivazione della modalità demo",
      },
      "disable": {
        "title": "Disattivazione della modalità demo",
        "server-name": "Nome del server",
        "return": "Ritorno del server nel pool Titan",
        "true": "Sì",
        "false": "No",
        "project": "Abbonamento di destinazione",
        "frequency": "Periodicità",
        "billing": "Da fatturare?",
        "billing-date": "Fatturazione dal",
        "message-success": "Modalità demo disattivata con successo",
        "message-error": "Errore durante la disattivazione della modalità demo",
      },
    },
    "license": {
      "title": "Licenze",
      "plesk": {
        "title": "Plesk",
        "list-empty": "Elenco vuoto",
        "generate": "Genera lista",
        "tooltip": [
          "Generare o rigenerare l'elenco",
          "Scaricare un CSV",
        ],
        "waiting": "Il tempo di elaborazione attuale può superare i 2 minuti. Si prega di attendere.",
        "search": "Ricerca per identificativo, elemento, IP, cliente, server",
        "goto-server": "Visualizza il server",
        "goto-subscription": "Visualizza l'abbonamento",
        "goto-invoice": "Visualizza la fattura",
        "header": [
          "Identificativo",
          "Stato",
          "Elemento",
          "Creazione",
          "Vincolo",
          "Cliente",
          "Server",
          "Stato server",
          "Abbonamento",
          "Ultima fattura",
          "Key ID",
          "Scadenza",
          "Codice di attivazione",
          "Descrizione",
          "IP segnalato/i",
        ],
        "sort": {
          "status": {
            "all": "Tutti",
            "ACTIVE": "Attivo",
            "TERMINATED": "Inattivo",
          },
        },
        "errors": {
          "title": "Errori",
          "legend": "Legenda",
          "error_legend": [
            "Azienda sconosciuta",
            "Server eliminato o sconosciuto",
            "Abbonamento terminato",
            "Assegnazione del server errata",
          ],
          "error_code": {
            "0": "Nessuno",
            "1": "Azienda",
            "2": "Server",
            "3": "Abbonamento",
            "4": "Sbagliato",
          },
        },
        "modal": {
          "disable": {
            "title": "Disattivazione della licenza",
            "message": "Confermando questa azione, la licenza verrà disattivata.",
            "warning": "Puoi sempre riattivare questa licenza",
            "extra": "Sei sicuro di voler disattivare la licenza?",
          },
        },
        "message": [
          "La licenza è stata disattivata",
          "Errore durante la disattivazione",
        ],
        "info": {
          "title": "Dettagli della licenza",
          "button": {
            "modify": "Modifica la licenza",
            "stop-modify": "Interrompi la modifica",
          },
        },
      },
    },
    "quote": {
      "overview": {
        "title": [
          "Preventivo N°",
          "Nuovo Preventivo",
        ],
        "header": [
          "Azienda",
          "Commerciale",
          "Data di Creazione",
          "Valido Fino a",
          "Stato",
          "Accettato il",
        ],
        "footer": [
          "Totale Imponibile",
          "IVA",
          "Totale IVA Inclusa",
          "Sconto",
          "Totale Netto Imponibile",
        ],
        "state": {
          "ongoing": "In corso",
          "accepted": "Accettato",
          "canceled": "Annullato",
        },
        "messages": {
          "create-success": "Creazione del preventivo riuscita",
          "create-error": "Errore nella creazione del preventivo",
          "update-success": "Aggiornamento del preventivo riuscito",
          "update-error": "Errore nell'aggiornamento del preventivo",
          "nothing": "Nessuna informazione da aggiornare",
          "already-used": "Nome già utilizzato",
        },
        "detail": {
          "title": "Dettaglio del Preventivo",
          "header": [
            "Articolo",
            "Designazione",
            "Server",
            "Quantità",
            "Prezzo Unitario",
            "Sconto",
            "Importo Imponibile",
          ],
        },
        "button": {
          "modify": "Modifica il preventivo",
          "open-menu": "Apri il menu",
          "close-menu": "Chiudi il menu",
          "delete-item": "Elimina l'elemento",
          "add-item": "Aggiungi un articolo",
          "add-comment": "Aggiungi un commento",
          "validate": "Convalida le modifiche",
          "cancel": "Esci e annulla le modifiche",
          "create": "Crea il preventivo",
          "show-quote": "Mostra il preventivo",
          "show": "Mostra",
          "exit": "Esci",
          "item-up": "Inserire un articolo sopra",
          "item-down": "Inserire un articolo sotto",
          "comment-up": "Inserire un commento sopra",
          "comment-down": "Inserire un commento sotto",
        },
      },
    },
    "dispute": {
      "modal": {
        "document_number": "Numero di Contenzioso",
        "created": "Creazione",
        "amount": "Importo del Pagamento",
        "fees": "Spese Bancarie",
        "total_amount": "Totale Dovuto",
        "client": "Cliente",
        "invoices": "Fatture",
        "status": "Stato",
        "bank_dispute_id": "Riferimento del Contenzioso",
        "bank_payment_id": "Riferimento del Pagamento",
        "reason": "Motivo",
        "paid": "Saldato",
        "authorize_SEPA": "Autorizzare il cliente a pagare tramite SEPA",
        "invoice_unlocked": "Sbloccare le fatture per pagamento separato",
        "cancel-deactivation": "Annulla le disattivazioni dei server pianificate",
        "dialog-cancel-deactivation": {
          "title": "Annulla disattivazioni dei server pianificate",
          "warning": "Questa azione è sensibile",
          "message": "Confermando questa azione, le disattivazioni verranno annullate.",
          "extra": "Sei sicuro di voler annullare le disattivazioni?",
          "button": "Annulla disattivazioni",
        },
      },
    },
  },
  "invoice": {
    "server": "Server",
    "status": "Stato",
    "number": "N.",
    "client_code": "Codice cliente",
    "client_name": "Cliente",
    "balance": "saldo",
    "final_date": "Scadenza",
    "product": "Prodotto",
    "created_at": "Data creazione",
    "create": "Creato il",
    "start": "Avviato il",
    "update": "Aggiornato il ",
    "not_found": "Nessuna fattura trovata.",
    "not_found_search": "Nessuna fattura corrisponde alla tua ricerca.",
    "type": "Tipo",
    "due_date": "Data di fine",
    "amount_ht": "Importo prima delle tasse",
    "amount_ttc": "Importo IVA inclusa",
    "changing_project_name": {
      "title": "Vuoi davvero cambiare il nome del progetto?",
      "description": "Il nome del nuovo progetto sarà: ",
      "success": "La modifica del nome è stata effettuata correttamente.",
    },
    "frequency": {
      "title": "Cadenza",
      "detailed_title": "Periodo di fatturazione",
      "changing_title": "Vuoi davvero cambiare la cadenza di fatturazione?",
      "success": "La modifica della cadenza di fatturazione è stata effettuata correttamente.",
      "MONTHLY": "Mensile",
      "QUARTERLY": "Trimestrale",
      "BIANNUALLY": "Semestrale ",
      "ANNUALLY": "Annuale",
    },
    "last_invoice": "Ultima Fattura",
    "next_invoice": "Prossima Fattura",
    "see_all_invoice": "Visualizza le fatture",
    "company": "Azienda",
    "price": "Importo",
    "project_name": "Progetto",
    "add_item": "Aggiungi elemento",
    "manage_item": "Gestisci elementi",
    "generate_invoice": "Genera una fattura",
    "payment_method": "Metodo di pagamento",
    "details": {
      "status": "Stato della fattura",
      "billing_address": "Fatturato a:",
      "shipping_address": "Consegnato a:",
      "same_shipping_billing": "Consegnato e fatturato a:",
      "description": "Descrizione",
      "initial_amount": "Importo IVA escl.",
      "discount_rate": "Tasso di sconto",
      "discount_amount": "Importo dello sconto",
      "net_amount": "Totale IVA escl.",
      "vat_rate": "Aliquota IVA",
      "vat_amount": "Importo IVA",
      "total_amount": "Totale IVA incl.",
      "remaining_amount": "Importo Dovuto",
      "download": "Scarica",
      "legal_notice_text": "Tasso interessi moratori: Ai sensi della legge francese n. 921442 del 31 dicembre 1992, la presente fattura è pagabile alla data di scadenza sopra indicata senza sconto per il pagamento anticipato. Le fatture emesse e non pagate entro la data di scadenza produrranno automaticamente interessi moratori ad un tasso pari al triplo del tasso legale fino al completo pagamento, come definito ai sensi dell'articolo L.441-6 del Codice di Commercio francese. Indennità forfettaria per spese di recupero di 40 euro (Decreto 2012-1115 del 2 ottobre 2012).",
      "tva_notice_in_eu": "Esenzione IVA - Articolo 196 della Direttiva 2006/112/CE",
      "tva_notice_out_eu": "IVA non imponibile - articolo 44 della Direttiva 2006/112/CE",
      "facturation_date": "Data della fattura",
      "credit_date": "Data della nota di credito :",
      "payment": "Saldo: Al ricevimento",
      "due_date": "Data di scadenza",
      "billing_period": "Periodo di fatturazione",
      "billing_period_sm": "Periodo",
      "project": "Progetto:",
      "page": "pagina",
      "of": "su",
      "subtotal": "Subtotale IVA escl.",
      "subtotalTag": "IVA escl.",
      "cancel": "Annulla",
      "paid_by": "Pagata da",
      "promotion_code": "Codice promozionale",
      "item": {
        "name": "Prodotto",
        "quantity": "Qtà",
        "unit_price": "P.U",
        "total_price": "Totale IVA escl."
      },
      "billing_date": "Data di inizio fatturazione",
    },
    "modal": {
      "button": {
        "pay": "Paga",
        "cancel": "Chiudi",
        "return": "Indietro",
        "next": "Avanti",
        "generate_credit": "Genera credito",
        "clear": "Cancellare",
        "deploy": "Deploy",
      },
      "payment": {
        "invoices_page": "Pagina Fatture",
        "servers_page": "Pagina Server",
      },
      "clear": {
        "title": "Salda una fattura o una nota di credito",
        "header": [
          "Nuovo Importo",
        ],
        "success": "Operazione completata con successo"
      },
      "deploy": {
        "ask_deploy": "Sei sicuro di voler distribuire le risorse?",
        "success": "Deployment in corso",
        "failed": "Richiesta fallita",
      },
    },
    "recurring": {
      "not_found": "Nessun abbonamento trovato.",
      "not_found_search": "Nessun abbonamento corrisponde alla tua ricerca.",
      "payment_method": {
        "text": [
          "Seleziona il metodo di pagamento da utilizzare per l'abbonamento e clicca su 'Applica'.",
          "Per utilizzare il metodo di pagamento predefinito, clicca su 'Reimposta'.",
          "In caso di errore di pagamento, verrà utilizzato il metodo di pagamento predefinito.",
        ],
      },
    },
    "next_frequency": "Frequenza successiva",
    "account_balance": "Saldo del tuo account: {amount}€"
  },

  "credit": {
    "created_at": "Data di creazione :",
    "start_date": "Data d'inizio :",
    "expiration_date": "Data d'inizio :",
    "credit_date": "Data di credito :",
    "reference_period": "periodo di riferimento :",
    "referring_invoice": "Fattura di riferimento :",
    "number": "Credito N° ",
    "remaining_amount": "Importo residuo",
    "modal": {
      "title": "Creazione di una nota di credito ({fattura})",
      "period": "Periodo interessato",
      "header1": "Articolo",
      "header2": "Quantità",
      "header3": "Importo",
      "header4": "Azione",
      "placeholder1": "Prezzo",
      "placeholder2": "Quantità",
      "footer": {
        "amount_allowed": "Importo disponibile IVA esclusa:",
        "amount_excluding_vat": "Totale IVA esclusa:",
        "amount_including_vat": "Totale IVA inclusa:",
        "amount_vat": "IVA:",
        "amount": "Importo IVA esclusa:",
        "keep_old_values": "Nota di credito globale con importi originali",
      },
    },
  },

  "payment_method": {
    "waiting": {
      "message_less_than_hour": "Il pagamento è in sospeso, ti preghiamo di attendere ",
      "message": "Il tuo pagamento è ancora in sospeso, ti preghiamo di contattare il supporto.",
      "link": "Crea un ticket"
    },
    "card": {
      "empty": "Nessun metodo di pagamento, aggiungerne uno per continuare.",
      "existing": "Metodi di pagamento esistenti ",
      "numbers": "Numero della carta",
      "expiration_date": "Data di scadenza (MM/YYYY)",
      "full_name": "Nome completo",
      "reference": "Reference:",
      "default": "Predefinito",
      "default_ribbon": "Predefinito",
      "remove": "Cancella",
      "expired": "Scaduta",
      "iban": "IBAN: ",
      "default_method": "Questo metodo di pagamento è quello predefinito. Viene usato per tutti i tuoi abbonamenti.",
      "creation": {
        "title_sepa": "Informazioni sul nuovo IBAN",
        "add_sepa": "Aggiungi un nuovo IBAN",
        "title": "Informazioni sulla nuova carta",
        "valid_card": "Aggiungi",
        "valid_sepa": "Accetta e aggiungi",
        "name_placeholder": "Nome completo",
        "email_placeholder": "E-mail",
        "add_card": "Aggiungi una nuova carta",
        "cancel": "Annulla"
      },
      "invalid": "Non valido",
      "validate": "Convalidare",
      "expired_message": "Questo metodo di pagamento è scaduto, puoi eliminarlo.",
      "invalid_message": "Questo metodo di pagamento non è stato convalidato dalla banca. Puoi procedere con la convalida o eliminarlo.",
    },
    "sepa_mandate": "La sottoscrizione del presente mandato comporta (A) l’autorizzazione a " +
      "Titan Datacenters France e Stripe, il nostro fornitore di servizi di pagamento, a richiedere alla banca " +
      "del debitore l’addebito del suo conto e (B) l’autorizzazione alla banca del debitore di procedere a tale " +
      "addebito conformemente alle queste disposizioni. Il debitore ha diritto di ottenere il " +
      "rimborso dalla propria Banca secondo gli accordi ed alle condizioni che regolano il rapporto " +
      "con quest’ultima.  Se del caso, il rimborso deve essere richiesto nel termine di 8 settimane a " +
      "decorrere dalla data di addebito in conto. I diritti del sottoscrittore del presente mandato sono " +
      "indicati nella documentazione ottenibile dalla propria banca.",
    "details": {
      "payment_period": "Per il periodo dal",
      "to": "al",
      "month": "Mese",
      "monthly": "Mese",
      "quarterly": "Trimestre",
      "biannually": "Semestre",
      "annually": "Anno",
      "initial_amount": "Importo IVA escl.:",
      "initial_amount_monthly": "Importo IVA escl./Mese:",
      "discount": "Sconto:",
      "net_amount": "Totale IVA escl.:",
      "vat": "IVA:",
      "total_amount": "Totale IVA incl.:",
      "total_amount_monthly": "Totale IVA incl./Mese:",
      "amount_due": "Importo dovuto:",
      "amount_paid": "Importo pagato:",
      "remaining_amount": "Importo restante tasse incluse:",
      "amount_too_low": "Questo importo è troppo basso per essere addebitato immediatamente, la fattura verrà automaticamente pagata insieme al rinnovo dell'abbonamento.",
      "amount_too_low_accept": "Accetto l'addebito differito",
      "non_recurring_amount": "Importo non ricorrente tasse escluse :",
      "recurring_amount": "Importo ricorrente IVA esclusa :"
    },
    "credit": {
      "list": "Lista Crediti",
      "id": "ID del Credito",
      "credit": "Credito",
      "allocated_amount": "Totale (incl. IVA)",
      "available_amount": "Saldo",
      "provisional_amount": "Saldo Provvisorio *",
      "provision_tips": "Si aggiorna quando selezionato.",
      "use": "Credito Utilizzato:",
      "expiration_date": "Data di Scadenza",
      "created_at": "Data di Creazione"
    },
    "modal": {
      "select_preferred": {
        "title": "Modifica metodo di pagamento predefinito",
        "warning": "Questo metodo di pagamento verrà usato per tutti i tuoi abbonamenti.",
      },
      "confirm_payment": {
        "title": "Riepilogo pagamento:",
        "amount": "Importo dell'ordine IVA incl.:",
        "method": "Metodo di pagamento usato:",
        "cgv_confirm": "Convalidando l'ordine, dichiaro di aver letto e accettato senza riserve le CGV e l'informativa sulla privacy",
        "credit": "Credito usato:",
        "cgv_see": "Vedere le CGV",
        "sepa_info_title": "Informazioni SEPA",
        "sepa_info": "Il solito ritardo per una transazione SEPA è compreso tra 7 e 14 giorni lavorativi. " +
          "Il tuo servizio verrà erogato solo dopo la completa convalida della transazione. " +
          "Se lo desideri, puoi effettuare questo pagamento con carta per usufruire dell'erogazione immediata del servizio.",
        "offline": "Pagamento offline",
      }
    },
    "state": {
      "failed": "Pagamento non riuscito",
      "action": "Paiement en attente d'action.",
      "action-detail": "Cliquez sur confirmer pour finaliser votre paiement.",
      "pending": "Pagamento in fase di elaborazione",
      "success": "Il pagamento è andato a buon fine",
      "processing": "Il pagamento è in fase di elaborazione da parte della banca.",
      "failed-comment": [
        'Il tuo ordine è stato salvato.',
        'Puoi trovarlo nella sezione "Azienda", scheda "Documenti"',
      ],
      "validation": {
        "failed": "La validazione del tuo metodo di pagamento è fallita",
        "action": "Azione richiesta da parte tua.",
        "action-detail": "Clicca conferma per finalizzare la validazione.",
        "pending": "La validazione del metodo di pagamento è in corso",
        "success": "Il tuo metodo di pagamento è stato validato",
        "processing": "La validazione è in corso di elaborazione dalla banca.",
        "failed-comment": [
          "Il tuo metodo di pagamento è ancora presente.",
          "Puoi trovarlo nella scheda 'Azienda' 'Metodi di pagamento'"
        ],
      },
    },
    "payment_state": {
      "success": "Andato a buon fine",
      "error": "Errore",
      "waiting": "In attesa",
      "canceled": "Cancellato",
      "processing": "In lavorazione"
    },
    "list": {
      "id": "#",
      "exec_date": "Data",
      "state": "Stato",
      "type": "Tipo",
      "methodID": "id",
      "card": "Carta",
      "credit": "Credito",
      "amount": "Importo",
      "psepa": "PSEPA",
      "vsepa": "VSEPA",
      "cancel_success": "Il suo pagamento è stato annullato con successo."
    },
    "promotional_code": {
      "invalid": "Codice promozionale non valido.",
      "valid": "Codice promozionale applicato",
      "valid_cant_apply": "Il codice promozionale è valido ma non può essere applicato al tuo ordine",
      "amount": "Codice promozionale:"
    }
  },
  "image": {
    "modal": {
      "title": "Modifica dello spazio di archiviazione",
      "description": "Qui è possibile aumentare o diminuire lo spazio di archiviazione totale. Attualmente stai usando:",
      "downgrade": "Stai rimuovendo lo stoccaggio, la riduzione del tuo spazio di stoccaggio sarà fatta immediatamente dopo la convalida.",
      "current": "Spazio di archiviazione corrente",
      "warning": "Questa azione è importante",
      "future": "Spazio di stoccaggio dopo il cambiamento",
      "update": "Modifica",
      "unit-disk": "GB",
      "price-recuring-final": "* Questo importo sarà aggiunto alla fatturazione periodica per il tuo spazio di archiviazione"
    },
    "edit": "Modifica della quota",
    "quotas": "Uso dello stoccaggio",
    "template-origin": "Modello originale: ",
    "empty": "erroreNessuna immagine...",
    "diskSize": "Dimensione del disco",
    "state": "Stato",
    "name": "Nome",
    "date": "Data di creazione",
    "more-info": "Più informazioni",
    "less-info": "Meno informazioni",
    "states": {
      "created": "creato",
      "creating": "creando",
      "error": "errore",
      "deleted": "Cancellato",
      "deleting": "Cancellazione in corso"
    }
  },
  "server": {
    "select": "Selezionare uno o più server",
    "user-name": "Nome utente",
    "from": "Da ",
    "has-notification": "Questo server richiede la tua attenzione",
    "server-empty": "Nessun server...",
    "more-info": "Maggiori informazioni",
    "less-info": "Meno informazioni",
    "_": "Server",
    "plan": "Piano",
    "name": "Nome",
    "state": "Stato",
    "uuid": "UUID",
    "oid": "OID",
    "ram": "RAM",
    "ip-protocol": "Protocollo2222",
    "ipv4": "IPV4",
    "ipv6": "IPV6",
    "ip": "IP",
    "os": "OS",
    "mac": "MAC",
    "cpu": "CPU",
    "vcpu": "vCPU",
    "disk": "Disco",
    "network": "Rete",
    "bandwidth": "Larghezza di banda",
    "disk-qos": "QoS disco",
    "delete-network": "Cancella questo Vswitch",
    "reverse": "Reverse",
    "hypervisor": "Hypervisor",
    "ssh": "SSH",
    "license": "Licenza",
    "kvmip": "KVM IP",
    "deleted_at": "Data cancellazione",
    "gateway": "Gateway",
    "private-network": "Rete privata",
    "ssh-keys": "Chiavi SSH",
    "hostname": "Nome dell'host",
    "company": "Azienda",
    "termination": "Disattivazione pianificata il",
    "outsourcing_date": "Sottoscritto il",
    "outsourcing_end": "Rinnovo",
    "outsourcing_notice": "Fine del Periodo di Preavviso",
    "created_at": "Creazione",
    "ctrl-alt-del": "Ctrl-Alt-Canc",
    "demo": "Demo",
    "disabled": "Server disattivato",
    "upgrade": "Upgrade",
    "downgrade": "Downgrade",
    "pay": "Pagamento",
    "processor": "Processore : ",
    "ram-capacity": "RAM : ",
    "disk-space": "Spazio del disco : ",
    "metric_not_available": "Statistiche non disponibili.",
    "outsourcing": "Esternalizzazione",
    "no-outsourcing": "Nessun outsourcing",
    "drp": "PRD",
    "drp-pending": "Attivazione del DRP in sospeso",
    "default-reverse": "Default reverse",
    "forced-hypervisor": "Hypervisor forzato",
    "no-drp": "Nessun DRP",
    "drp-activated": "DRP Attivato",
    "drp-syncing": "Sincronizzazione",
    "drp-fail": "Errore DRP",
    "drp-info": {
      "title": "Informazioni DRP",
      "last-sync": "Ultimo Aggiornamento"
    },
    "actions": {
      "start": "Avvia",
      "stop": "Interrompi",
      "reboot": "Riavvia",
      "hardstop": "Interruzione forzata",
      "resume": "Riprendi",
      "reset": "Reinizializza",
      "terminate": "Disattiva",
      "reset-network": "Reset Network",
      "cancel-terminate": "Annulla disattivazione",
      "activate-drp": "Attivare il PRD",
      "deactivate-drp": "Disattivare il PRD",
      "schedule-reboot": "Riavvio programmato",
    },
    "events": {
      "snapshot": "Snapshot",
      "network": "Rete privata",
      "ipkvm": "KVM",
      "changestate": "Server",
      "servercreation": "Creazione",
      "serverdeletion": "Cancellazione",
      "serverupdate": "Aggiornamento del campo",
      "serveraddons": "Addon",
      "migration": "Migrazione Hypervisor",
      "serveroperations": "Server operation",
      "storageupdate": "Update"
    },
    "states": {
      "unknow": "Approvvigionamento",
      "createserverimage": "Immagine creata",
      "starting": "Avvio",
      "started": "Avviato",
      "suspending": "Sospensione",
      "suspended": "Sospeso",
      "stopping": "Interruzione in corso",
      "stopped": "Interrotto",
      "reseting": "Reinizializzazione in corso",
      "resuming": "Ripristino",
      "resumed": "Ripristinato",
      "upgrading": "Aggiornamento",
      "disabling": "Disattivazione",
      "disabled": "Disattivato",
      "enabling": "Attivazione",
      "enabled": "Attivato",
      "creating": "Creazione",
      "created": "Creato",
      "deleting": "Cancellazione",
      "deleted": "Cancellato",
      "rebooting": "Riavvio",
      "restoring": "Ripristino",
      "restored": "Ripristinato",
      "terminated": "Completato",
      "diskconfigured": "Disco configurato",
      "diskconfiguration": "Configurazione del disco",
      "diskcreated": "Disco creato",
      "diskcreation": "Creazione del disco",
      "diskdeleted": "Disco cancellato",
      "diskdeletion": "Cancellazione del disco",
      "configurationcreation": "Creazione della configurazione",
      "configurationcreated": "Configurazione creata",
      "configurationdeletion": "Cancellazione della configurazione",
      "configurationdeleted": "Configurazione cancellata",
      "attach-ip": "Inserisci ip",
      "detach-ip": "Rimuovi ip",
      "attach": "Inserisci",
      "attached": "Inserito",
      "detach": "Rimuovi",
      "detached": "Rimosso",
      "install": "Completa",
      "installed": "Installato",
      "uninstalled": "Disinstallato",
      "unpaid": "In attesa di pagamento",
      "load-iso": "ISO Loaded",
      "loadiso": "ISO Loaded",
      "unload-iso": "ISO Unloaded",
      "unloadiso": "ISO Unloaded",
      "up": "Up",
    },
    "requests": {
      "title": "Operazione richiesta ",
      "action": "Azione ",
      "user": "Utente ",
      "ip": "IP ",
      "create": "Creazione",
      "delete": "Cancellazione",
      "stop": "Interruzione",
      "start": "Avvio",
      "reboot": "Riavvio",
      "restore": "Ripristino",
      "refresh": "Aggiorna ",
      "hardstop": "Interruzione forzata",
      "reset": "Reinizializzazione",
      "terminate": "Disattivazione",
      "attach": "Inserisci",
      "attached": "Inserito",
      "detach": "Rimuovi",
      "detached": "Rimosso",
      "install": "Installa",
      "installed": "Installato",
      "rename": "Rinominare",
      "decrease": "Diminuzione",
      "increase": "Aumento",
      "update": "Aggiornare",
      "disable-drp": "Disattivazione P.R.A. in corso",
    },
    "status": {
      "title": "Stato:",
      "success": "riuscito",
      "error": "non riuscito",
    },
    "entities": {
      "changestate": "Server",
      "serverupdate": "Server",
      "serverdeletion": "Server",
      "servercreation": "Server",
      "serveraddons": "Server",
      "serverreset": "Server",
      "ipkvm": "KVM IP",
      "snapshot": "Snapshot",
      "network": "Rete privata",
      "storageupdate": "Storage pool"
    },
    "pending-actions": {
      "key": "Azione/i in attesa di riavvio: ",
      "info": "È necessario riavviare tramite l'interfaccia web",
      "addons": "installazione add-on",
      "load_iso": "montaggio ISO",
      "unload_iso": "smontaggio ISO",
      "notification": "aggiornamento",
    },
    "notifications": {
      "addons": "Riavvia il server dall'interfaccia affinché gli addon abbiano effetto.",
      "mount_iso": "Riavvia il server dall'interfaccia e avvia il tuo accesso remoto.",
      "plesk": "La tua licenza Plesk è stata modificata. Troverai il nuovo codice di attivazione nelle informazioni dettagliate della licenza.",
      "user_agreement": "Non mostrare più questo messaggio",
    },
    "server-page": "Pagina server",
    "no-addons": "Nessuna azione possibile. Il conto di questo server non è stato pagato.",
    "gauge": {
      "menu": {
        "title": "Modelli",
        "model": {
          "bar": "Barra",
          "arc": "arco",
          "circle": "cerchio",
          "stroke": "colpi",
        },
        "change-model": "Cambiare modello",
      },
      "save": {
        "button": {
          "save-server": "Preferenze del server",
          "save-user": "Preferenze utente",
        },
        "tooltip": {
          "save-server": "Salva come preferenze per questo server",
          "save-user": "Salva come preferenze predefinite",
        },
        "message": {
          "successful": "Backup riuscito",
          "unsuccessful": "Impossibile salvare",
        },
      },
    },
    "chart": {
      "activity": "Attività {type} ({unit})",
      "tooltip": [
        "Zoom Avanti",
        "Zoom Indietro",
        "Aggiorna",
        "Non riempire l'area del grafico",
        "Riempi l'area del grafico"
      ]
    },
    "tooltips": {
      "delete": "Eliminazione immediata del server",
      "show-company": "Visualizza informazioni sull'azienda",
    },
    "drp-text": [
      "A causa dell'alta popolarità del nostro Piano di Ripristino in caso di Disastro (PDR), ci dispiace informarvi che la sua attivazione non è momentaneamente disponibile.",
      "Vi preghiamo di riprovare più tardi o di contattare il nostro team di supporto tecnico per assistenza immediata.",
      "Ci scusiamo per eventuali inconvenienti e vi ringraziamo per la vostra comprensione.",
      "Cordiali saluti,",
      "Il team di Titan SmallCloud",
      "+33(0)9 88 99 88 20"
    ],
    "alert-text": [
      "Questa funzionalità è momentaneamente non disponibile.",
      "Stiamo lavorando attivamente per ripristinarla al più presto.",
      "Ci scusiamo per eventuali inconvenienti e apprezziamo la vostra comprensione.",
      "Cordiali saluti,",
      "Il Team di Titan SmallCloud",
      "+33(0)9 88 99 88 20"
    ],
    "uptime": {
      "title": "Tempo di attività",
      "down": "Down",
      "up": "Up",
      "pending": "In attesa",
      "checkEverySecond": "Controlla ogni {0} secondi",
      "days": "giorni",
      "hours": "ore",
      "latency": "Latency",
    },
    "managed-service": {
      "title": "Livelli di Gestione Esternalizzata",
      "add": "Aggiungi servizio gestito",
      "update": "Cambia livello di servizio gestito",
      "terminate": "Terminare i Servizi Gestiti",
      "goto-server": "Visualizza Server",
    },
    "drp-modal": {
      "header": [
        "Server",
        "Prezzo",
      ],
      "total": "Costo totale del tuo DRP",
      "prefix": "DRP_",
    },
    "license-info": {
      "button-info": "Informazioni Dettagliate",
      "button-change": "Cambia",
      "title-change": "Cambia Licenza",
      "id": "ID Licenza",
      "code": "Articolo",
      "key": "Codice di Attivazione",
      "in-cart": "Licenza già nel carrello",
      "empty": "Nessuna licenza",
      "plesk": {
        "description": "Descrizione",
        "code": "Codice",
        "server": "Server"
      },
    },
    "os-modification": {
      "popup": "Modifica il sistema operativo"
    },
  },
  "event_log": {
    "requests": {
      "title": "Operazione richiesta ",
      "action": "Azione ",
      "user": "Utente ",
      "server": "Server ",
      "old_event": "Vecchio evento"
    },
    "type": {
      "network": "Rete",
      "ip": "Indirizzo IP",
      "iso": "ISO",
      "configuration": "Configurazione",
      "snapshot": "Snapshot",
      "note": "Nota",
      "image": "Immagine",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disco",
      "vswitch": "Vswitch",
      "addons": "Aggiungi su",
      "state": "Stato",
      "ipkvm": "KVM",
      "changestate": "Server - I cambiamenti",
      "servercreation": "Server - Creazione",
      "createserver": "Server - Creazione",
      "serverdeletion": "Server - Cancellazione",
      "serverupdate": "Server - Aggiornare",
      "serveraddons": "Server - Addon(s)",
      "migration": "Migrazione dell'hypervisor",
      "serveroperations": "Server - Operazioni",
      "storageupdate": "Disco - Aggiornare",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Autenticazione",
      "material": "Materiale",
      "hypervisor": "Ipervisore",
      "payment_method": "Metodo di pagamento",
      "company": "Società",
      "user": "Utente",
      "invitation": "Invito",
      "role": "Ruolo",
      "cron": "Compito",
      "accounting_code": "Numero di conto",
      "outsourcing": "Gestione esternalizzata",
      "order": "Ordine",
      "drp": "D.R.P.",
    },
    "subtype": {
      "network": "Rete",
      "ip": "Indirizzo IP",
      "iso": "ISO",
      "configuration": "Configurazione",
      "snapshot": "Snapshot",
      "note": "Nota",
      "image": "Immagine",
      "server": "Server",
      "kvmip": "KVM",
      "console": "Console",
      "storage": "Disco",
      "vswitch": "Vswitch",
      "addons": "Aggiungi su",
      "state": "Stato",
      "two_fa": "2FA",
      "ssh": "Ssh",
      "authentication": "Autenticazione",
      "material": "Materiale",
      "hypervisor": "Ipervisore",
      "payment_method": "Metodo di pagamento",
      "company": "Società",
      "user": "Utente",
      "invitation": "Invito",
      "role": "Ruolo",
      "cron": "Compito",
      "accounting_code": "Numero di conto",
      "drp": "D.R.P.",
    },
    "action": {
      "load": "Caricato",
      "unload": "Scaricato",
      "mount": "Montato",
      "unmount": "Smontato",
      "create": "Creazione",
      "creation": "Creazione",
      "created": "Creato",
      "attach": "Allegato",
      "attached": "Allegato",
      "detach": "Distacco",
      "detached": "Distaccato",
      "start": "Inizio",
      "started": "Cominciato",
      "stop": "Fermare",
      "stopping": "Fermarsi",
      "stopped": "Fermato",
      "resume": "Riprendere",
      "resumed": "Ripreso",
      "reset": "Ripristina",
      "increase": "Aumento",
      "decrease": "Diminuire",
      "update": "Aggiornare",
      "updated": "Aggiornare",
      "cancel": "Annulla",
      "delete": "Cancellazione",
      "deletion": "Cancellazione",
      "deleted": "Eliminato",
      "upgrade": "Aggiornamento",
      "upgraded": "Aggiornato",
      "downgrade": "Donwgrade",
      "downgraded": "Declassato",
      "install": "Installazione",
      "installed": "Installato",
      "uninstall": "Disinstallazione",
      "uninstalled": "Disinstallato",
      "restore": "Ristabilire",
      "restored": "Restaurato",
      "rename": "Rinominare",
      "renamed": "Rinominato",
      "configuration": "Configurazione",
      "configured": "Configurato",
      "disable": "disattivare",
      "disabled": "Disabilitato",
      "enable": "Attivazione",
      "enabled": "Attivato",
      "hardstop": "Fermata difficile",
      "reboot": "Riavviare",
      "suspend": "Sospensione",
      "revoked": "Revocato",
      "activate": "Attivare",
      "activated": "Attivato",
      "deactivate": "Disattivare",
      "deactivated": "Disattivato",
      "termination": "Risoluzione",
    },
    "status": {
      "title": "Stato:",
      "success": "riuscito",
      "error": "non riuscito",
    },
    "messages": {
      "material_hypervisor_enable": "Hypervisor abilitato",
      "material_hypervisor_disable": "Ipervisore disabilitato",
      "authentication_two_fa_enabled": "Autenticazione 2FA abilitata",
      "authentication_two_fa_disabled": "Autenticazione 2FA disabilitata",
      "authentication_ssh_create": "Autenticazione SSH creata",
      "authentication_ssh_created": "Autenticazione SSH creata",
      "authentication_ssh_delete": "Autenticazione SSH eliminata",
      "authentication_ssh_deleted": "Autenticazione SSH eliminata",
      "authentication_ssh_update": "Autenticazione SSH aggiornata",
      "authentication_ssh_updated": "Autenticazione SSH aggiornata",
      "ip_attach": "Richiesta di assegnazione IP",
      "ip_detach": "Richiesta di disassegnazione IP",
      "ip_attached": "Indirizzo IP assegnato",
      "ip_detached": "Indirizzo IP disassegnato",
      "ip_updated": "Indirizzo IP aggiornato",
      "ip_termination": "Richiesta di terminazione IP",
      "ip_cancel_termination": "Richiesta di terminazione IP annullata",
      "server_iso_mount": "Montaggio immagine",
      "server_iso_unmount": "Smonta immagine",
      "server_iso_mounted": "Immagine ISO del server montata",
      "server_iso_unmounted": "Immagine ISO del server non montata",
      "server_iso_create": "Immagine ISO del server creata",
      "server_iso_deletion": "Immagine ISO del server eliminata",
      "server_addons_upgrade": "Aggiornamento del server",
      "server_addons_downgrade": "downgrade del server",
      "server_network_enabled": "Rete server abilitata",
      "server_network_disabled": "Rete server disabilitata",
      "server_configuration_creation": "Creazione della configurazione del server",
      "server_configuration_created": "Configurazione server creata",
      "server_configuration_deletion": "Eliminazione configurazione server",
      "server_configuration_deleted": "Configurazione server eliminata",
      "server_storage_creation": "Creazione spazio su disco",
      "server_storage_created": "Spazio su disco creato",
      "server_storage_configured": "Spazio su disco configurato",
      "server_storage_deletion": "Eliminazione spazio su disco",
      "server_storage_deleted": "Spazio su disco eliminato",
      "server_termination": "Richiesta di terminazione",
      "server_cancel_termination": "Richiesta di terminazione annullata",
      "server_state_started": "Server avviato",
      "server_state_stopped": "Server fermo",
      "server_state_resumed": "Server riavviato",
      "server_state_stopping": "Server in arresto",
      "server_start": "Server in avvio",
      "server_stop": "Arresto del server",
      "server_update": "Server aggiornato",
      "server_updated": "Server aggiornato",
      "server_renamed": "Server rinominato",
      "server_create": "Server creato",
      "server_started": "Server avviato",
      "server_stoppato": "Server fermo",
      "server_resumed": "Server ripreso",
      "server_stopping": "Server in arresto",
      "server_hardstop": "Arresto forzato del server",
      "server_reboot": "Riavvio del server",
      "storage_snapshot_create": "Creazione istantanea",
      "storage_snapshot_restore": "Ripristino snapshot",
      "storage_snapshot_deletion": "Eliminazione istantanea",
      "storage_snapshot_created": "Istantanea creata",
      "storage_snapshot_restored": "Istantanea ripristinata",
      "storage_snapshot_deleted": "Istantanea eliminata",
      "storage_snapshot_updated": "Istantanea aggiornata",
      "storage_image_create": "Creazione di un'immagine",
      "storage_image_delete": "Eliminazione di un'immagine",
      "storage_image_created": "Immagine creata",
      "storage_image_deleted": "Immagine eliminata",
      "storage_image_updated": "Immagine aggiornata",
      "storage_decreased": "Spazio su disco diminuito",
      "storage_increased": "Spazio su disco aumentato",
      "note_updated": "Nota aggiornata",
      "console_kvmip_start": "Console IpKvm avviata",
      "console_kvmip_stop": "Interruzione console IpKvm",
      "console_kvmip_update": "Console IpKvm aggiornata",
      "console_kvmip_started": "Console IpKvm avviata",
      "console_kvmip_stopped": "Console IpKvm interrotta",
      "console_kvmip_stopping": "Console IpKvm in arresto",
      "network_vswitch_attach": "Collega Vswitch alla rete",
      "network_vswitch_detach": "Distacco switch virtuale di rete",
      "network_vswitch_attached": "Vswitch collegato alla rete",
      "network_vswitch_worthy": "Vswitch scollegato dalla rete",
      "network_vswitch_create": "Creazione di uno switch virtuale",
      "network_vswitch_created": "Vswitch creato",
      "network_vswitch_delete": "Eliminazione di uno switch virtuale",
      "network_vswitch_deleted": "Vswitch eliminato",
      "network_vswitch_update": "Aggiornamento switch virtuale",
      "network_vswitch_updated": "Vswitch aggiornato",
      "payment_method_created": "Metodo di pagamento aggiunto",
      "createserver": "Creazione del server",
      "metric_cpu_alert": "Attività CPU",
      "metric_ram_alert": "Attività RAM",
      "metric_disk_alert": "Attività del Disco",
      "metric_net_alert": "Attività di Rete",
      "company_created": "Azienda creata",
      "company_update": "Aggiornamento delle informazioni aziendali",
      "company_updated": "Informazioni aziendali aggiornate",
      "company_invitation_create": "Creazione di un invito",
      "company_invitation_created": "Invio di un invito",
      "company_invitation_delete": "Eliminazione di un invito",
      "company_invitation_deleted": "Invito eliminato",
      "company_user_revoke": "Revoca di un utente",
      "company_user_revoked": "Utente revocato",
      "company_user_delete": "Eliminazione di un utente",
      "company_user_deleted": "Utente eliminato",
      "company_user_join": "Tentativo di unirsi all'azienda",
      "company_user_joined": "L'utente si è unito all'azienda",
      "company_role_create": "Creazione di un ruolo",
      "company_role_created": "Ruolo creato",
      "company_role_delete": "Eliminazione di un ruolo",
      "company_role_deleted": "Ruolo eliminato",
      "company_role_update": "Aggiornamento di un ruolo",
      "company_role_updated": "Ruolo aggiornato",
      "cron_reboot_create": "Programmazione di un'attività di riavvio",
      "cron_reboot_created": "Attività di riavvio programmata",
      "cron_reboot_delete": "Eliminazione di un'attività di riavvio",
      "cron_reboot_deleted": "Attività di riavvio eliminata",
      "cron_reboot_update": "Aggiornamento di un'attività di riavvio",
      "cron_reboot_updated": "Attività di riavvio aggiornata",
      "cron_reboot_activate": "Attivazione di un'attività di riavvio",
      "cron_reboot_activated": "Attività di riavvio attivata",
      "cron_reboot_deactivate": "Disattivazione di un'attività di riavvio",
      "cron_reboot_deactivated": "Attività di riavvio disattivata",
      "cron_snapshot_create": "Programmazione di un'attività di snapshot",
      "cron_snapshot_created": "Attività di snapshot programmata",
      "cron_snapshot_delete": "Eliminazione di un'attività di snapshot",
      "cron_snapshot_deleted": "Attività di snapshot eliminata",
      "cron_snapshot_update": "Aggiornamento di un'attività di snapshot",
      "cron_snapshot_updated": "Attività di snapshot aggiornata",
      "cron_snapshot_activate": "Attivazione di un'attività di snapshot",
      "cron_snapshot_activated": "Attività di snapshot attivata",
      "cron_snapshot_deactivate": "Disattivazione di un'attività di snapshot",
      "cron_snapshot_deactivated": "Attività di snapshot disattivata",
      "accounting_code_create": "Creazione numero di conto",
      "accounting_code_created": "Numero di conto creato",
      "accounting_code_delete": "Cancellazione numero di conto",
      "accounting_code_deleted": "Numero di conto cancellato",
      "api_token_create": "Creazione di una chiave API",
      "api_token_created": "Chiave API creata",
      "api_token_delete": "Cancellazione di una chiave API",
      "api_token_deleted": "Chiave API cancellata",
      "api_token_update": "Aggiornamento di una chiave API",
      "api_token_updated": "Chiave API aggiornata",
      "outsourcing_termination": "Richiesta di risoluzione dei servizi gestiti",
      "outsourcing_cancel_termination": "Richiesta di risoluzione dei servizi gestiti eliminata",
      "outsourcing_deleted_termination": "Servizi gestiti risolti",
      "vswitch_ports_increase": "Porte Aggiunte al VSwitch",
      "order_deletion": "Cancellazione dell'Ordine",
      "server_drp_activated": "DRP Attivato",
      "server_drp_deactivated": "DRP Disattivato",
      "server_drp_activate": "Richiesta di attivazione P.R.A.",
      "server_drp_deactivate": "Richiesta di disattivazione P.R.A.",
      "server_maintenance_level_update": "Aggiornamento del Servizio Gestito",
      "server_service_managed_update": "Aggiornamento del Servizio Gestito",
      "server_maintenance_level_updated": "Servizio Gestito Aggiornato",
      "server_service_managed_updated": "Servizio Gestito Aggiornato",
      "server_plesk_update": "Aggiornamento della licenza Plesk",
      "server_plesk_updated": "Licenza Plesk aggiornata",
    },
  },
  "intervention": {
    "title": "Servizi"
  },
  "pages": {
    "devis": {
      "title": "Citazione",
      "number": "Numero",
      "commercial": "Commerciale ",
      "created_at": "Create on",
      "expire_at": "Creato il",
      "amount": "Importo",
      "tooltip-download": "Scarica il pdf",
      "tooltip-cart": "Aggiungi al carrello",
      "empty": "Nessuna citazione per il momento.",
      "tooltip-accept": "Accetta il preventivo",
      "validate_at": "Accettato il ",
      "accept": "Accettare",
      "invoice_number": "Documento ",
      "details": "N° preventivo ",
      "quote_date": "Data di preventivo ",
      "quote_validity": "Valido fino a ",
      "create_quote": "Crea un preventivo",
      "company": "Azienda",
      "tooltip-delete": "Elimina citazione",
    },
    "lang": "it",
    "ip-histo": {
      "empty": "La storia è vuota.",
      "company_uuid": "Società",
      "server_uuid": "Server",
      "start_date": "Data d'inizio",
      "end_date": "Data di fine"
    },
    "login": {
      "title": "Accedere",
      "email-label": "E-mail",
      "password-label": "Parola d'ordine",
      "login-action": "Accedere",
      "email-not-validated": "Ti preghiamo di convalidare l'e-mail prima di effettuare il login",
      "email-confirmation-sent": "Ti abbiamo inviato un'e-mail di conferma (per favore controlla anche i tuoi spam).",
      "promotional_code_saved": "Il codice promozionale è stato salvato"
    },
    "login2fa": {
      "title": "Autenticazione a due fattori",
      "tfa-label": "Codice di sicurezza"
    },
    "register": {
      "company_invite_title": "Benvenuto/a su Titan SmallCloud! Per finalizzare il tuo account, ti preghiamo di fornirci alcune informazioni. Queste informazioni rimarranno riservate e puoi chiederci di rimuoverle in qualsiasi momento.",
      "title": "Per implementare le tue richieste e fornirti il miglior servizio possibile abbiamo bisogno che tu ci fornisca alcune informazioni. Queste informazioni rimarranno riservate e puoi chiederci di rimuoverle in qualsiasi momento.",
      "action": "Crea un account",
      "billing-address": "Indirizzo di fatturazione",
      "modal": {
        "title": "Hai cambiato le informazioni precedentemente compilate.",
        "danger": "",
        "message": "",
        "button": "Confermare",
        "extra": "Potete confermare questi cambiamenti?",
      },
    },
    "dashboard": {
      "status-map": "Mappa di stato TITAN",
      "status-page": "Stato",
      "favorite": "I tuoi preferiti",
      "notifications": "Notifications",
      "overview-server": "Panoramica server",
      "overview-resource": "Le Mie Risorse",
      "status": {
        "bandwidth": "Larghezza di banda",
        "v4-peers-visibility": "V4 peer visibility",
        "v6-peers-visibility": "V6 peer visibility",
        "ping-latency": "Latenza"
      },
      "weather-cpu-ram": "CPU/RAM",
      "weather-public-network": "Rete pubblica (WAN)",
      "weather-private-network": "Rete privata (LAN)",
      "weather-storage": "Memorizzazione",
      "pending_servers": "Server in attesa di essere configurati",
      "last_op": "Ultime operazioni",
      "title-tickets": "I tuoi ultimi ticket",
      "title-history": "Le tue ultime operazioni",
      "weather-ras": "OK",
      "weather-degraded": "Retrocesso",
      "weather-failure": "Critico",
      "started-server": "Server avviati : ",
      "stopped-server": "Server fermi : ",
      "other-server": "server in attesa : ",
      "ipv4-active": "IPv4 attivi: ",
      "ipv4-free": "IPv4 disponibili: ",
      "ipv6-active": "IPv6 attivi: ",
      "ipv6-free": "IPv6 disponibili: ",
      "disk-space": "Spazio di archiviazione disponibile: ",
      "disk-used": "Spazio di archiviazione utilizzato: ",
      "modal": {
        "you_will_be_redirected_to": "Verrai reindirizzato a",
        "status_link": "https://titandc.statuspage.io/",
      },
      "popup": [
        "Stato Titan Datacacenter"
      ],
    },
    "servers": {
      "mac": "indirizzo MAC",
      "plan": {
        "SC1": "SC1",
        "SC2": "SC2",
        "SC3": "SC3"
      }
    },
    "server-new": {
      "devis-mode": {
        "false": "Activate the quote generation mode",
        "true": "Deactivate the generation mode of an estimate",
      },
      "title": "Configura il tuo server",
      "choose-managed": "Scegli la modalità di gestione della rete",
      "choose-plan": "Scegli le tue risorse",
      "choose-os": "Scegli il tuo sistema operativo",
      "setup-ssh": "Configura la connessione al tuo server",
      "licenses": "Licenza",
      "bandwidth": "Larghezza di banda",
      "managed-true": "Rete gestita",
      "managed-false": "Rete dedicata",
      "managed-true-text": "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.",
      "managed-false-text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "iops": "QoS disco : ",
      "ssh-password": "Login tramite password",
      "ssh-password-input": "Inserisci una password",
      "ssh-key": "Login tramite chiave SSH",
      "ssh-key-input": "Inserisci la tua chiave per poter eseguire il login a ",
      "ssh-key-public-msg": "Per utilizzare delle chiavi SSH è necessario effettuare il login.",
      "ssh-key-hint": "Inserisci la tua chiave pubblica SSH.",
      "ssh-key-placeholder": "Chiave pubblica",
      "confirm": "Conferma",
      "finalize": "Finalizza l'ordine",
      "order-plan": "Ordinazione di un server ",
      "order-with": "con il sistema operativo",
      "order-preinstalled": "preinstallato",
      "order-button": "Ordina per ",
      "month": "mese IVA escl.",
      "duty-free": "IVA escl.*",
      "recurring_description": "* L'importo verrà aggiunto alla fatturazione periodica attuale",
      "tooltip": "Inserisci una password o una chiave SSH",
      "tooltip_password": "Inserisci una password",
      "tooltip_windows": "Windows è disponibile a partire dai SC2.",
      "tooltip_windows_to_windows": "Windows può solo migrare a Window.",
      "tooltip_license": "Non è possibile cambiare il tipo di licenza.",
      "tooltip_add": "Aggiungi al carrello",
      "tooltip_packetfence": "PacketFence è disponibile solo su un SC3.",
      "order-count": "Non hai ordini… | C'è un ordine nel tuo carrello… | Hai {nb} ordini nel carrello…",
      "order-added": "Ordine aggiunto…",
      "order-cart": "Visualizza il carrello",
      "order-reset": "Vuota il carello",
      "version-placeholder": "Versione",
      "plesk-title": "Selezione della licenzas plesk",
      "plesk-selection": "Selezione la tua licenza:",
      "unit-price": "Prezzo per unità:",
      "os": "Sistema operativo",
      "qty": "Qtà.:",
      "pwd_hint": "Scegliere una password e/o una chiave SSH",
      "pwd_and_ssh": "Scegliere una chiave SSH e/o una password",
      "cpu-desc": " ",
      "ram-desc": " ",
      "disk-desc": " ",
      "next-step-btn": "Prossimo passo",
      "promo_code": "Codice sconto",
      "unit": {
        "from_to_gb": "{ from } GB a { to } GB",
        "from_to_cpu": "{ from } vCPU a { to } vCPU",
      },
      "update-os": "Nuovo Sistema Operativo",
    },
    "server-detail": {
      "ips": "Indirizzi IP",
      "note": "Appunto",
      "note-edit": "Modifica l'appunto",
      "note-save": "Salva l'appunto",
      "note-help": "Puoi modificare questo appunto con Markdown (aiuta cliccando qui)",
      "note-default": "Questo server è stato installato con **{template}** il {creation_date}",
      "note-empty": "Nessuna nota",
      "activity": "Attivato",
      "activity-empty": "Ancora nessuna attività.",
      "graph-reset": "reinizializza il grafico",
      "network-activity": "Attività rete",
      "ram-activity": "Attività RAM",
      "cpu-activity": "Attività CPU",
      "disk-activity": "Spazio sul disco",
      "Vswitch-activity": "Attività Vswitch",
      "history": "Cronologia",
      "last-activity": "Ultime attività recenti",
      "server-history": "Cronologia server",
      "no_ip": "Nessun indirizzo IP",
      "snapshots": "Snapshot",
      "snapshot-create": "Crea uno snapshot",
      "snapshot-restore": "Ripristina",
      "snapshot-delete": "Cancella",
      "snapshot-to-template": "Immagine",
      "snapshot-to-template-modal": {
        "title": "Generare un'immagine da questa istantanea",
        "name": "Nome dell'immagine"
      },
      "snapshot-empty": "Nessuno snapshot…",
      "snapshot-error": "Snapshot non riuscito",
      "snapshot-schedule": "Programmare uno Snapshot",
      "terminate-success": "Disattivazione server in corso.",
      "terminate-canceled": "Disattivazione del server annullata.",
      "modal-server-downgrade-nothing": "Nessun componente aggiuntivo da declassare.",
      "modal-need-stop-title": "Disattivazione del server",
      "modal-need-stop-text": "Interruzione del server richiesta.",
      "modal-need-stop-description": "Devi interrompere il server per effettuare l'operazione.",
      "modal-need-stopped-description": "Ti preghiamo di attendere che il server venga interrotto per effettuare l'operazione.",
      "modal-server-upgrade-description": "Questo aggiornamento sarà completo solo dopo un riavvio del server attivato dalla dashboard.",
      "modal-mount-cdrom-title": "Montaggio CD-ROM",
      "modal-mount-cdrom-text": "Un CD-ROM può essere caricato sul server come file ISO remoto. Il server si avvierà quindi prioritariamente sul CD-ROM fino a quando il CD-ROM non sarà stato smontato (riavvio richiesto). La configurazione della tua ISO viene eseguita dal KVM.",
      "modal-mount-cdrom-label": "URL dell‘ISO avviabile da caricare (https unicamente):",
      "modal-mount-cdrom-list-label": "Elenco di ISO predefiniti",
      "modal-mount-cdrom-list-placeholder": "— Seleziona una ISO —",
      "modal-unmount-cdrom-title": "Smontaggio CD-ROM",
      "modal-unmount-cdrom-text": "Questa operazione smonterà il CD-ROM caricato in precedenza. Se la macchina è attualmente avviata dal CD-ROM, allora sarà necessario effettuare un'interruzione forzata della macchina per rendere effettivo lo smontaggio.",
      "modal-server-downgrade-description": "La rimozione dei componenti aggiuntivi verrà eseguita immediatamente dopo la convalida.",
      "see-all-server": "Mostra i server",
      "terminate-descriptions-title": "Qual è il motivo di questa risoluzione ?",
      "terminate-descriptions-placeholder": "— Motivi —",
      "terminate-descriptions": {
        "over": "El proyecto asociado a este producto ha terminado.",
        "unsuitable": "El producto no es adecuado para mis necesidades.",
        "quality": "La calidad del servicio (averías, ralentizaciones, etc.) no cumple con mis expectativas.",
        "assistance": "La asistencia en línea (servicio al cliente, preguntas frecuentes, soporte, etc.) no me permite administrar correctamente mis servicios.",
        "expensive": "Los productos son demasiado caros.",
        "loyalty": "Falta de reconocimiento (programa de fidelización, bajada de precios, etc.).",
        "mistake": "Cometí un error al realizar el pedido (recursos incorrectos, pedido doble, etc.).",
      },
      "goto_images": "Le mie immagini",
      "modal-addon-exists": "Un aggiornamento è già stato aggiunto al carrello",
      "modal-actual-addon": "(Actual { quantity } { type })",
      "modal-none": "Nessuno",
      "addon-added": "Componenti aggiuntivi aggiunti al carrello",
      "modal-no-ip": [
        "Impossibile terminare il server",
        "La terminazione è possibile solo se il server ha almeno un indirizzo IPv4 associato.",
        "Questa IPv4 deve avere un corrispondente IPv6 che non è associato a un altro server ed è parte del tuo pool di indirizzi.",
        "Si prega di andare nella tua interfaccia di rete e associare un indirizzo IPv4 a questo server.",
        "Grazie.",
      ],
    },
    "faq": {
      "title": "FAQ",
      "updated": "Aggiornamento"
    },
    "payment-list": {
      "create-payment": "Creare un pagamento",
      "date": "Data",
      "number": "Numero di pagamento",
      "ref": "Riferimento",
      "facture": "Fatture correlate",
      "mode": "Regolato da",
      "total-amount": "Importo",
      "charged-amount": "Importo addebitato",
      "unused-amount": "Importo non utilizzato",
      "client_code": "Codice cliente",
      "client": "Cliente",
      "no_payment": "Nessun pagamento trovato",
      "regularized_by": "Regolarizzato dal/i pagamento/i {document_number}",
      "modes": {
        'VSEPA': "Trasferimento",
        'PSEPA': "Debit",
        'CB': "CB",
        'CREDIT': "Credito",
        'UNPAID': "Non pagato",
      },
      "states": {
        'title': "Stato",
        'waiting': "In attesa di",
        'processing': "In elaborazione",
        'succeeded': "Successo",
        'success': "Successo",
        'error': "Errore",
        'canceled': "Cancellato",
        'requires_action': "Azione",
        "regularized": "Regolarizzato",
      },
      "date-range": {
        'month': 'Mese',
        'all': 'Tutto',
        'last-month': 'Lo scorso mese',
        'last-quarter': 'Ultimo quarto',
        'format': 'yyyy/MM/dd',
        'apply': 'Applicare',
        'cancel': 'Annulla',
        'weekLabel': 'W',
        'customRange': 'Gamma personalizzata',
        'daysOfWeek': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'monthNames': ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        'monthNamesFull': ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      }
    },
    "account": {
      "vat": "N. di partita IVA intracomunitaria",
      "title": "Parametri dell'account",
      "personal_infos": "Informazioni personali",
      "civility": "Stato civile",
      "civility-mr": "Signor",
      "civility-mme": "Signora",
      "civility-mx": "Signx",
      "first-name": "Nome",
      "last-name": "Cognome",
      "name": "Cognome",
      "email": "E-mail",
      "phone": "Telefono",
      "phone-country": "Codice paese",
      "phone-country-error": "Scegliere un paese",
      "phone-ex": "Esempio:",
      "mobile": "Cellulare",
      "add-phone": "Aggiungi un numero di telefono",
      "save": "Salva",
      "languages": "Lingua",
      "interface-mode": "Colore dell'interfaccia",
      "current-password": "Password attuale",
      "new-password": "Nuova password",
      "confirm-new-password": "Conferma la nuova password",
      "password": "Password",
      "confirm-password": "Conferma password",
      "settings": "Preferenze",
      "company_infos": "Informazioni sull'azienda",
      "company-name": "Nome dell'azienda",
      "company-position": "Titolo",
      "company-id": "D-U-N-S",
      "tva-number": "N. di partita IVA intracomunitaria",
      "address": "indirizzo",
      "address-name": "Nome",
      "address-street": "Via",
      "address-postal-code": "Codice postale",
      "address-city": "Città",
      "address-country": "Paese",
      "add-address": "Aggiungi un indirizzo",
      "password-mismatch": "La password e la conferma non corrispondono",
      "recaptcha-error": "Devi convalidare il recaptcha.",
      "password-hint": "La password dev'essere lunga 8 caratteri (maiuscole, minuscole, numeri).",
      "legal-info": "Creando il tuo account beneficerai dei servizi Titan Datacenters France.\nI dati comunicati tramite questo modulo verranno raccolti solo in base al tuo consenso e sono destinati a Titan Datacenters France in qualità di responsabile del trattamento.\n\nTali informazioni e quelle dei tuoi ordini verranno trattate principalmente per creare e gestire il tuo account, per gestire gli ordini e per il loro tracking, per personalizzare i servizi, per prevenire frodi e a fini di analisi statistica, nonché per scopi di marketing e di pubblicità mirata.\nTale trattamento è fondato su diverse basi giuridiche a seconda delle apposite finalità.\n\nAvrai la possibilità in qualsiasi momento di annullare il consenso.",
      "legal-notice": "Avvertenze legali",
      "privacy-policy": "Informativa sulla privacy",
      "discount": "Tipo di abbonamento",
      "auto_debit": "Ritiro alla fine del mese:",
      "disabled": "Azienda disabilitata:",
      "renew_with_credits": "Usa i crediti quando rinnovi gli abbonamenti:",
      "reset": "Resettare",
      "affiliate_infos": "Programma di affiliazione (facoltativo)",
      "affiliate-code": "Codice di affiliazione (facoltativo)",
      "code": "Codificato",
      "status": "Stato",
      "balance": "Equilibrio",
      "vatConfirmed": "Partita IVA confermata",
      "vatNotConfirmed": "IVA non valida non ancora confermata",
      "modal": {
        "title": "Contatto",
        "message_invalid": "Dati non validi",
        "message_error": "Errore di salvataggio",
        "message_success": "Contatto aggiornato con successo",
      },
      "send_invoice": "Invio automatico delle fatture via email (Super Admin + Contabili)",
      "creation_date": "Creato il",
      "update_date": "Aggiornato il",
      "oid": "OID",
      "affiliation_code": "Codice di affiliazione",
      "server-detail-closed": "Dettagli del server chiusi",
      "server-detail-help": "Nel profilo del server, tieni aperto o chiudi il pannello delle informazioni dettagliate",
      "create-new-company": "Crea una nuova azienda",
      "invoice_payment_grouped": "Pagamento unico per più fatture",
      "sms_alert": "Autorizzazione all'invio di SMS",
      "single_invoice": "Raggruppare gli abbonamenti in un'unica fattura",
    },
    "account-ssh": {
      "title": "Chiavi SSH",
      "management": "Gestisci le chiavi SSH",
      "ssh-key-name": "Nome",
      "ssh-key-usage": "Numero di usi",
      "ssh-key-value": "Chiavi SSH",
      "ssh-key-add": "Aggiungi una chiave SSH"
    },
    "account-api": {
      "title": "Chiavi API",
      "api-keys": "Chiavi API",
      "api-key-name": "Nome",
      "api-key-token": "Chiave API",
      "api-key-expire": "Data di scadenza",
      "api-key-add": "Genera una chiave API"
    },
    "account-2fa": {
      "title": "Autenticazione a due fattori (2FA)",
      "actions": {
        "enable": "Attiva la 2FA",
        "disable": "Disattiva la 2FA",
      },
      "enable": {
        "step1": {
          "title": "Salva la chiave segreta di recupero",
          "text": "Ti preghiamo di annotare e tenere accuratamente da parte la seguente chiave di recupero. Potrà tornarti utile, ad esempio in caso di smarrimento del telefono."
        },
        "step2": {
          "title": "Scansiona il QR code tramite un'app TOTP",
          "text": "È sufficiente scansionare il seguente QR code tramite la tua app TOTP preferita (Authy, Google Authenticator, ecc.)"
        },
        "step4": {
          "title": "Scansiona il QR code tramite un'app TOTP",
          "text": "È sufficiente scansionare il seguente QR code tramite la tua app TOTP preferita (Authy, Google Authenticator, ecc.)"
        },
        "step3": {
          "title": "Inserisci il codice di autorizzazione a 6 cifre",
          "text": "Ti preghiamo d'inserire il seguente codice a 6 cifre che appare sull'app TOTP (i codici hanno una durata di vita limitata, bisogna quindi agire velocemente). "
        }
      },
      "disable": {
        "title": "Disattiva l'autenticazione a due fattori",
        "text": "L'autenticazione a due fattori (2FA) è attualmente attiva sul tuo account. Per disattivarla, inserisci la password e il codice di autenticazione a 6 cifre visualizzato sull'app TOTP.",
        "warning": "Il tuo codice di recupero attuale diventerà obsoleto. Se desideri riattivare la 2FA successivamente, ti verrà comunicato un nuovo codice di recupero."
      },
      "disable-success": "Disattivazione riuscita",
      "disable-error": "Errore durante la disattivazione",
    },
    "account-preferences": {
      "title": "Preferenze dell'account",
      "menu-1": "Le tue preferenze salvate",
      "cartModal": "Riepilogo dell'ordine:",
      "show": "Visualizzare",
      "hide": "Nascondere",
      "sorting-col": "Colonna di ordinamento:",
      "sorting-dir": "Direzione di ordinamento:",
      "invoices": "Visualizzazione della fattura:",
      "recurrings": "Visualizzazione degli abbonamenti:",
      "servers": "Visualizzazione del server:",
      "tooltips-hide": "Per visualizzare nuovamente il riepilogo del tuo ordine, vai alla sezione  \" Preferenze \" delle impostazioni del tuo account.",
      "sms-alert": "Ricevere notifiche via SMS",
      "sms-help": "Per ricevere SMS, l'autorizzazione all'invio deve essere attivata nella scheda 'Notifiche' della pagina aziendale. Assicurati di aver inserito un numero di telefono valido.",
    },
    "network": {
      "title": "Reti",
      "title-switch": "Vswitch",
      "title-ips": "Pool di IP",
      "no_ip": "Nessun indirizzo IP",
      "no_vswitch": "Nessun Vswitch",
      "help": "-",
      "name": "Nome",
      "ports": "Porte",
      "speed": "Velocità",
      "speed-with-unit": "Velocità in {unit}",
      "mtu-max": "MTU max",
      "hostname": "Nome dell'host",
      "comment": "Commento",
      "plan": "Piano",
      "mac": "Indirizzo MAC",
      "cidr": "CIDR",
      "gateway": "Gateway",
      "connected-servers": "Server connessi",
      "connect-button": "Connetti un server",
      "add-network": "Aggiungi un Vswitch",
      "add-vm": "Connetti un server",
      "add-success": "Switch virtuale creato correttamente",
      "delete-network": "Cancella questo Vswitch",
      "cant-delete-network": "Questo vswitch non può essere rimosso. I server sono collegati ad esso.",
      "delete-vm": "Rimuovi questo server",
      "delete-success": "Vswitch cancellato correttamente",
      "title-add-server": "Connetti un server",
      "server-attach-success": "Server aggiunto correttamente",
      "server-detach-success": "Server rimosso correttamente",
      "order-count": "Non hai ordini… | Non hai un IP nel carrello… | Non hai {nb} IP nel carrello…",
      "order-added": "Ordine aggiunto…",
      "order-cart": "Visualizza il carrello",
      "order-reset": "Svuotare il carrello",
      "modal-network-delete-title": "Cancella questa rete privata",
      "modal-network-delete-description": "Confermando l'operazione questo Vswitch verrà cancellato.",
      "modal-network-delete-confirm": "Vuoi davvero cancellare questo Vswitch?",
      "modal-server-detach-title": "Rimuovi questo server",
      "modal-server-detach-description": "Confermando l'operazione questo server verrà rimosso dal Vswitch.",
      "modal-server-detach-confirm": "Vuoi davvero rimuovere questo server?",
      "modal-server-detach-ip-title": "Scollega IP {ip}",
      "modal-server-detach-ip-description": "Confermando questa azione, questa IP verrà scollegata dal server.",
      "modal-server-detach-ip-confirm": "Sei sicuro di voler scollegare questa IP?",
      "modal-server-detach-ip-warning": "Potrai collegare questa IP a un altro server.",
      "modal-server-delete-ip-title": "Termina IP {ip}",
      "modal-server-delete-ip-description": "Confermando questa azione, questa IP verrà terminata.",
      "modal-server-delete-ip-confirm": "Sei sicuro di voler terminare questa IP?",
      "modal-server-delete-ip-warning": "Questa azione è reversibile.",
      "modal-network-managed-title": "Rete gestita",
      "modal-network-managed-label-name": "Nome delle rete",
      "modal-network-managed-label-gateway": "Gateway",
      "modal-network-managed-label-cidr": "CIDR",
      "reset-network": "Ripristina l'interfaccia di rete del server",
      "update": "Aggiorna Vswitch",
      "reset": "Reimposta",
      "add-ip": "Aggiungi un indirizzo IP",
      "goto-server": "Vai al server",
      "detach-ip": "Scollega questa IP",
      "attach-ip": "Collega questa IP",
      "server-ip-attach-success": "IP collegata con successo",
      "server-ip-detach-success": "IP scollegata con successo",
      "server-search": "Cerca un server. Doppio clic per mostrare l'elenco.",
      "update-reverse": "Aggiorna reverse",
      "update-ip": "Modifica dell'IP",
      "open-port-list": "Mostra elenco porte",
      "close-port-list": "Chiudi elenco porte",
      "ip-delete-success": "Terminazione dell'IP avvenuta con successo",
      "ip-delete-failed": "Terminazione dell'IP non riuscita",
      "ip-cancel-delete-success": "Annullamento della terminazione dell'IP effettuato con successo",
      "ip-added-to-cart": "IP aggiunta al carrello",
      "ip-already-in-cart": "IP già presente nel carrello",
      "ip-check": "Esegui controlli su questo indirizzo",
      "ip-summary": {
        "title": "Sommario",
        "header": [
          "IP allegati",
          "IPv4",
          "IPv6",
          "IP disponibili",
        ],
      },
      "title-choose-subscription": "Scegli l'abbonamento",
      "subtitle-choose-subscription": "Seleziona l'abbonamento per cui verrà terminato l'IP",
      "subscription-search": "Cerca un abbonamento. Doppio clic per mostrare l'elenco.",
      "drp": "PRD",
      "show-chart": "Mostra attività",
      "hide-chart": "Nascondi attività",
      "add-ports": "Aggiungere Porte",
      "number-of-ports": "Numero di porte da aggiungere",
      "qos-value": "Nuova QoS in Gbps",
      "company": "Azienda",
      "vswitch-search": "Cerca per nome o azienda",
      "ip-search": "Cerca per indirizzo, azienda, reverse, server, MAC",
      "new-vswitch": {
        "title": "Aggiungi un VSwitch",
        "number-of-ports": "Numero di porte desiderato",
        "qos-value": "QoS desiderata in Gbps",
      },
    },
    "company": {
      "tabs": {
        "info": "Informazione",
        "users": "Utenti",
        "payments": "Metodi di pagamento",
        "reglements": "Pagamenti",
        "historic": "Storico",
        "notifications": "Notifiche",
        "piggy": "Crediti",
        "tag": "Tags",
        "billing": "Fatturazione",
      },
      "title": "Azienda",
      "title-info": "Informazione sull'azienda",
      "title-users": "Utenti e ruoli",
      "title-user-invite": "Invita un utente",
      "title-role-create": "Crea un ruolo",
      "title-role-update": "Aggiorna un Ruolo",
      "title-authorization-update": "Aggiorna Autorizzazioni",
      "title-notifications": "Gestione delle notifiche",
      "title-piggy": "Utilizzo dei crediti",
      "title-tag": "Elenco dei Tag",
      "title-managed-service": "Server sotto Servizi Gestiti",
      "address-billing": "Indirizzo di fatturazione",
      "address-shipping": "Indirizzo di spedizione",
      "address-copy": "Utilizza questo indirizzo come indirizzo di spedizione.",
      "company": "Azienda",
      "save-success": "Informazioni salvate correttamente",
      "user-add": "Invita un utente",
      "user-add-success": "Invito inviato correttamente all'utente",
      "user-revoke-success": "Utente rimosso correttamente",
      "invitation-delete-success": "Invito revocato con successo",
      "user-empty": "Ti preghiamo d'invitare un utente.",
      "user-role-change-success": "Il ruolo dell'utente è stato modificato correttamente",
      "user-filter-place-holder": "Ricerca di un utente...",
      "complete-company-error": "Si prega di completare prima le informazioni sulla società (nome, indirizzo e partita IVA).",
      "same_address": "Gli indirizzi di consegna e di fatturazione sono gli stessi",
      "item_added": "Articolo(i) aggiunto(i) al carrello",
      "piggy": {
        "amount": "Numero di crediti rimanenti",
        "provisioning": "Provvigionamento automatico",
        "add-pack": "Aggiungi un pacchetto",
        "order": "Ordine",
        "header": [
          "Creazione",
          "Utilizzo",
          "Società",
          "Utente",
          "Numero di crediti",
          "Inviato a",
        ],
      },
      "tag": {
        "filter-place-holder": "Cerca un tag",
        "add-tag": "Aggiungi un tag",
        "list-empty": "Per favore, inserisci un tag",
        "create": "Creazione di un tag",
        "update": "Aggiornamento del tag",
        "create-success": "Creazione avvenuta con successo",
        "create-error": "Errore durante la creazione",
        "update-success": "Aggiornamento avvenuto con successo",
        "update-error": "Errore durante l'aggiornamento",
        "delete-success": "Cancellazione avvenuta con successo",
        "delete-error": "Errore durante la cancellazione",
        "select-all": "Tutti",
        "server-list": "Elenco dei server",
        "help": {
          "del-tag": "Elimina il tag",
          "edit-tag": "Modifica il tag",
          "edit-color-off": "Clicca per cambiare il colore",
          "edit-color-on": "Clicca per mantenere questo colore",
          "edit-servers": "Clicca per modificare la lista dei server",
        },
        "header": [
          "Nome",
          "Colore",
          "Server",
        ],
        "dialog-delete-tag": {
          "title": "Elimina questo tag",
          "warning": "Questa azione è irreversibile",
          "message": "Confermando questa azione, questo tag sarà eliminato.",
          "extra": "Sei sicuro di voler eliminare questo tag?",
        },
      },
    },
    "tickets": {
      "title": "Ticket",
      "title-new": "Apri un nuovo ticket",
      "service": "Servizio interessato",
      "server": "Server interessato",
      "object": "Oggetto del ticket",
      "object-placeholder": "Inserisci qui l'oggetto.",
      "message": "Il tuo messaggio",
      "message-placeholder": "Inserisci qui il tuo messaggio.",
      "add-ticket": "Nuovo ticket",
      "send-ticket": "Invia",
      "sending-ticket": "Invio",
      "close-ticket": "Chiudi il ticket",
      "close-ticket-force": "Chiudi senza risposta",
      "cancel": "Torna all'elenco",
      "close-text": "Abbiamo risposto a tutte le tue domande?",
      "ticket-added": "Ticket aggiunto…",
      "ticket-added-text": "Il nostro personale ha ricevuto correttamente la richiesta.",
      "chat": "Dialogo",
      "level": "Priorità",
      "levels": {
        "low": "Bassa",
        "medium": "Normale",
        "high": "Urgente"
      },
      "categories": {
        "billing": "Compatibilità",
        "technical": "Tecnico",
        "sales": "Commerciale",
        "other": "Altro"
      },
      "total": "Totale",
      "company": "Cliente interessato",
      "pending_client": "In attesa di risposta del cliente",
      "pending_admin": "In attesa di risposta del tecnico",
      "pending_you": "In attesa della tua risposta",
    },
    "invoices": {
      "title": {
        "invoice": "Fatture",
        "documents": "Documenti",
        "recurring": "Abbonamenti",
        "recurrings_invoices": "Fatture dell'abbonamento:",
        "admin_title": "Fatture, note di credito e ordini di acquisto",
      },
      "differed": "Questa fattura verrà automaticamente pagata insieme al rinnovo dell'abbonamento.",
      "pay": "Paga la fattura",
      "auto_debit_part_title": "Le sue prestazioni sono attualmente pagate tramite addebito diretto.",
      "auto_debit_part_one": "Il pagamento del vostro ordine sarà effettuato alla fine del mese.",
      "auto_debit_part_two": "Se avete domande, contattate il nostro supporto:",
      "auto_debit_btn": "Creare un ticket",
      "last-invoice": "Fattura recente",
      "details": "N. della fattura ",
      "order_details": "N. dell'ordine ",
      "pending": "in attesa",
      "multi-pay": "Pagamento di più fatture",
      "order_cancel": {
        "title": "Annulla l'ordine",
        "msg": "Vuoi davvero annullare l'ordine?",
        "btn": "Annulla l'ordine"
      },
      "tabs": {
        "invoice_tab": "Fattura",
        "order_tab": "Ordine",
        "credit_tab": "Credito",
        "payment_tab": "Pagamento",
        "payment_list_tab": "Elenco dei pagamenti",
        "expenses_list_tab": "Elenco delle spese",
        "payment_tab_header": "Dettagli",
        "dispute_list_tab": "Elenco delle Controversie",
        "payment_detail_header": [
          "Numero Fattura",
          "Importo",
          "Sconto",
          "Importo Netto",
          "IVA",
          "Importo Totale",
          "Pagato",
          "Saldo",
        ],
      },
      "no-payments": "Nessun pagamento.",
    },
    "cgv": {
      "title": "Condizioni Generali",
      "action": "Accetta le CGV",
      "next": "Avanti",
      "previous": "Indietro",
      "download": "Scarica",
      "accept": "Accetto le Condizioni Generali di Vendita.",
      "signed-at": "Hai firmato le nostre CGV il {date} dall'indirizzo IP {ip}.",
      "up-to-date": "Da quando hai firmato le CGV esse non sono state modificate.",
      "not-up-to-date": "Da quando hai firmato le CGV esse sono state modificate.",
      "current-version": "La versione attuale è la V{version}.",
      "read": "Consulta le Condizioni Generali",
    },
    "email-validation": {
      "title": "Convalida l'indirizzo E-mail",
      "send-action": "Rimandami il link di conferma"
    },
    "forget-password": {
      "title": "Hai dimenticato la tua password?",
      "send-action": "Inviami le istruzioni per ripristinarla",
      "confirm-success": "Ti abbiamo inviato un'e-mail con le istruzioni per ripristinarla (per favore controlla anche i tuoi spam)."
    },
    "forget-password-change": {
      "title": "Nuova password",
      "action": "Conferma"
    },
    "commercial": {
      "title": "Commerciale",
      "last_name": "Cognome",
      "first_name": "Nome",
      "email": "Email",
      "phone": "Telefono",
      "status": "Estado",
      "disabled": "Disabilitato",
      "create_commercial": "Crea un venditore",
    },
    "cron": {
      "add-cron": "Aggiungi un compito",
      "header": [
        "Attività",
        "Nome",
        "Azienda",
        "Utente",
        "Server",
        "Programmazione",
        "Ricorrente",
        "Stato",
        "Attivata",
      ],
      "job": {
        "type": {
          "schedule-reboot": "Riavvio",
          "snapshot": "Snapshot",
          "undefined": "",
        },
        "message": {
          "create-success": "Lavoro creato",
          "delete-success": "Lavoro eliminato",
          "update-success": "Lavoro aggiornato",
          "no-job": "Nessuna attività programmata",
        },
        "creation": {
          "title": "Creazione di un compito",
          "label": [
            "Azienda",
            "Server",
            "Tipo",
            "Compito ricorrente",
            "Programmazione",
          ],
          "no-credit": [
            "Nessun credito disponibile.",
            "Senza crediti, le attività ricorrenti programmate su un server non possono essere eseguite.",
            "Vi invitiamo ad acquistare un pacchetto di crediti per consentire l'esecuzione di molteplici attività per server.",
            "Si prega di notare che questi crediti consentono anche la ricezione di allarmi via SMS.",
            "Vi ringraziamo per la vostra comprensione."
          ],
        },
        "toggle": {
          "button-tip-true": "Clicca per attivare il compito",
          "button-tip-false": "Clicca per disattivare il compito",
          "true": "Compito disattivato",
          "false": "Compito attivato",
        },
      },
      "modal": {
        "delete-job-title": "Elimina compito {job}",
        "delete-job-description": "Confermando questa azione, questo compito sarà eliminato.",
        "delete-job-confirm": "Sei sicuro di voler eliminare questo compito?",
        "delete-job-warning": "Questa azione è reversibile.",
      },
    },
    "managed-service": {
      "title": "Servizi Gestiti",
      "terminate-canceled": "Annullamento della terminazione dei servizi gestiti",
      "help": {
        "terminate": "Termina servizi gestiti",
        "cancel-terminate": "Annulla terminazione",
        "download": "Scarica il contratto",
      },
      "dialog-terminate": {
        "title": "Termina i servizi gestiti per il server {server}",
        "warning": "Questa azione è reversibile",
        "message": "Confermando questa azione, i servizi gestiti saranno terminati.",
        "extra": "Sei sicuro di voler terminare questi servizi gestiti?",
        "button": "Termina",
      },
      "dialog-cancel-terminate": {
        "title": "Annullamento della terminazione dei servizi gestiti",
        "warning": "Questa azione è delicata",
        "message": "Confermando questa azione, la terminazione dei tuoi servizi gestiti sarà annullata.",
        "extra": "Sei sicuro di voler annullare la terminazione di questi servizi gestiti?",
        "button": "Annulla terminazione",
      },
    },
    "scheduled-action": {
      "add-action": "Crea un'azione",
      "goto-server": "Vai al server",
      "header": [
        "Azione",
        "Server",
        "IP",
        "Azienda",
        "Creazione",
        "Pianificazione",
        "In attesa",
        "Completata",
      ],
      "type": {
        "delete_server": "Terminazione del server",
        "delete_ip": "Terminazione IP",
        "delete_outsourcing": "Terminazione dell'outsourcing",
        "deactivate_server": "Disattivazione del server",
      },
      "states": {
        "all": "Tutte",
        "pending": "In attesa",
        "terminated": "Completata",
      },
      "dialog-action-delete": {
        "title": "Elimina un'azione programmata",
        "danger": "Questa eliminazione è irreversibile",
        "message": "Confermando questa richiesta, l'azione programmata sarà eliminata",
        "extra": "Sei sicuro di voler eliminare questa azione programmata?",
      },
      "operation": [
        "Creazione di un'azione programmata",
        "Modifica di un'azione programmata",
      ],
    },
    "dispute-list": {
      "title": "Controversie",
      "create-dispute": "Crea una Contestazione",
      "date": "Data",
      "number": "N° del Contenzioso",
      "ref": "Riferimento",
      "facture": "Fatture Allegate",
      "total-amount": "Importo",
      "fees": "Spese",
      "client_code": "Codice Cliente",
      "client": "Cliente",
      "no_dispute": "Nessuna Controversia Trovata",
      "paid": "Saldato",
      "status": "Stato",
      "states": {
        "title": "Stato",
        "lost": "Perso",
        "needs_response": "In Attesa",
        "under_review": "In Corso",
        "warning_closed": "Avviso Chiuso",
        "warning_needs_response": "Avviso in Attesa",
        "warning_under_review": "Avviso in Corso",
        "won": "Vinto",
      },
      "reason": {
        "title": "Motivo",
        "bank_cannot_process": "Elaborazione bancaria impossibile",
        "credit_not_processed": "Non elaborato dalla banca",
        "customer_initiated": "Richiesta del cliente",
        "debit_not_authorized": "Addebito non autorizzato",
        "duplicate": "Duplicato di fatturazione",
        "fraudulent": "Fraudolento",
        "general": "Motivi vari",
        "incorrect_account_details": "Dettagli del conto errati",
        "insufficient_funds": "Fondi insufficienti",
        "product_not_received": "Prodotto non ricevuto",
        "product_unacceptable": "Prodotto inaccettabile",
        "subscription_canceled": "Abbonamento annullato",
        "unrecognized": "Pagamento non riconosciuto",
      },
      "date-range": {
        "month": "Mese",
        "all": "Tutti",
        "last-month": "Ultimo Mese",
        "last-quarter": "Ultimo Trimestre",
        "format": "dd/MM/yyyy",
        "apply": "Applica",
        "cancel": "Annulla",
        "weekLabel": "S",
        "customRange": "Personalizzato",
        "daysOfWeek": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
        "monthNames": ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        "monthNamesFull": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      },
      "help": {
        "pay": "Pagare le fatture",
      },
    },
    "drp-location": {
      "empty": "Elenco vuoto",
      "name": "Nome",
      "url": "URL",
      "dialog-action-delete": {
        "title": "Eliminare la Posizione",
        "danger": "Questa eliminazione è irreversibile",
        "message": "Confermare questa richiesta eliminerà la posizione",
        "extra": "Sei sicuro di voler eliminare questa posizione?",
      },
      "operation": [
        "Creazione di una posizione",
        "Modifica di una posizione",
      ],
    },
    "statistics": {
      "title": "Statistiche",
      "tab": {
        "title": "Fatture",
      },
      "header": {
        "year": "Anno",
        "month": "Mese",
        "paid": "Saldato",
        "pending": "In attesa",
        "unpaid": "Non pagato",
      },
    },
  },
  "unit": {
    "GB": "GB",
    "vcpu": "vCores",
    "mbps": "mbps",
    "Cores": "vCores",
  },
  "form": {
    "required-field": "Campi obbligatori",
    "missing-fields": "Ti preghiamo di compilare i campi mancanti",
    "password-constraints": "Password di minimo 6 caratteri",
    "email-invalid": "Indirizzo e-mail non valido",
    "affiliate-code-invalid": "Codice affiliazione non valido",
    "country-invalid": "Paese non valido",
    "password-condition": {
      "characters": "caratteri min.",
      "contains-number": "1 numero",
      "contains-uppercase": "1 maiuscola",
      "contains-special": "1 carattere speciale"
    },
  },
  "beta-functionnality-modal": {
    "title": "Funzionalità di accesso limitata.",
    "content": "Per attivare questa funzione, è necessario contattare il nostro ufficio vendite per telefono o per telefono."
  },
  "tour": {
    "next": "Prossimo",
    "previous": "Precedente",
    "end": "Fine",
    "skip": "Salta il tour",
    "global": {
      "play": "Riproduci il tour",
      "dashboard": {
        "header": {
          "title": "Dashboard"
        },
        "content": "Qui troverà un riassunto delle informazioni sui suoi biglietti, delle azioni e delle statistiche di consumo.",
      },
      "servers": {
        "header": {
          "title": "Qui potete controllare i vostri server con molte caratteristiche:"
        },
        "content": "- Accesso remoto (risoluzione dei problemi tramite kvm)<br />" +
          "- Upgrade / Downgrade delle risorse cpu/ram/disk<br />" +
          "- Impostazione ISO e recupero ISO<br />" +
          "- Stop / Avvio di server<br />" +
          "- Attivazione ipv6 e creazione di inversioni<br />" +
          "- Reset, snapshot e creazione di template<br />" +
          "- Statistiche risorse cpu/ram/disk/network",
      },
      "networks": {
        "header": {
          "title": "Network"
        },
        "content": "Create e gestite le vostre reti private per proteggere e impostare la vostra piattaforma server."
      },
      "company": {
        "header": {
          "title": "Company"
        },
        "content": "Qui puoi gestire le informazioni della tua azienda così come i tuoi metodi di pagamento e la gestione dei ruoli.",
      },
      "tickets": {
        "header": {
          "title": "Ticket"
        },
        "content": "Qui puoi comunicare con i nostri diversi dipartimenti: tecnico, commerciale e contabile.",
      },
      "affiliations": {
        "header": {
          "title": "Affiliazioni"
        },
        "content": "Qui puoi gestire il tuo conto di membro e gli importi accreditati su di esso.<br />" +
          "« Contattate il nostro ufficio vendite per verificare la vostra idoneità »",
      },
      "recurring": {
        "header": {
          "title": "Subscription"
        },
        "content": "Gestisci la lista dei tuoi abbonamenti e i nomi dei tuoi progetti che appaiono sulle tue fatture.",
      },
      "invoice": {
        "header": {
          "title": "Document"
        },
        "content": "Qui potete trovare tutti i vostri documenti contabili (ordini, fatture e note di credito)",
      },
      "vms": {
        "header": {
          "title": "Caddie"
        },
        "content": "Qui potete ordinare i vostri server e farveli consegnare in meno di un minuto.",
      },
      "header-user": {
        "header": {
          "title": "Impostazioni dell'account"
        },
        "content": "- Qui puoi gestire le impostazioni dei tuoi diversi account<br />" +
          "- Cambiare interfaccia tra i tuoi diversi account<br />" +
          "- Gestisci il tuo negozio di chiavi <strong>SSH</strong> e <strong>API</strong><br />" +
          "- Attiva la tua autenticazione 2FA<br />" +
          "- Acquista i tuoi servizi (intervento una tantum e licenza Microsoft)<br />" +
          "- Accedi alla documentazione API"
      }
    }
  },
  "object_search": {
    "empty": "Inserire almeno {length} caratteri",
    "selected": "Il valore selezionato è {value}",
    "match": "Il nome deve esistere nell'elenco"
  },
  "company_search": {
    "placeholder": "Cerca un cliente",
    "empty": "Cerca e poi seleziona un'azienda",
    "selected": "L'azienda selezionata è {value}",
  },
  "cron-date": {
    "minute": "Minuto",
    "hour": "Ora",
    "month": "Mese",
    "day-of-month": "Giorno del mese",
    "day-of-week": "Giorno della settimana",
    "help": "L'asterisco (*) rappresenta qualsiasi valore possibile per il campo in questione.",
    "hours": "Ore",
    "at": "A",
    "all": "Tutte",
  },
  "days-of-week": ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  "months-of-year": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
  "Forbidden": "Accesso negato",
  "errors": {
    "CREATE_SUCCESSFUL": "Creazione riuscita",
    "UPDATE_SUCCESSFUL": "Aggiornamento riuscito",
    "DELETE_SUCCESSFUL": "Cancellazione riuscita",
    "NO_DOCUMENT_FOUND": "Non esiste!",
    "PAGE_NOT_FOUND": "Pagina non trovata.",
    "ALREADY_EXISTS": "Esiste già",
    "TASK_ALREADY_RUNNING": "Attività già in esecuzione",
    "INVALID_RECAPTCHA": "Recaptcha non valido.",
    "UPDATE_UNSUCCESSFUL": "Aggiornamento non riuscito",
    "ERR_ORDER_DELETION": "Errore durante la cancellazione dell'ordine",
    "DOWNLOAD_ERROR": "Errore durante il download",
    "DISABLE_SUCCESS": "Disattivazione riuscita",
    "DISABLE_ERROR": "Errore durante la disattivazione",

    "TOO_MANY_KVM": "Hai troppe KVM aperte",

    "PERMISSION_DENIED": "Non hai i permessi per questa azione",
    "2FA_INVALID_SECRET": "Segreto non valido",
    "USER_UPDATE_SSH_KEY_SUCCESS": "Aggiornato con successo",
    "USER_UPDATED_SUCCESSFULLY": "Aggiornato con successo",
    "TOKEN_UPDATE_SUCCESS": "Aggiornato con successo",

    "SERVER_RESET_FAIL_NOT_LICENSED_OS_TO_LICENSED_OS": "Questa funzione non è ancora disponibile per il tuo utente. Si prega di contattare il supporto.",

    "IMAGES_EMPTY": "Non avete un'immagine, potete crearne una dallo snapshot di una macchina.",

    "APP_BETA_FUNCTIONNALITY": "Presto, un'applicazione mobile Titan vi permetterà di controllare tutte le funzioni.",

    "BETA_FUNCTIONNALITY": "Per attivare questa funzione, è necessario contattare il nostro ufficio vendite per telefono o per telefono.",

    "INVALID_EMAIL_OR_PASSWORD": "Indirizzo email o password non validi.",
    "MISSING_EMAIL_OR_PASSWORD": "Email o password mancanti.",
    "ACCOUNT_NOT_VALIDATED": "Il tuo account non è convalidato, controlla le tue email email.",
    "USER_OR_COMPANY_MISSING_NAME_OR_ADDRESS_INFORMATION": "Devi prima completare le tue informazioni personali e professionali.",

    "INTERNAL_ERROR": "Errore interno.",
    "BAD_PERMISSION": "Permesso negato.",
    "BAD_REQUEST": "Richiesta errata.",
    "SERVER_NOT_FOUND": "Server non trovato.",
    "COMPANY_NOT_FOUND": "Compagnia non trovata.",
    "INVOICE_NOT_FOUND": "Fattura non trovata.",
    "INVOICE_ALREADY_PAID": "Fattura già pagata.",
    "PAYMENT_METHOD_NOT_FOUND": "Metodo di pagamento non trovato.",
    "PLAN_NOT_FOUND": "Modello non trovato",
    "CREDIT_NOT_FOUND": "Credito non trovato.",
    "CREDIT_EXPIRED": "Il credito è scaduto.",
    "CREDIT_SPENT": "Credito non valido, ti preghiamo di contattare l'assistenza.",
    "CREDIT_INVALID": "Credito non valido, ti preghiamo di contattare l'assistenza.",
    "PAYMENT_FAIL": "Il pagamento non è avvenuto correttamente.",
    "PROJECT_NAME_INVALID": "Il nome del progetto non è valido, deve avere una lunghezza compresa tra 4 e 20 caratteri.",
    "PROJECT_NAME_CONFLICT": "Il nome del progetto è già in uso, ti preghiamo di sceglierne un altro.",
    "ROLE_NAME_INVALID": "Nome ruolo non valido, la lunghezza deve essere compresa tra 2 e 50 caratteri.",
    "REVERSE_INVALID": "Inverso non valido: assicurati di impostare l'indirizzo IP appropriato sulla zona DNS.",

    "AVATAR_INVALID_TYPE": "L'avatar scelto non è nel formato corretto (jpg o png).",
    "AVATAR_INVALID_SIZE": "L'avatar scelto non rispetta le condizioni.",
    "AVATAR_UPDATED_SUCCESSFULLY": "Avatar aggiornato con successo",
    "PHONE_NUMBER_INVALID": "Il numero di telefono non è valido.",

    // License
    "LICENSE_NOT_FOUND": "Licenza non trovata",

    // Snapshot
    "SNAPSHOT_NOT_FOUND": "Snapshot non trovato.",
    "SNAPSHOT_RESTORE_STARTED": "Ripristino dello snapshot in corso.",
    "SNAPSHOT_RENAME_SUCCESS": "Snapshot rinominato correttamente.",
    "SNAPSHOT_DELETE_SUCCESS": "Snapshot cancellato correttamente.",
    "SNAPSHOT_CREATE_FAIL_LIMIT_EXCEEDED": "È stato raggiunto il limite di snapshot.",
    "SNAPSHOT_DELETE_INVALID_STATE": "Impossibile cancellare lo snapshot: stato non valido.",
    "SNAPSHOT_RESTORE_INVALID_SNAPSHOT_STATE": "Impossibile ripristinare lo snapshot: stato dello snapshot non valido.",
    "SNAPSHOT_CREATE_FAIL_TOO_FAST": "Impossibile creare un nuovo snapshot: devono intercorrere almeno 30 minuti tra uno snapshot creato e l'altro.",

    // Image
    "IMAGE_CREATE_FAIL_INSUFFICIENT_SPACE": "Il tuo spazio di archiviazione è insufficiente.",
    "IMAGE_CREATE_FAIL_QUOTA_EXCEEDED": "La quota di creazione delle immagini è stata superata.",
    "SERVER_CREATE_FAIL_SERVER_DISK_IMAGE_AND_TEMPLATE_CONFLICT": "Conflitto tra l'immagine del disco e il modello.",
    "SERVER_CREATE_FAIL_INVALID_DISK_SIZE": "Dimensione del disco non valida.",
    "SERVER_CREATE_FAIL_INVALID_IMAGE_STATE": "Stato dell'immagine non valido.",
    "SERVER_CREATE_FAIL_DISK_SOURCE_INVALID": "Sorgente disco non valida.",
    "SERVER_CREATE_FAIL_IMAGE_UUID_MISSING": "Identificatore unico mancante.",
    "SERVER_CREATE_FAIL_TEMPLATE_UUID_MISSING": "Identificatore unico mancante.",
    "IMAGE_DELETE_SUCCESS": "Immagine cancellata con successo.",
    "IMAGE_UPDATE_SUCCESS": "Immagine aggiornata con successo.",
    "IMAGE_CREATE_SUCCESS": "Immagine creata con successo.",
    "NO_IMAGE": "Non hai un'immagine, puoi crearne una da uno snapshot",
    "IMAGE_NOT_FOUND": "Immagine non trovata.",
    "IMAGE_DELETE_FAIL_IS_ACTUALLY_USED": "Questa immagine è usata, non puoi cancellarla.",
    "IMAGE_CREATE_FAIL_PLESK_DISABLED": "È impossibile creare modelli PLESK.",
    "IMAGE_CREATE_FAIL_NAME_ALREADY_USED": "Questo nome è già in uso.",
    "IMAGE_INVALID_NAME_SIZE": "La dimensione del nome non è corretta.",
    "IMAGE_INVALID_OS_NAME_SIZE": "La dimensione del nome del sistema operativo non è corretta.",
    "IMAGE_INVALID_OS_VERSION_SIZE": "La dimensione della versione del sistema operativo non è corretta.",
    "IMAGE_CREATE_FAIL_SNAPSHOT_INVALID_STATE": "L'istantanea è in uno stato non valido.",
    "IMAGE_FAIL_TO_RETRIEVE_ORIGINAL_TEMPLATE": "Impossibile trovare il modello originale.",
    "INVALID_TARGET_STATE": "Destinazione inesistente o in uno stato non conforme alla richiesta.",

    // Addon
    "ADDON_INSTALL_FAIL_INVALID_PLAN": "Piano non valido.",
    "ADDON_INSTALL_FAIL_INVALID_QUANTITY": "Quantità di add-on non valida.",
    "ADDON_INSTALL_FAIL_INVALID_ITEM": "Add-on non valido.",
    "ADDON_INSTALL_FAIL_CPU_LIMIT_EXCEEDED": "Impossibile installare l'add-on/gli add-on: la quantità di CPU è stata superata, ti preghiamo di contattare il supporto.",
    "ADDON_INSTALL_FAIL_RAM_LIMIT_EXCEEDED": "Impossibile installare l'add-on/gli add-on: la quantità di RAM è stata superata, ti preghiamo di contattare il supporto.",
    "ADDON_INSTALL_FAIL_DISK_LIMIT_EXCEEDED": "Impossibile installare l'add-on/gli add-on: la quantità di disco è stata superata, ti preghiamo di contattare il supporto.",
    "ADDON_INSTALL_FAIL_SRV_ON_TERMINATION": "La cancellazione del server è stata pianificata.",
    "ADDON_UNINSTALL_FAIL_SERVER_BAD_STATE": "Impossibile disinstallare i componenti aggiuntivi: il server deve essere arrestato.",
    "ADDON_UNINSTALL_FAIL_NOT_FOUND": "Impossibile disinstallare i componenti aggiuntivi: componenti aggiuntivi non trovati.",
    "ADDON_UNINSTALL_FAIL_SERVER_DO_NOT_BELONG_TO_SERVER": "Impossibile disinstallare i componenti aggiuntivi: i componenti aggiuntivi non sono installati.",
    "ADDON_UNINSTALL_FAIL_ITEM_TYPE_DISK_INVALID": "Impossibile disinstallare i componenti aggiuntivi: i componenti aggiuntivi del disco non possono essere disinstallati.",
    "ADDON_UNINSTALL_FAIL_INVALID_QUANTITY": "Impossibile disinstallare i componenti aggiuntivi: quantità non valida.",
    "ADDON_UNINSTALL_FAIL_SRV_ON_TERMINATION": "Impossibile disinstallare i componenti aggiuntivi: è prevista l'eliminazione del server.",
    "ADDON_UNINSTALL_SUCCESS": "I componenti aggiuntivi sono stati disinstallati correttamente.",
    "ADDON_LIST_FAIL": "Impossibile elencare gli add-on.",
    "ADDON_ERR_INVOICE_NOT_PAID": "Impossibile aggiungere addon. Fattura(e) non pagata(e).",

    // Invoice
    "INVOICE_PAYMENT_NOT_FOUND": "Impossibile trovare il pagamento della fattura.",
    "INVOICE_CONTACT_NOT_RECOGNIZED": "Il contatto per la fattura non è stato trovato.",
    "INVOICE_CREATE_FAIL": "Impossibile creare una nuova fattura.",
    "INVOICE_CREATE_SUCCESS": "Nuova fattura creata.",
    "INVOICE_CREATE_NOT_REQUIRED": "Non è ancora stato richiesto di creare una nuova fattura.",
    "INVOICE_ITEM_BUILD_FAIL": "Impossibile creare le voci della fattura.",
    "UNPAID_INVOICES_EXIST": "Ci dispiace, il tuo account presenta fatture non pagate.",

    // Order
    "ORDER_CANCEL_SUCCESS": "Ordine annullato correttamente.",
    "ORDER_ALREADY_INVOICE": "L'ordine è già stato inserito in fattura.",
    "ORDER_ALREADY_CANCELED": "L'ordine di acquisto è già stato annullato.",
    "ORDER_ALREADY_PAID": "L'ordine di acquisto è già stato pagato.",
    "ORDER_HAS_SUCCESS_PAYMENT": "Il pagamento di quest'ordine risulta effettuato correttamente. Ti preghiamo di contattare l'assistenza.",
    "ORDER_HAS_WAITING_PAYMENT": "Il pagamento di quest'ordine risulta in sospeso. Ti preghiamo di attendere.",
    "ORDER_HAS_DEPLOYED_ITEM": "L'ordine comporta già un elemento implementato. Ti preghiamo di contattare il supporto.",
    "ORDER_NOT_CANCELABLE": "L'ordine non può essere annullato. Ti preghiamo di contattare il supporto.",

    // Recurring invoices
    "RECURRING_INVOICE_NOT_FOUND": "Abbonamento non trovato.",
    "RECURRING_INVOICE_UPDATE_FAIL": "Impossibile aggiornare l'abbonamento.",
    "RECURRING_INVOICE_CANCEL_SUCCESS": "Abbonamento terminato con successo.",
    "RECURRING_INVOICE_UPDATE_SUCCESS": "Abbonamento aggiornato con successo.",
    "RECURRING_INVOICE_CREATE_FAIL": "Impossibile creare un nuovo abbonamento.",
    "RECURRING_INVOICE_CREATE_SUCCESS": "Nuova sottoscrizione creata con successo.",
    "RECURRING_INVOICE_INVALID_FREQ": "La frequenza dell'abbonamento non è valida",
    "RECURRING_INVOICE_ITEM_BUILD_FAIL": "Impossibile aggiungere articoli alla tua iscrizione.",
    "RECURRING_INVOICE_NOT_ONGOING": "L'abbonamento non è in corso.",
    "RECURRING_INVOICE_START_TOO_EARLY": "La data d'inizio dell'abbonamento è troppo vicina, cancellarla per avviare dinamicamente il abbonamento dopo un pagamento effettuato correttamente.",
    "RECURRING_INVOICE_CANCELED": "L'abbonamento viene annullato.",
    "RECURRING_INVOICE_PENDING": "L'abbonamento è in sospeso.",
    "RECURRING_INVOICE_ONGOING": "Eliminazione non possibile. Una sottoscrizione attiva in questo account richiede almeno un metodo di pagamento registrato.",
    "RECURRING_ITEM_NOT_LICENSE_TYPE": "L'elemento non è di tipo licenza",
    "RECURRING_INVOICE_NAME_CANCELED": "Abbonamento annullato. Si prega di cambiare il nome del progetto.",
    "SUBSCRIPTION_ALREADY_EXISTS": "L'abbonamento esiste già",
    "OVER_FRAMEWORK_AGREEMENT_QUOTA": "Hai superato le quantità di risorse del contratto quadro",

    // Payment methods
    "PAYMENT_METHOD_ADD_FAIL_EMPTY_NAME": "È necessario specificare il nome completo che compare sulla carta.",
    "PAYMENT_METHOD_UPDATE_SUCCESS": "Il metodo di pagamento è stato aggiornato correttamente.",
    "PAYMENT_METHOD_REMOVE_SUCCESS": "Il metodo di pagamento è stato cancellato correttamente.",
    "PAYMENT_METHOD_ADD_SUCCESS": "Il metodo di pagamento è stato aggiunto correttamente.",
    "PAYMENT_METHOD_ALREADY_REGISTERED": "Il metodo di pagamento è già stato salvato.",
    "PAYMENT_METHOD_ADD_FAIL": "Impossibile aggiungere il nuovo metodo di pagamento.",
    "PAYMENT_EXCEED_AMOUNT": "L'importo del pagamento supera l'importo da pagare.",
    "PAYMENT_FAILED": "Pagamento non riuscito.",
    "PAYMENT_METHOD_IS_DEFAULT": "Eliminazione del metodo di pagamento predefinito vietata.",
    "Credit card number is not long enough": "Il numero della carta di credito non è abbastanza lungo",
    "Invalid credit card number": "Numero di carta di credito non valido",
    "Test numbers are not allowed": "I numeri di test non sono consentiti",
    "Invalid year": "Anno non valido",
    "Invalid month": "Mese non valido",
    "Credit card has expired": "Carta di credito scaduta",
    "Invalid CVV": "Numero CVV non valido",
    "Unknown credit card method": "Tipo di carta di credito sconosciuto",

    // Payment delay
    "PAYMENT_DELAY_ALREADY_EXISTS": "Questo ritardo di pagamento esiste già.",
    
    // Credit
    "CREDIT_CREATE_SUCCESS": "Il credito è stato creato correttamente.",
    "CREDIT_FUNDING_EXCEEDED": "È stato superato il finanziamento del credito per una data fattura.",
    "CREDIT_FUNDING_INVOICE_NOT_PAID": "Una data fattura di finanziamento non è stata pagata.",
    "CREDIT_FUNDING_ITEM_NOT_FOUND": "Il finanziamento di una voce della data fattura non è stato trovato.",
    "CLEAR_AMOUNT_EXCEED": "L'importo supera il saldo del documento.",

    // Migration
    "MIGRATE_FAIL_ITEM_NOT_DEPLOYED": "Migrazione impossibile, uno o più elementi non sono ancora stati implementati.",

    // Server
    "BAD_OID": "OID non valido",

    // Delete
    "SERVER_DELETE_FAIL_ALREADY_ON_TERMINATION": "La cancellazione del server è già stata pianificata.",
    "SERVER_DELETE_FAIL_PLAN_NOT_FOUND": "Impossibile cancellare il server: piano non trovato.",
    "SERVER_DELETION_PENDING_SUCCESS": "Cancellazione del/dei server in corso.",
    "SERVER_DELETION_PENDING": "Azione non autorizzata. È stata richiesta la chiusura del server.",
    "SERVER_DELETE_SUCCESS": "Server cancellato correttamente.",
    "SERVER_DELETE_FAIL_RECURRING_NOT_FOUND": "Impossibile cancellare il server: fattura ricorrente non trovata.",
    "SERVER_DELETE_FAIL_WAN_CONFIG_ERROR": "Impossibile cancellare il server: errore di configurazione WAN.",
    "SERVER_DELETE_FAIL_UPDATE_RECURRING_ERROR": "Impossibile cancellare il server: errore durante l'aggiornamento della fattura ricorrente.",
    "SERVER_CANCEL_DELETE_FAIL_ALREADY_DELETED": "Impossibile annullare l'eliminazione del server: il server è già stato eliminato.",
    "SERVER_DELETE_FAIL_ACTION_NOT_SCHEDULED": "L'eliminazione di questo server non è pianificata.",
    // Create
    "SERVER_CREATE_FAIL": "Impossibile trovare la relativa fattura.",
    "SERVER_CREATE_FAIL_FREE_WAN_NOT_FOUND": "Impossibile creare il/i server: non ci sono più indirizzi IP disponibili, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_SERVER_LIMIT_EXCEEDED": "Impossibile creare il/i server: la quantità di server è stata superata, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_CPUS_LIMIT_EXCEEDED": "Impossibile creare il/i server: la quantità di CPU è stata superata, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_RAM_LIMIT_EXCEEDED": "Impossibile creare il/i server: la quantità di RAM è stata superata, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_DISK_LIMIT_EXCEEDED": "Impossibile creare il/i server: la quantità di disco è stata superata, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_IDENT_MISSING": "Impossibile creare il/i server: password e/o chiave SSH assente.",
    "SERVER_CREATE_FAIL_ADDON_QUANTITY_EXCEEDED": "Impossibile creare il/i server: il limite di add-on è stato superato, ti preghiamo di contattare il supporto.",
    "SERVER_CREATE_FAIL_INVALID_ADDON": "Impossibile creare il/i server: add-on non trovato o non valido.",
    "SERVER_CREATE_FAIL_INVALID_QUANTITY": "Impossibile creare il/i server: quantità errata.",
    "SERVER_CREATE_FAIL_INVALID_PLAN": "Impossibile creare il/i server: piano non trovato o non valido.",
    "SERVER_CREATE_FAIL_INVALID_CPU_QUANTITY": "Impossibile creare il/i server: quantità di CPU non valida.",
    "SERVER_CREATE_FAIL_INVALID_TEMPLATE": "Impossibile creare il/i server: template non trovato o non valido.",
    "SERVER_CREATE_FAIL_INVALID_PASSWORD": "Impossibile creare il/i server: password non valida.",
    "SERVER_CREATE_FAIL_FREE_MAC_NOT_FOUND": "Impossibile creare il/i server: non ci sono indirizzi MAC disponibili",
    "SERVER_CREATE_FAIL_FREE_IP_NOT_FOUND": "Impossibile creare il/i server: non ci sono più indirizzi IP disponibili",
    "SERVER_CREATE_FAIL_MISSING_LICENSE": "Impossibile creare il server: licenza mancante",
    "SERVER_CREATE_FAIL_PROJECT_NAME_INVALID": "Impossibile creare il server: nome del progetto non valido.",
    "SERVER_CREATE_FAIL_PROJECT_NAME_DUPLICATE": "Impossibile creare il server: il nome del progetto esiste già.",
    // Reset
    "SERVER_RESET_SUCCESS": "Reinizializzazione server in corso.",
    "SERVER_RESET_FAIL_MISSING_PASSWORD_OR_KEY": "Impossibile reinizializzare il server: password e/o chiave SSH assente.",
    "SERVER_RESET_FAIL_INVALID_PLAN": "Impossibile reinizializzare il server: piano non trovato o non valido.",
    "SERVER_RESET_FAIL_INVALID_TEMPLATE": "Impossibile reinizializzare il server: template non trovato o non valido.",
    "SERVER_RESET_FAIL_WAN_NOT_FOUND": "Impossibile reinizializzare il server: ingresso WAN non trovato.",
    "SERVER_RESET_FAIL_NO_PRIMARY_IP": "Impossibile ripristinare il server: IP primario non trovato.",
    // Update
    "SERVER_UPDATE_SUCCESS": "Server aggiornato correttamente.",
    "SERVER_UPDATE_FAIL_INVALID_NAME": "Impossibile aggiornare il server: nuovo nome non valido.",
    "SERVER_UPDATE_FAIL_MISSING_PARAMETER": "Impossibile aggiornare il server: parametro assente.",
    "SERVER_UPDATE_FAIL_UPDATE_REVERSE": "Impossibile aggiornare il reverse del server, ti preghiamo di contattare il supporto.",
    "SERVER_UPDATE_FAIL_INVALID_REVERSE": "Impossibile aggiornare il server: reverse non valido.",
    "SERVER_UPDATE_FAIL_WAN_NOT_FOUND": "Impossibile aggiornare il server: ingresso WAN non trovato.",
    "SERVER_UPDATE_FAIL_NOTES_TOO_LONG": "Impossibile aggiornare il server: il campo appunti è troppo lungo.",
    "SERVER_UPDATE_FAIL_NO_PRIMARY_IP": "Impossibile aggiornare il server: nessun IP principale trovato.",
    // Actions
    "SERVER_ACTION_FAIL_UNKNOWN_ACTION": "Operazione sconosciuta.",
    "SERVER_ACTION_FAIL_CONFLICT": "L'azione non è compatibile con lo stato corrente del server.",
    "SERVER_ACTION_SUCCESS": "Operazione sul server in fase di elaborazione.",
    // ISO
    "SERVER_LOAD_ISO_BAD_PATH": "Impossibile caricare l'ISO: percorso non valido.",
    "SERVER_LOAD_ISO_BAD_PROTOCOL": "Impossibile caricare l'ISO: il protocollo non è valido",
    "SERVER_LOAD_ISO_SUCCESS": "ISO caricata correttamente.",
    "SERVER_UNLOAD_ISO_SUCCESS": "ISO smontata correttamente.",
    // OUTSOURCING - MANAGED SERVICES
    "SERVER_OUTSOURCING_SET": "Livello di servizio attivo sul server. Si prega di contattare il reparto contabilità.",
    "SERVER_OUTSOURCING_ALREADY_SET": "Livello di servizio già attivo per il/i server",
    "SERVER_OUTSOURCING_TERMINATION_EXISTS": "Una richiesta di terminazione esiste già.",
    "SERVER_OUTSOURCING_TERMINATION_TOO_LATE": "La terminazione deve essere richiesta almeno due mesi prima della fine del contratto.",
    "SERVER_OUTSOURCING_TERMINATION_NOT_EXISTS": "Non esiste alcuna richiesta di terminazione.",
    // DRP
    "SERVER_DRP_ALREADY_SET": "DRP già attivato per il/i server",
    // DEACTIVATION
    "SERVER_DEACTIVATION_ALREADY_SCHEDULED": "Disattivazione del server già pianificata.",
    "SERVER_DEACTIVATION_DATE_INVALID": "La data di disattivazione non è valida.",

    // Template
    "TEMPLATE_DELETE_SUCCESS": "Template cancellato correttamente.",
    "TEMPLATE_GET_FAIL_INVALID_OS": "Impossibile recuperare i template.",
    "TEMPLATE_DELETE_NOT_FOUND": "Impossibile cancellare il template: template non trovato.",

    // IP KVM
    "IPKVM_FAIL_ACTION_UNKNOWN": "Operazione sul KVM IP sconosciuta.",
    "IPKVM_SEND_ACTION_SUCCESS": "Operazione sul KVM IP in fase di elaborazione.",
    "IPKVM_GET_URI_FAIL_KVM_NOT_STARTED": "Il KVM IP non è stato avviato sul server.",
    "IPKVM_FAIL_INVALID_DEADLINE": "Impossibile eseguire l'operazione sul KVM IP: il timeout non è valido.",
    "IPKVM_FAIL_TOO_LONG_DEADLINE": "Impossibile eseguire l'operazione sul KVM IP: il timeout non deve superare le 5 ore.",
    "IPKVM_FAIL_NOT_STOPPED": "Azione impossibile: il KVM non viene arrestato",

    // Migrate
    "MIGRATE_FAIL_MISSING_SRC": "Migrazione impossibile: sorgente assente (hypervisor o server).",
    "MIGRATE_FAIL_CONFLICT_SRC": "Migrazione impossibile: conflitto sorgente (hypervisor o server).",
    "MIGRATE_FAIL_HYPERV_SRC_EQUAL_DST": "Migrazione impossibile: gli hypervisor (sorgente e destinazione) sono gli stessi.",
    "MIGRATE_FAIL_HYPERV_SRC_NOT_FOUND": "Migrazione impossibile: hypervisor sorgente non trovato.",
    "MIGRATE_FAIL_HYPERV_DST_NOT_FOUND": "Migrazione impossibile: hypervisor destinazione non trovato.",
    "MIGRATE_SUCCESS": "Richiesta di migrazione inviata correttamente.",

    // Event / History
    "SERVER_EVENT_FAIL_INVALID_INTEGER": "Impossibile recuperare gli eventi del server: numero intero non valido.",
    "SERVER_EVENT_FAIL_INTEGER_LIMIT_EXCEEDED": "Impossibile recuperare gli eventi del server: il numero di eventi dev'essere compreso tra 1 e 50.",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_START": "Impossibile recuperare la cronologia del server: timestamp di avvio non valido (start).",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_END": "Impossibile recuperare la cronologia del server: timestamp di fine non valido (end).",
    "HISTORY_SRV_RESOURCE_FAIL_INVALID_TIMER": "Impossibile recuperare la cronologia del server: l'intervallo non deve superare le 12 ore.",

    // Network
    "NETWORK_NOT_FOUND": "Rete non trovata.",
    "NETWORK_DELETE_FAIL_NOT_FOUND": "Impossibile cancellare la rete: rete non trovata.",
    "NETWORK_DELETE_FAIL_SERVER_ATTACHED": "Impossibile cancellare la rete: un server è ancora collegato.",
    "NETWORK_CREATE_FAIL_COUNT_EXCEEDED": "Impossibile creare la rete: la quantità è stata superata, ti preghiamo di contattare il supporto.",
    "NETWORK_CREATE_FAIL_INVALID_CIDR": "Impossibile creare la rete: CIDR non valido.",
    "NETWORK_CREATE_FAIL_CIDR_CONFLICT": "Impossibile creare la rete: il CIDR entra in conflitto con un'altra rete gestita.",
    "NETWORK_CREATE_FAIL_CIDR_TOO_LARGE": "Impossibile creare la rete: il CIDR è troppo grande, la rete non può superare i 65536 IP (/16).",
    "NETWORK_CREATE_FAIL_MANAGED_SERVICES_DISABLED": "Impossibile creare la rete gestita: i servizi gestiti sono disabilitati per la tua azienda.",
    "NETWORK_CREATE_FAIL_INVALID_NAME": "Impossibile creare la rete: formato del nome non valido.",
    "NETWORK_CREATE_FAIL_GATEWAY_NOT_FOUND": "Impossibile creare la rete: gateway non trovato.",
    "NETWORK_ATTACH_FAIL_SERVER_FULL": "Impossibile collegare il server: vengono utilizzate tutte le porte del server.",
    "NETWORK_ATTACH_FAIL_MAX_SERVER_EXCEEDED": "Impossibile collegare il server: vengono utilizzate tutte le porte della rete",
    "NETWORK_ATTACH_FAIL_NETWORK_DOWN": "Impossibile collegare il server: la rete è fuori uso.",
    "NETWORK_ATTACH_FAIL_SERVER_DISABLED": "Impossibile collegare il server: il server non è attivo, ti preghiamo di contattare il supporto.",
    "NETWORK_ATTACH_FAIL_MANAGED_SERVICE_CONFLICT": "Impossibile collegare il server: rilevato conflitto di servizi gestiti.",
    "NETWORK_ATTACH_FAIL_MULTIPLE_MANAGED_NETWORKS": "Impossibile collegare il server: il server gestito può essere collegato a una sola rete.",
    "NETWORK_ATTACH_FAIL_ALREADY_ATTACHED_TO_THIS_SERVER": "Il server è già stato collegato a questa rete.",
    "NETWORK_DETACH_FAIL_SERVER_DISABLED": "Impossibile rimuovere il server: il server non è attivo, ti preghiamo di contattare il supporto.",
    "NETWORK_DETACH_FAIL_SERVER_NOT_ATTACHED": "Impossibile rimuovere il server: il server non è collegato alla rete.",
    "NETWORK_DETACH_FAIL_MANAGED_SERVER": "Impossibile rimuovere il server: i server gestiti non possono essere rimossi manualmente da una rete.",
    "NETWORK_DETACH_FAIL_NETWORK_DOWN": "Impossibile rimuovere il server: la rete è down.",
    "NETWORK_UPDATE_FAIL_INVALID_NAME": "Impossibile aggiornare la rete: formato del nome non valido.",
    "NETWORK_SET_GATEWAY_FAIL_NOT_MANAGED": "Impossibile definire il gateway: la rete non è gestita.",
    "NETWORK_SET_GATEWAY_FAIL_IP_NOT_FOUND": "Impossibile definire il gateway: l'indirizzo IP non è stato trovato.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_MANAGED": "Impossibile cancellare il gateway: la rete non è gestita.",
    "NETWORK_DELETE_GATEWAY_FAIL_NOT_FOUND": "Impossibile cancellare il gateway: gateway non trovato.",
    "NETWORK_ADD_FW_RULE_FAIL_NOT_MANAGED": "Impossibile aggiungere la regola firewall: la rete non è gestita.",
    "NETWORK_ADD_FW_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossibile aggiungere la regola firewall: interfaccia non trovata.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_SOURCE": "Impossibile aggiungere la regola firewall: sorgente non valida.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PORT": "Impossibile aggiungere la regola firewall: porta non valida.",
    "NETWORK_ADD_FW_RULE_FAIL_INVALID_PROTOCOL": "Impossibile aggiungere la regola firewall: protocollo non valido.",
    "NETWORK_REMOVE_FW_RULE_FAIL_NOT_FOUND": "Impossibile cancellare la regola firewall: regola non trovata.",
    "NETWORK_ADD_FW_RULE_SUCCESS": "Regola firewall aggiunta correttamente.",
    "NETWORK_REMOVE_FW_RULE_SUCCESS": "Regola firewall cancellata correttamente.",
    "NETWORK_SET_GATEWAY_SUCCESS": "Gateway definito correttamente.",
    "NETWORK_DELETE_GATEWAY_SUCCESS": "Gateway cancellato correttamente.",
    "NETWORK_ATTACH_SERVER_SUCCESS": "Server collegato correttamente.",
    "NETWORK_DETACH_SERVER_SUCCESS": "Server rimosso correttamente.",
    "NETWORK_DELETION_SUCCESS": "Cancellazione rete in corso.",
    "NETWORK_UPDATE_SUCCESS": "Rete aggiornata correttamente.",

    // Metric
    "METRIC_EMPTY_NET_IN": "Dati di rete in entrata non disponibili.",
    "METRIC_EMPTY_NET_OUT": "Dati di rete in uscita non disponibili.",
    "METRIC_UNAVAILABLE": "Dati non disponibili.",

    // Ticket
    "TICKET_NOT_FOUND": "Ticket non trovato.",
    "TICKET_CREATE_FAIL": "Impossibile creare il ticket: l'UUID del server è assente.",
    "TICKET_CREATE_FAIL_INVALID_OBJECT": "Impossibile creare il ticket: oggetto non valido.",
    "TICKET_CREATE_FAIL_INVALID_CATEGORY": "Impossibile creare il ticket: categoria non valida.",
    "TICKET_CREATE_FAIL_INVALID_MESSAGE": "Impossibile creare il ticket: messaggio non valido.",
    "TICKET_CREATE_FAIL_INVALID_LEVEL": "Impossibile creare il ticket: livello di priorità non valido.",
    "TICKET_CREATE_FAIL_UNKNOWN_SERVER": "Impossibile creare il ticket: server non trovato.",
    "TICKET_ADD_MESSAGE_INVALID_TEXT": "Impossibile aggiungere il messaggio al ticket: formato messaggio non valido.",
    "TICKET_ADD_MESSAGE_FAIL_MISSING_TICKET_UUID": "Impossibile aggiungere il messaggio al ticket: UUID ticket assente.",
    "TICKET_ADD_MESSAGE_FAIL_TICKET_LOCKED": "Impossibile aggiungere il messaggio al ticket: il ticket è bloccato da un altro utente.",
    "TICKET_DELETE_FAIL_MISSING_UUID": "Impossibile cancellare il ticket: UUID assente.",
    "TICKET_GET_FAIL_MISSING_TICKET_UUID": "Impossibile recuperare il ticket: UUID assente.",
    "TICKET_LOCK_FAIL_ALREADY_LOCKED": "Il ticket è già bloccato.",
    "TICKET_UNLOCK_FAIL_ALREADY_UNLOCKED": "Il ticket non è bloccato",
    "TICKET_UNLOCK_FAIL_LOCKED_BY_OTHER_USER": "Impossibile sbloccare il ticket: il ticket è bloccato da un altro utente.",
    "TICKET_LOCK_SUCCESS": "Ticket bloccato correttamente.",
    "TICKET_UNLOCK_SUCCESS": "Ticket sbloccato correttamente.",
    "TICKET_CREATE_SUCCESS": "Ticket creato correttamente.",
    "TICKET_CLOSE_SUCCESS": "Ticket chiuso correttamente.",
    "TICKET_DELETE_SUCCESS": "Ticket cancellato correttamente.",
    "TICKET_ADD_MESSAGE_SUCCESS": "Messaggio aggiunto correttamente al ticket.",
    "TICKET_DELETE_MESSAGE_SUCCESS": "Messaggio del ticket cancellato correttamente.",
    "TICKET_UPDATE_LEVEL_SUCCESS": "Livello del biglietto aggiornato correttamente.",
    "TICKET_UPDATE_LEVEL_FAIL_CLOSED": "Impossibile aggiornare il livello del ticket: il ticket è chiuso.",
    "TICKET_INVALID_MESSAGE": "La lunghezza del messaggio del ticket deve essere compresa tra 3 e 5.000 caratteri.",
    "ERR_TICKET_ADMIN_CANNOT_CREATE_FOR_HIMSELF": "Non puoi creare un biglietto per te stesso.",
    "TICKET_CLOSED": "il biglietto è chiuso",
    "TICKET_CREATE_FAIL_OTHER": "Impossibile creare il ticket",
    "TICKET_REPLY_ALREADY_OPENED": "Una finestra di risposta è già aperta",

    // Contact
    "CONTACT_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha non valido.",
    "CONTACT_REQUEST_FAIL_INVALID_EMAIL": "Formato e-mail non valido.",
    "CONTACT_REQUEST_SUCCESS": "E-mail inviata correttamente.",

    // Job
    "JOB_REQUEST_FAIL_RECAPTCHA_ERROR": "Recaptcha non valido.",
    "JOB_REQUEST_FAIL_INVALID_EMAIL": "Formato e-mail non valido.",
    "JOB_REQUEST_SUCCESS": "E-mail inviata correttamente.",

    // User
    "USER_2FA_ENABLE_FAIL_WRONG_CODE": "Codice di convalida errato",
    "USER_UPDATE_INVALID_FIRSTNAME": "Impossibile aggiornare l'utente: nome non valido",
    "USER_LIST_FAIL": "Impossibile recuperare l'elenco degli utenti.",
    "USER_NOT_FOUND": "Utente non trovato.",
    "USER_DELETE_FAIL_NOT_FOUND": "Impossibile cancellare l'utente: utente non trovato.",
    "USER_DELETE_SUCCESS": "Utente cancellato correttamente.",
    "USER_RESET_PASSWORD_FAIL_INVALID_TOKEN": "Impossibile reinizializzare la password dell'utente: token non valido.",
    "USER_RESET_PASSWORD_FAIL_INVALID_PASSWORD": "Impossibile reinizializzare la password dell'utente: formato password non valido.",
    "USER_RESET_PASSWORD_SUCCESS": "Password reinizializzata correttamente.",
    "USER_RESET_PASSWORD_ASK_SUCCESS": "Se disponi di un account esistente, verrà inviato alla tua email un messaggio contenente le istruzioni per reimpostare la password.",
    "USER_UPDATE_INVALID_LASTNAME": "Cognome non valido.",
    "USER_UPDATE_INVALID_PHONE_NUMBER": "Numero di telefono non valido.",
    "USER_UPDATE_INVALID_MOBILE_NUMBER": "Numero di cellulare non valido.",
    "USER_UPDATE_SUCCESS": "Utente aggiornato correttamente.",
    // API KEY
    "USER_ADD_API_KEY_SUCCESS": "Chiave creata con successo",
    // SSH key
    "USER_DELETE_SSH_KEY_SUCCESS": "Chiave SSH cancellata correttamente.",
    "USER_DELETE_SSH_KEY_FAIL_MISSING_NAME": "Nome della chiave SSH assente.",
    "USER_DELETE_SSH_KEY_FAIL_NOT_FOUND": "Chiave SSH non trovata.",
    "USER_ADD_SSH_KEY_FAIL_EMPTY": "La chiave SSH è vuota.",
    "USER_ADD_SSH_KEY_FAIL_KEY_INVALID": "La chiave SSH non è valida.",
    "USER_ADD_SSH_KEY_SUCCESS": "Chiave SSH aggiunta correttamente.",
    "USER_ADD_SSH_KEY_FAIL_ALREADY_NAME_EXIST": "Nome chiave già esistente",
    // Password
    "USER_UPDATE_PASSWORD_FAIL_WRONG_CURRENT_PASSWORD": "La password attuale non è corretta.",
    "USER_UPDATE_PASSWORD_FAIL_NEW_PASSWORD_INVALID": "La nuova password non è valida.",
    "USER_UPDATE_PASSWORD_SUCCESS": "Password aggiornata correttamente.",
    // Registration
    "USER_CREATE_FAIL_DEFAULT_PREFERENCES": "Impossibile creare le preferenze predefinite dell'utente.",
    "USER_CREATE_FAIL_COMPANY": "Impossibile creare l'azienda dell'utente.",
    "USER_UPDATE_FAIL_COMPANIES": "Impossibile associare l'utente alle aziende.",
    "VALIDATE_REGISTRATION_FIRST": "Per favore, valida prima la tua registrazione.",
    "VALIDATE_USER_SUCCESS": "La tua registrazione è stata convalidata.",

    // Company
    "COMPANY_DISABLED": "La tua attività è disattivata, contatta l'assistenza per ulteriori informazioni.",
    // Create
    "COMPANY_CREATE_FAIL_USER_ASSIGNMENT": "Impossibile assegnare l'utente alla nuova compagnia creata.",
    "COMPANY_CREATE_FAIL_ALREADY_EXISTS": "Creazione non riuscita, l'azienda esiste già",
    // Delete
    "COMPANY_DELETE_FAIL": "Impossibile cancellare tutti gli utenti dell'azienda.",
    "COMPANY_DELETE_SUCCESS": "Azienda cancellata correttamente.",
    // (Re)Validation
    "VALIDATE_USER_FAIL_CREATE_CONTACT_ERROR": "Impossibile creare il nuovo contatto.",
    "VALIDATE_USER_FAIL_UPDATE_COMPANY_ERROR": "Impossibile assegnare il numero contabile all'azienda.",
    "REVALIDATE_USER_FAIL_ALREADY_VALIDATED": "L'utente è già stato convalidato.",
    "REVALIDATE_USER_FAIL_ASSIGN_NEW_COMPANY": "Impossibile assegnare la nuova azienda al nuovo utente.",
    "REVALIDATE_USER_SUCCESS": "E-mail di convalida inviata correttamente.",
    // Update
    "INVALID_EMAIL": "L'indirizzo e-mail non è valido",
    "COMPANY_CREATE_SUCCESS": "Azienda creata con successo.",
    "COMPANY_UPDATE_SUCCESS": "Azienda aggiornata correttamente.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_TYPE": "Impossibile aggiornare l'azienda: tipo d'indirizzo errato.",
    "COMPANY_UPDATE_FAIL_INVALID_ADDRESS_NAME": "Impossibile aggiornare l’azienda: nome indirizzo non valido.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET": "Impossibile aggiornare l'azienda: indirizzo non valido.",
    "COMPANY_UPDATE_FAIL_INVALID_STREET_2": "Impossibile aggiornare l’azienda: via non valida.",
    "COMPANY_UPDATE_FAIL_INVALID_POSTAL_CODE": "Impossibile aggiornare l'azienda: codice postale non valido.",
    "COMPANY_UPDATE_FAIL_INVALID_CITY": "Impossibile aggiornare l'azienda: città non valida.",
    "COMPANY_UPDATE_FAIL_INVALID_COUNTRY": "Impossibile aggiornare l’azienda: paese non valido.",
    "COMPANY_UPDATE_FAIL_INVALID_PHONE": "Impossibile aggiornare l’azienda: numero di telefono non valido.",
    // Register user with company
    "REGISTER_COMPANY_FAIL_RECAPTCHA_ERROR": "Recaptcha non valido.",
    "REGISTER_COMPANY_FAIL_COMPANY_NAME_ALREADY_USED": "Impossibile salvare il nuovo account: il nome dell’azienda è già in uso.",
    "REGISTER_COMPANY_FAIL_USER_EMAIL_ALREADY_USED": "Impossibile salvare il nuovo account: e-mail già in uso.",
    "REGISTER_COMPANY_FAIL_COMPANY_EMAIL_ALREADY_USED": "Impossibile salvare il nuovo account: e-mail dell'azienda già in uso.",
    "REGISTER_COMPANY_FAIL_ASSIGN_USER_ERROR": "Impossibile assegnare la nuova azienda al nuovo utente.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_EMAIL": "Impossibile salvare il nuovo account: indirizzo e-mail dell'utente non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_EMAIL": "Impossibile salvare il nuovo account: indirizzo e-mail dell'azienda non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_NAME": "Impossibile salvare il nuovo account: nome dell’azienda non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_CIVILITY": "Impossibile salvare il nuovo account: stato civile non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_COUNTRY": "Impossibile salvare il nuovo account: paese non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET": "Impossibile salvare il nuovo account: via non valida.",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_STREET_2": "Impossibile salvare il nuovo account: seconda riga della via non valida.",
    "REGISTER_COMPANY_FAIL_INVALID_POSTAL_CODE": "Impossibile salvare il nuovo account: codice postale dell'azienda non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_CITY": "Impossibile salvare il nuovo account: città dell’azienda non valida.",
    "REGISTER_COMPANY_FAIL_INVALID_PREFERRED_LANGUAGE": "Impossibile salvare il nuovo account: lingua preferita non valida.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_FIRST_NAME": "Impossibile salvare il nuovo account: nome non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_LAST_NAME": "Impossibile salvare il nuovo account: cognome non valido",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PASSWORD": "Impossibile salvare il nuovo account: password non valida.",
    "REGISTER_COMPANY_FAIL_INVALID_USER_PHONE": "Impossibile salvare il nuovo account: numero di telefono dell'utente non valido",
    "REGISTER_COMPANY_FAIL_INVALID_USER_MOBILE": "Impossibile salvare il nuovo account: numero di cellulare non valido",
    "REGISTER_COMPANY_FAIL_INVALID_COMPANY_PHONE": "Impossibile salvare il nuovo account: numero di telefono dell'azienda non valido.",
    "REGISTER_COMPANY_FAIL_INVALID_AFFILIATE_CODE": "Impossibile salvare il nuovo account: codice affiliato non valido.",
    "REGISTER_COMPANY_SUCCESS": "Nuova azienda salvata correttamente.",
    "REGISTER_COMPANY_FAIL_INVALID_VAT_NUMBER": "Numero di IVA non è valido.",
    "INVALID_VAT_NUMBER": "Numero di IVA non è valido.",
    "REGISTER_COMPANY_FAIL_CHECK_VAT_NUMBER": "Errore durante il controllo.",
    "VAT_NUMBER_EMPTY": "Inserisci il numero di partita IVA",
    // Register user to company
    "REGISTER_USER_FAIL_RECAPTCHA_ERROR": "Recaptcha non valido.",
    "REGISTER_USER_FAIL_INVALID_FIRSTNAME": "Impossibile aggiungere l'utente all'azienda: nome non valido.",
    "REGISTER_USER_FAIL_INVALID_LASTNAME": "Impossibile aggiungere l'utente all'azienda: cognome non valido.",
    "REGISTER_USER_FAIL_INVALID_PASSWORD": "Impossibile aggiungere l’utente all’azienda: password non valida.",
    "REGISTER_USER_FAIL_INVALID_PHONE": "Impossibile aggiungere l’utente all’azienda: numero di telefono non valido.",
    "REGISTER_USER_FAIL_INVALID_MOBILE": "Impossibile aggiungere l’utente all’azienda: numero di cellulare non valido.",
    "REGISTER_USER_FAIL_INVALID_CIVILITY": "Impossibile aggiungere l'utente all'azienda: stato civile non valido.",
    "REGISTER_USER_FAIL_INVALID_PREFERRED_LANGUAGE": "Impossibile aggiungere l’utente all’azienda: lingua preferita non valida.",
    "REGISTER_USER_FAIL_INVALID_EMAIL": "Impossibile aggiungere l’utente all’azienda: indirizzo e-mail non valido.",
    "REGISTER_USER_FAIL_EMAIL_ALREADY_EXIST": "Impossibile aggiungere l'utente all'azienda: l'indirizzo e-mail inserito è già presente in memoria.",
    "REGISTER_USER_FAIL_CGV_NOT_FOUND": "Impossibile aggiungere l'utente all'azienda: CGV non trovate.",
    "REGISTER_USER_FAIL_COMPANY_NOT_FOUND_OR_INVALID_TOKEN": "Compagnia o token d'invito non trovato.",
    "REGISTER_USER_FAIL_EMAIL_REJECTED": "Impossibile aggiungere l’utente all’azienda: indirizzo e-mail rifiutato.",
    "REGISTER_USER_FAIL_CLEAR_INVITATION": "Impossibile rifiutare l'invito.",
    "REGISTER_USER_FAIL_INVALID_OR_EXPIRED_LINK": "Il link per la registrazione non è valido o è scaduto.",
    "REGISTER_USER_SUCCESS": "Nuovo utente salvato correttamente.",
    // Invite user to company
    "INVITE_USER_FAIL_INVALID_EMAIL": "Impossibile invitare l'utente: formato e-mail non valido.",
    "INVITE_USER_FAIL_BAD_PERMISSION": "Non hai il permesso d'invitare un nuovo utente.",
    "INVITE_USER_FAIL_ALREADY_INVITED": "L'utente ha già un ruolo in azienda.",
    "INVITE_USER_FAIL_ALREADY_ADMIN_OR_ACCOUNTANT": "L'utente è già amministratore o commercialista di un'altra azienda.",
    "INVITE_USER_FAIL_INVALID_POSITION": "Impossibile invitare l'utente: posizione non valida.",
    // Join
    "JOIN_COMPANY_FAIL_INVALID_COMPANY_OR_TOKEN": "Azienda o token d'invito non trovato.",
    "JOIN_COMPANY_FAIL_EMAIL_REJECTED": "Il tuo indirizzo e-mail è stato rifiutato.",
    "JOIN_COMPANY_FAIL_UNABLE_TO_UPDATE_USER": "Impossibile assegnare l'utente all'azienda.",
    "JOIN_COMPANY_FAIL_INVITATION_NOT_FOUND": "Impossibile trovare l'invito.",
    // Revoke
    "REVOKE_USER_FAIL_BAD_PERMISSION": "Solo gli amministratori possono rimuovere gli utenti.",
    "REVOKE_USER_FAIL_SELF_REVOCATION": "Non puoi rimuoverti da un'azienda.",
    "REVOKE_USER_FAIL_EMAIL_NOT_FOUND": "Impossibile trovare l'indirizzo e-mail dell'utente da rimuovere",
    "REVOKE_USER_FAIL_IS_ADMIN": "Impossibile rimuovere il creatore dell’azienda.",
    "REVOKE_USER_FAIL_WRONG_ROLE": "Impossibile trovare l'utente con il ruolo indicato.",
    "REVOKE_USER_FAIL_ACCOUNTING_ERROR": "Impossibile cancellare le informazioni contabili dell'utente.",
    "REVOKE_USER_SUCCESS": "Utente rimosso correttamente.",

    // Role
    "ROLE_NOT_FOUND": "Ruolo non trovato.",
    "ROLE_CREATE_FAIL_BAD_PERMISSION": "Solo gli amministratori di un'azienda possono creare nuovi ruoli.",
    "ROLE_DELETE_FAIL_BAD_PERMISSION": "Solo gli amministratori di un'azienda possono cancellare ruoli.",
    "ROLE_GET_FAIL_BAD_PERMISSION": "Solo gli amministratori dell'azienda possono recuperare l'elenco dei ruoli.",
    "ROLE_UPDATE_FAIL_BAD_PERMISSION": "Solo gli amministratori dell'azienda possono aggiornare ruoli.",
    "ROLE_DELETE_FAIL_NOT_FOUND": "Impossibile cancellare il ruolo: ruolo non trovato all'interno dell'azienda.",
    "ROLE_UPDATE_FAIL_NOT_FOUND": "Impossibile aggiornare il ruolo: ruolo non trovato all'interno dell'azienda.",
    "ROLE_UPDATE_FAIL_ALREADY_EXIST": "Impossibile aggiornare il ruolo: il nome del ruolo esiste già.",
    "ROLE_CREATE_FAIL_ALREADY_EXIST": "Impossibile creare il ruolo: il nome del ruolo esiste già.",
    "ROLE_DELETE_FAIL_IN_USE": "Impossibile eliminare il ruolo: il ruolo è assegnato a uno o più utenti.",
    "ROLE_CREATE_SUCCESS": "Ruolo creato correttamente.",
    "ROLE_UPDATE_SUCCESS": "Ruolo aggiornato correttamente.",
    "ROLE_DELETE_SUCCESS": "Ruolo cancellato correttamente.",

    // CGV
    "CGV_NOT_FOUND": "Impossibile trovare le ultime CGV.",
    "CGV_VALIDATE_FAIL_UPDATE_ERROR": "Errore durante la firma delle CGV dell'utente.",
    "CGV_VALIDATE_FAIL_INVALID_VERSION": "Non hai firmato l'ultima versione delle CGV.",
    "CGV_VALIDATE_FAIL": "Ti preghiamo di tenere presente che è necessario firmare le CGV per poter utilizzare i nostri servizi.",
    "CGV_VALIDATE_SUCCESS_ALREADY_SIGNED": "Hai già firmato le ultime CGV.",
    "CGV_VALIDATE_SUCCESS": "Grazie per aver firmato le nostre CGV.",

    // Token
    "TOKEN_NOT_FOUND": "Token non trovato.",
    "TOKEN_CREATE_FAIL_UPDATE_USER": "Impossibile generare il token per l'utente attuale.",
    "TOKEN_CREATE_FAIL_INVALID_DATE": "Impossibile generare il token: data di scadenza non valida.",
    "TOKEN_CREATE_FAIL_NAME_ALREADY_USED": "Impossibile generare il token: il nome del token esiste già.",
    "TOKEN_GET_FAIL_EMPTY_NAME": "Il nome del token non è valido.",
    "TOKEN_REVOKE_SUCCESS": "Token rimosso correttamente.",

    // Plan
    "PLAN_LIST_FAIL": "Impossibile recuperare l'elenco dei piani.",

    // IPs
    "IP_NOT_FOUND": "IP non trovato.",
    "IP_BLACKLISTED": "IP in blacklist.",
    "IP_DELETE_FAIL_ALREADY_ON_TERMINATION": "L'eliminazione di questo indirizzo IP è già pianificata.",
    "IP_DELETE_FAIL_ACTION_NOT_SCHEDULED": "L'eliminazione di questo indirizzo IP non è pianificata.",
    "IP_DELETE_FAIL_IPV4_DELETION_SCHEDULED": "L'eliminazione dell'indirizzo IPv4 corrispondente è pianificata.",
    "IP_V6_UNAVAILABLE": "IPv6 non disponibile.",
    "IP_V4_V6_COUPLE_NOT_FOUND": "IP_V4_V6_COUPLE_NOT_FOUND",
    // Attach/detach
    "DETACH_IP_SUCCESS": "IP rimosso correttamente.",
    "ATTACH_IP_SUCCESS": "IP collegato correttamente.",
    "ATTACH_IP_FAIL_NO_MORE_AVAILABLE_IP": "Impossibile collegare l'IP: non ci sono più indirizzi IP disponibili.",
    "ATTACH_IP_FAIL_IP_IS_NOT_IN_YOUR_COMPANY_POOL": "Impossibile collegare l'IP: l'indirizzo IP non è nel pool dell’azienda.",
    "ATTACH_IP_FAIL_SERVER_MANAGED": "Impossibile collegare l'IP: l'IP non è compatibile con il/i server, ti preghiamo di usare la regola PNAT.",
    "DETACH_IP_FAIL_BAD_SERVER_IP": "Impossibile rimuovere l'IP: l'indirizzo IP non è collegato al server.",
    "DETACH_IP_FAIL_IP_NOT_FOUND": "Impossibile rimuovere l'IP: indirizzo IP non trovato.",
    "DETACH_IP_FAIL_SERVER_MANAGED": "Impossibile rimuovere l'IP: la rimozione dell'IP non è compatibile con i server.",
    "DETACH_IP_FAIL_PRIMARY_ADDRESS": "Impossibile scollegare l'IP: Indirizzo IP principale.",
    // PNAT rules add/del
    "ADD_IP_PNAT_RULE_SUCCESS": "Regola PNAT aggiunta correttamente.",
    "DEL_IP_PNAT_RULE_SUCCESS": "Regola PNAT cancellata correttamente.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Impossibile aggiungere la regola PNAT: sorgente della porta non valida.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Impossibile aggiungere la regola PNAT: destinazione della porta non valida.",
    "ADD_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Impossibile aggiungere la regola PNAT: protocollo non valido.",
    "ADD_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Impossibile aggiungere la regola PNAT: il server non è gestito.",
    "ADD_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Impossibile aggiungere la regola PNAT: l'indirizzo IP non è stato trovato.",
    "ADD_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossibile aggiungere la regola PNAT: interfaccia privata non trovata.",
    "ADD_IP_PNAT_RULE_FAIL_TRANSPARENT_CONFLICT_PNAT": "Impossibile aggiungere la regola PNAT: devi rimuovere tutte le regole PNAT esistenti da questo IP prima di poter autorizzare la modalità trasparente.",
    "ADD_IP_PNAT_RULE_FAIL_PNAT_CONFLICT_TRANSPARENT": "Impossibile aggiungere la regola PNAT: devi disattivare i reindirizzamenti trasparenti riguardo a questo IP prima di poter aggiungere le regole PNAT.",
    "ADD_IP_PNAT_RULE_FAIL_SRC_PORT_CONFLICT": "Impossibile aggiungere la regola PNAT: per questo IP è già stata definita una regola PNAT.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_SRC_PORT": "Impossibile cancellare la regola PNAT: porta sorgente non valida.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_DST_PORT": "Impossibile cancellare la regola PNAT: destinazione porta non valida.",
    "DEL_IP_PNAT_RULE_FAIL_INVALID_PROTOCOL": "Impossibile cancellare la regola PNAT: protocollo non valido.",
    "DEL_IP_PNAT_RULE_FAIL_SERVER_NOT_MANAGED": "Impossibile cancellare la regola PNAT: il server non è gestito.",
    "DEL_IP_PNAT_RULE_FAIL_IP_NOT_FOUND": "Impossibile cancellare la regola PNAT: indirizzo IP non trovato.",
    "DEL_IP_PNAT_RULE_FAIL_INTERFACE_NOT_FOUND": "Impossibile cancellare la regola PNAT: interfaccia privata non trovata.",
    "DEL_IP_PNAT_RULE_FAIL_NOT_FOUND": "Impossibile cancellare la regola PNAT: regola PNAT non trovata.",

    // Demo
    "DEMO_SEND_CREATE_SERVER_SUCCESS": "Richiesta creazione di un server in modalità demo inviata correttamente.",

    // Managed services
    "MANAGED_SERVICES_ENABLED": "I servizi gestiti sono già attivati per questa azienda.",
    "MANAGED_SERVICES_SUCCESS": "Richiesta di attivazione servizi gestiti inviata correttamente.",
    "PROMOTION_CODE_INVALID": "Codice promozionale non valido, la lunghezza deve essere compresa tra 4 e 60 caratteri.",

    // Promotional code
    "PROMOTION_CODE_NOT_FOUND": "Codice promozionale non valido.",
    "PROMOTION_CODE_ALREADY_EXISTS": "Il codice promozionale esiste già.",

    // Items
    "ITEM_INVALID_QUANTITY": "Quantità articolo non valida.",
    "ITEM_NOT_FOUND": "Articolo non trovato",

    // Generate affiliation Links
    "GENERATE_AFF_LINKS_SUCCESS": "Il link di affiliazione è stato generato con successo. È registrato nel vostro giornale.",
    "UPDATE_AFF_LINKS_SUCCESS": "Il link di affiliazione è stato aggiornato con successo.",

    // Email validation
    "VALIDATE_EMAIL": "Ti è stata inviata un'e-mail per attivare il tuo account (controlla anche le tue e-mail di spam).",
    "VALIDATE_EMAIL_SUCCESS": "La tua email è stata convalidata con successo.",
    "VALIDATE_EMAIL_SUCCESS_ALREADY_VALIDATED": "La tua email è già stata convalidata.",
    "VALIDATE_EMAIL_INVITE_SUCCESS": "Hai accettato l'invito.",
    "VALIDATE_EMAIL_FAIL": "Impossibile convalidare l'account utente, contatta l'assistenza.",
    "VALIDATE_EMAIL_FAIL_TOKEN_INVALID": "Link non valido, invia nuovamente una richiesta di convalida per la tua email.",
    
    // Parameters
    "PARAM_SINGLE_NOT_FOUND": "Parametro di tipo SINGLE non trovato.",
    "PARAM_ALREADY_EXISTS": "Il parametro esiste già",
    
    // Transfer
    "TRANSFER_SUCCESS": "Trasferimento riuscito",
    "ERR_TRANSFER_NO_TYPE": "Tipo di trasferimento non specificato",
    "ERR_TRANSFER_SAME_COMPANY": "Trasferimento alla stessa azienda non possibile.",
    "ERR_TRANSFER_DEMO_MODE_SERVER": "Trasferimento impossibile, server in modalità DEMO.",
    "ERR_TRANSFER_GETTING_SUBSCRIPTIONS": "Errore nel recupero degli abbonamenti",
    "ERR_TRANSFER_STATE_INVALID": "Trasferimento impossibile, server cancellato o stato non valido",
    "ERR_TRANSFER_SAME_SUBSCRIPTION": "Il trasferimento del server allo stesso abbonamento non è possibile.",
    // Stripe
    "stripe": {
      "account_closed": "Conto bancario chiuso",
      "account_country_invalid_address": "Paese del conto bancario non valido",
      "account_error_country_change_requires_additional_steps": "Cambiare paese richiede passaggi aggiuntivi",
      "account_information_mismatch": "Alcune informazioni del conto non corrispondono",
      "account_invalid": "L'ID del conto fornito come valore per l'intestazione Stripe-Account non è valido",
      "account_number_invalid": "Il numero di conto bancario fornito non è valido",
      "acss_debit_session_incomplete": "La sessione di addebito ACSS non è ancora pronta",
      "alipay_upgrade_required": "Questo metodo di creazione dei pagamenti Alipay non è più supportato",
      "amount_too_large": "L'importo specificato è superiore all'importo massimo consentito",
      "amount_too_small": "L'importo specificato è inferiore all'importo minimo consentito",
      "api_key_expired": "La chiave API fornita è scaduta",
      "application_fees_not_allowed": "L'applicazione di tariffe non è consentita",
      "authentication_required": "Autenticazione richiesta",
      "balance_insufficient": "Saldo insufficiente",
      "balance_invalid_parameter": "Parametro fornito non valido per il metodo di saldo",
      "bank_account_bad_routing_numbers": "Il conto bancario non supporta questa valuta",
      "bank_account_declined": "Pagamento non autorizzato su questo conto bancario",
      "bank_account_exists": "Il conto bancario esiste già",
      "bank_account_restricted": "Il conto bancario non accetta questo metodo di pagamento",
      "bank_account_unusable": "Conto bancario non utilizzabile",
      "bank_account_unverified": "Conto bancario non verificato",
      "bank_account_verification_failed": "La verifica del conto bancario è fallita",
      "billing_invalid_mandate": "Mandato di pagamento non valido",
      "bitcoin_upgrade_required": "Il pagamento con Bitcoin non è più supportato",
      "capture_charge_authorization_expired": "I fondi non possono essere prelevati perché l'autorizzazione è scaduta",
      "capture_unauthorized_payment": "Non sei autorizzato a prelevare i fondi",
      "card_error": "Metodo di pagamento non valido",
      "card_decline_rate_limit_exceeded": "Superato il limite di tasso di rifiuto della carta",
      "card_declined": "Carta rifiutata",
      "cardholder_phone_number_required": "Numero di telefono del titolare della carta richiesto per la creazione di carte europee",
      "charge_already_captured": "Fondi già prelevati",
      "charge_already_refunded": "Fondi già rimborsati",
      "charge_disputed": "Fondi già contestati",
      "charge_exceeds_source_limit": "Il carico supera il limite della fonte",
      "charge_expired_for_capture": "Il carico è scaduto per la cattura",
      "charge_invalid_parameter": "Parametro non valido per prelevare i fondi",
      "charge_not_refundable": "Fondi non rimborsabili",
      "clearing_code_unsupported": "Il codice di compensazione fornito non è supportato",
      "country_code_invalid": "Codice del paese non valido",
      "country_unsupported": "Paese non supportato",
      "coupon_expired": "Coupon scaduto",
      "customer_max_payment_methods": "Raggiunto il numero massimo di metodi di pagamento",
      "customer_max_subscriptions": "Raggiunto il numero massimo di abbonamenti",
      "debit_not_authorized": "Pagamento non autorizzato",
      "email_invalid": "Indirizzo email non valido",
      "expired_card": "Carta scaduta",
      "insufficient_funds": "Fondi insufficienti",
      "intent_invalid_state": "Intento di pagamento in uno stato non valido",
      "invalid_card_type": "Tipo di carta non supportato",
      "invalid_charge_amount": "Importo del carico non valido",
      "payment_intent_mandate_invalid": "Mandato di pagamento non valido",
      "payment_method_bank_account_blocked": "Conto bancario bloccato",
      "payouts_limit_exceeded": "Superato il limite di pagamenti giornalieri",
      "payouts_not_allowed": "Pagamento non consentito",
      "incorrect_number": "Numero di carta errato",
      "generic_decline": "Carta rifiutata",
    },
    // Scheduled Actions
    "ERR_SCHEDULED_ACTION_INVALID_DATE": "La data programmata non è valida.",
    "ERR_SCHEDULED_ACTION_ALREADY_EXISTS": "Questa azione è già pianificata.",
    // Disputes
    "ERR_PAYMENT_NOT_BELONGS_TO_COMPANY": "Questo metodo di pagamento non appartiene a questa azienda",
    "ERR_DISPUTE_NOT_FOUND": "Controversia non trovata",
    "ERR_DISPUTE_PAID": "Controversia già saldata",
    "ERR_PAYMENT_BY_CARD_REQUIRED": "Pagamento con carta obbligatorio",
    "ERR_NO_INVOICES": "Nessuna fattura da pagare",
    "ERR_INCORRECT_NUMBER_OF_REFERENCES": "Numero di riferimenti errato",
    "ERR_SOME_INVOICE_ARE_IN_DISPUTE": "Almeno una fattura è in contestazione.",
  },
  "tools": {
    "coming_soon": "Funzionalità in arrivo.",
    "bad_level": "Livello di servizio insufficiente",
  },
}
